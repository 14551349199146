// import React, { useEffect, useState } from 'react'
// import JSSidebar from './JSSidebar'
// import NavBar from '../element/NavBar'
// import Footer from '../element/Footer'
// import { Link, useNavigate } from 'react-router-dom'
// import axios from 'axios'
// import ApiKey from '../api/ApiKey'
// import BaseApi from '../api/BaseApi'
// // import Multiselect from "multiselect-react-dropdown";
// import JoditEditor from 'jodit-react'
// import Select from 'react-select'
// import { useRef } from 'react'
// import Swal from 'sweetalert2'
// import Cookies from 'js-cookie'
// import LocationOnIcon from '@mui/icons-material/LocationOn'
// import { useTranslation } from 'react-i18next'
// import ReactQuill from 'react-quill'
// import 'react-quill/dist/quill.snow.css' // import styles
// import { event } from 'jquery'

// const ExperienceDetails = () => {
//   let primaryColor = Cookies.get('primaryColor')
//   let secondaryColor = Cookies.get('secondaryColor')
//   const mapKey = Cookies.get('mapKey')
//   const [t, i18n] = useTranslation('global')
//   const [editProfile, setEditProfile] = useState({
//     experience_certificate: '',
//     job_position: '',
//     company_name: '',
//     location: '',
//     job_profile: '',
//     startMonth: '',
//     startYear: '',
//     endMonth: '',
//     endYear: '',
//     ongoing: false,
//     selected_value: ''
//   })
//   const [selectedFiles, setSelectedFiles] = useState([])
//   // const [internships, setInternships] = useState([])
//   const [experience, setExperience] = useState([])
//   const [error, setError] = useState('')
//   const [validationErrors, setValidationErrors] = useState([])
//   const [yearsList, setYearsList] = useState([])
//   const [selected_value, setSelected_value] = useState('')
//   const [options, setOptions] = useState([])
//   const [errors, setErrors] = useState({})
//   const [educationDetails, setEducationDetails] = useState([])
//   const [loading, setLoading] = useState(false)
//   const [autocompleteService, setAutocompleteService] = useState(null)
//   const [suggestionsPreferred, setSuggestionsPreferred] = useState([])
//   const [suggestionsNative, setSuggestionsNative] = useState([])
//   const [suggestionTakenNative, setSuggestionTakenNative] = useState(false)

//   const navigate = useNavigate()
//   const tokenKey = Cookies.get('tokenClient') // Assuming you're using cookies for the token

//   const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false)

//   const handleFirstButtonMouseEnter = () => {
//     setHoverFirstButtonColor(true)
//   }

//   const handleFirstButtonMouseLeave = () => {
//     setHoverFirstButtonColor(false)
//   }

//   const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false)

//   const handleSecondButtonMouseEnter = () => {
//     setHoverSecondButtonColor(true)
//   }

//   const handleSecondButtonMouseLeave = () => {
//     setHoverSecondButtonColor(false)
//   }

//   const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false)

//   const handleThirdButtonMouseEnter = () => {
//     setHoverThirdButtonColor(true)
//   }

//   const handleThirdButtonMouseLeave = () => {
//     setHoverThirdButtonColor(false)
//   }

//   const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

//   const handleFourthButtonMouseEnter = () => {
//     setHoverFourthButtonColor(true)
//   }
//   const [fileName, setFileName] = useState('')

//   const handleFourthButtonMouseLeave = () => {
//     setHoverFourthButtonColor(false)
//   }

//   const getFileIcon = fileName => {
//     const ext = fileName.split('.').pop().toLowerCase()
//     const icons = {
//       pdf: 'fa-file-pdf',
//       doc: 'fa-file-word',
//       docx: 'fa-file-word',
//       txt: 'fa-file-alt',
//       xls: 'fa-file-excel',
//       xlsx: 'fa-file-excel',
//       csv: 'fa-file-csv',
//       jpg: 'fa-file-image',
//       jpeg: 'fa-file-image'
//     }
//     return icons[ext] || 'fa-file' // Default to a generic file icon
//   }

//   // const handleFileChange = e => {
//   //   const files = Array.from(e.target.files) // Corrected to use 'e' instead of 'event'
//   //   setSelectedFiles(prevFiles => [...prevFiles, ...files]) // Append new files to the existing state

//   //   if (files.length > 0) {
//   //     console.log(files) // Log the files for debugging

//   //     // Create a new FormData to store the files if necessary
//   //     const formData = new FormData()
//   //     files.forEach(file => {
//   //       formData.append('experience_certificate', file) // Append each file to FormData
//   //     })

//   //     // Update the experience state
//   //     const updatedExperience = [...experience]
//   //     updatedExperience[0].experience_certificate = files // Update the certificate field with the file array
//   //     setExperience(updatedExperience)
//   //   }
//   // }

//   // const handleFileChange = (e, index, mainID) => {
//   //   const files = Array.from(e.target.files); // Convert FileList to an array
//   //   setSelectedFiles(prevFiles => [...prevFiles, ...files]); // Append new files to the existing state

//   //   if (files.length > 0) {
//   //     console.log(files); // Log the files for debugging

//   //     // Create a new FormData to store the files if necessary
//   //     const formData = new FormData();
//   //     files.forEach(file => {
//   //       formData.append('experience_certificate', file); // Append each file to FormData
//   //     });

//   //     setExperience(prevInternships => {
//   //       const updatedDetails = [...prevInternships] // Create a shallow copy of the array
//   //       updatedDetails[index] = {
//   //         ...updatedDetails[index], // Create a shallow copy of the specific internship object
//   //         experience_certificate: files, // Update the specific field with the new value or checked state
//   //       }
//   //       return updatedDetails // Return the updated array
//   //     })
//   //   }
//   // };

//   // const handleFileChange = (e, index) => {
//   //   const files = Array.from(e.target.files) // Convert FileList to an array

//   //   if (files.length > 0) {
//   //     console.log(`Files selected for index ${index}:`, files)

//   //     // Update the `selectedFiles` state for the specific index
//   //     setSelectedFiles(prevFiles => {
//   //       const updatedFiles = { ...prevFiles } // Create a shallow copy of the object
//   //       updatedFiles[index] = files // Associate files with the specific index
//   //       return updatedFiles
//   //     })

//   //     // Update the `experience` state for the specific index
//   //     setExperience(prevExperience => {
//   //       const updatedExperience = [...prevExperience] // Create a shallow copy of the array
//   //       updatedExperience[index] = {
//   //         ...updatedExperience[index], // Create a shallow copy of the specific object
//   //         experience_certificate: files // Update the specific field with the new files
//   //       }
//   //       return updatedExperience // Return the updated array
//   //     })
//   //   } else {
//   //     console.warn(`No files selected for index ${index}.`)
//   //   }
//   // }

//   // const handleFileChange = (e, index) => {
//   //   const files = Array.from(e.target.files) // Convert FileList to an array

//   //   if (files.length > 0) {
//   //     console.log(`Files selected for index ${index}:`, files)

//   //     // Update the `selectedFiles` state for the specific index
//   //     setSelectedFiles(prevFiles => {
//   //       const updatedFiles = { ...prevFiles } // Create a shallow copy of the object
//   //       updatedFiles[index] = [...(updatedFiles[index] || []), ...files] // Append new files to the existing ones
//   //       return updatedFiles
//   //     })

//   //     // Update the `educationDetails` state for the specific index
//   //     setExperience(prevEducation => {
//   //       const updatedEducation = [...prevEducation] // Create a shallow copy of the array
//   //       updatedEducation[index] = {
//   //         ...updatedEducation[index], // Create a shallow copy of the specific object
//   //         experience_certificate: files // Update the specific field with the new files
//   //       }
//   //       return updatedEducation // Return the updated array
//   //     })
//   //   } else {
//   //     console.warn(`No files selected for index ${index}.`)
//   //   }
//   // }

//   // const handleRemoveFile = (index, fileIndex) => {
//   //   setSelectedFiles(prevFiles => {
//   //     const updatedFiles = { ...prevFiles } // Create a shallow copy of the object
//   //     updatedFiles[index] = updatedFiles[index].filter(
//   //       (_, idx) => idx !== fileIndex
//   //     ) // Remove the file by index
//   //     return updatedFiles
//   //   })

//   //   setExperience(prevEducation => {
//   //     const updatedEducation = [...prevEducation] // Create a shallow copy of the array
//   //     updatedEducation[index] = {
//   //       ...updatedEducation[index], // Create a shallow copy of the specific object
//   //       experience_certificate: updatedEducation[
//   //         index
//   //       ].experience_certificate.filter((_, idx) => idx !== fileIndex) // Remove the file by index
//   //     }
//   //     return updatedEducation // Return the updated array
//   //   })
//   // }

//    const handleFileChange = (e, index) => {
//       const files = Array.from(e.target.files); // Convert FileList to an array

//       if (files.length > 0) {
//         console.log(`Files selected for index ${index}:`, files);

//         // Get the current selected files for this index
//         const currentFiles = selectedFiles[index] || [];

//         // Check if more than 5 files are selected
//         if (currentFiles.length + files.length > 5) {
//           Swal.fire({
//             icon: 'error',
//             title: 'Maximum files limit reached',
//             text: 'You can upload a maximum of 5 files.',
//           });
//           return;
//         }

//         // Check for duplicate files by comparing file names
//         const newFiles = files.filter(
//           (file) => !currentFiles.some((existingFile) => existingFile.name === file.name)
//         );

//         // If there are duplicate files, show an error
//         if (newFiles.length !== files.length) {
//           Swal.fire({
//             icon: 'error',
//             title: 'Duplicate files',
//             text: 'Duplicate files are not allowed.',
//           });
//           return;
//         }

//         // Update the `selectedFiles` state for the specific index
//         setSelectedFiles((prevFiles) => {
//           const updatedFiles = { ...prevFiles }; // Create a shallow copy of the object
//           updatedFiles[index] = [...currentFiles, ...newFiles]; // Append new files to the existing ones
//           return updatedFiles;
//         });

//         // Update the `experience` state for the specific index
//         setExperience((prevExperience) => {
//           const updatedExperience = [...prevExperience]; // Create a shallow copy of the array
//           updatedExperience[index] = {
//             ...updatedExperience[index], // Create a shallow copy of the specific object
//             experience_certificate: [
//               ...(updatedExperience[index]?.experience_certificate || []), // Keep existing certificates
//               ...newFiles, // Append new files
//             ],
//           };
//           return updatedExperience; // Return the updated array
//         });
//       } else {
//         console.warn(`No files selected for index ${index}.`);
//       }
//     };

//     const handleRemoveFile = (index, fileIndex) => {
//       setSelectedFiles((prevFiles) => {
//         const updatedFiles = { ...prevFiles }; // Create a shallow copy of the object
//         updatedFiles[index] = updatedFiles[index].filter((_, idx) => idx !== fileIndex); // Remove the file by index
//         return updatedFiles;
//       });

//       setExperience((prevEducation) => {
//         const updatedEducation = [...prevEducation]; // Create a shallow copy of the array
//         updatedEducation[index] = {
//           ...updatedEducation[index], // Create a shallow copy of the specific object
//           experience_certificate: updatedEducation[index].experience_certificate.filter(
//             (_, idx) => idx !== fileIndex // Remove the file by index
//           ),
//         };
//         return updatedEducation; // Return the updated array
//       });
//     };

//   const handleChange = (e, index, mainID) => {
//     const { name, value, type, checked } = e.target

//     // For checkboxes, we need to use `checked` instead of `value`
//     const fieldValue = type === 'checkbox' ? checked : value

//     setExperience(prevInternships => {
//       const updatedDetails = [...prevInternships] // Create a shallow copy of the array
//       updatedDetails[index] = {
//         ...updatedDetails[index], // Create a shallow copy of the specific internship object
//         [name]: fieldValue // Update the specific field with the new value or checked state
//       }
//       return updatedDetails // Return the updated array
//     })
//   }

//   const handleAdd = () => {
//     const newQualification = {
//       experience_certificate: '',
//       job_position: '',
//       company_name: '',
//       location: '',
//       job_profile: '',
//       startMonth: '',
//       startYear: '',
//       endMonth: '',
//       endYear: '',
//       ongoing: false
//     }

//     // setEducationDetails(prevEducationDetails => [
//     //   ...prevEducationDetails,
//     //   newQualification
//     // ])
//     setExperience(prevInternships => [...prevInternships, newQualification])
//   }
//   const [suggestionTakenPreferred, setSuggestionTakenPreferred] =
//     useState(false)
//   const handlePreferredLocationChange = e => {
//     const { value } = e.target
//     setSuggestionTakenPreferred(false)
//     if (value == '') {
//       setSuggestionTakenPreferred(true)
//     }
//     if (value != '') {
//       setErrors({
//         ...errors,
//         pre_location: ''
//       })
//     }

//     setEditProfile(prevFilter => ({
//       ...prevFilter,
//       pre_location: value
//     }))

//     /*************  ✨ Codeium Command ⭐  *************/
//     /**
//      * Handles when a user clicks on a suggestion from the native location
//      * input. Updates the input value with the suggestion and clears the
//      * suggestions list.
//      * @param {string} suggestion The suggestion that was clicked.
//      */
//     /******  e9055c96-6b93-4213-85d5-0b5527fb5028  *******/

//     if (autocompleteService) {
//       // Call Google Maps Autocomplete API
//       autocompleteService.getPlacePredictions(
//         {
//           input: value,
//           types: ['(cities)'] // Restrict to cities if needed
//         },
//         (predictions, status) => {
//           if (status === 'OK' && predictions) {
//             setSuggestionsPreferred(
//               predictions.map(prediction => prediction.description)
//             )
//           } else {
//             setSuggestionsPreferred([])
//           }
//         }
//       )
//     }
//     if (editProfile.pre_location === '') {
//       setSuggestionsPreferred([])
//     }
//   }

//   const handleNativeLocationChange = e => {
//     const { value } = e.target
//     setSuggestionTakenNative(false)
//     if (value == '') {
//       setSuggestionTakenNative(true)
//     }
//     if (value != '') {
//       setErrors({
//         ...errors,
//         location: ''
//       })
//     }

//     setEditProfile(prevFilter => ({
//       ...prevFilter,
//       location: value
//     }))

//     if (autocompleteService) {
//       // Call Google Maps Autocomplete API
//       autocompleteService.getPlacePredictions(
//         {
//           input: value,
//           types: ['(cities)'] // Restrict to cities if needed
//         },
//         (predictions, status) => {
//           if (status === 'OK' && predictions) {
//             setSuggestionsNative(
//               predictions.map(prediction => prediction.description)
//             )
//           } else {
//             setSuggestionsNative([])
//           }
//         }
//       )
//     }
//     if (editProfile.location === '') {
//       setSuggestionsNative([])
//     }
//   }

//   const validateFields = () => {
//     // Ensure experience is an array
//     if (!Array.isArray(experience)) {
//       setValidationErrors([{ error: 'Experience data is invalid' }])
//       return false
//     }

//     const errors = experience.map((exp, index) => ({
//       job_position:
//         exp?.job_position?.trim() === '' ? 'Job position is required' : '',
//       company_name:
//         exp?.company_name?.trim() === '' ? 'Company name is required' : '',
//       startYear: exp?.startYear?.trim() === '' ? 'Start year is required' : '',
//       location: exp?.location?.trim() === '' ? 'Location is required' : '',
//       job_profile:
//         exp?.job_profile?.trim() === '' ? 'Job profile is required' : '',
//       startMonth:
//         exp?.startMonth?.trim() === '' ? 'Start month is required' : '',
//       endYear: exp?.endYear?.trim() === '' ? 'End year is required' : '',
//       ongoing: exp?.ongoing === false ? 'Ongoing status is required' : ''
//       // experience_certificate:
//       //   exp?.experience_certificate?.trim() === '' ? 'Upload files upto 5' : '' // Optional: Add validation for ongoing
//     }))

//     setValidationErrors(errors) // Set the validation errors in the state

//     const isValid = errors.every(
//       error =>
//         !error.job_position &&
//         !error.company_name &&
//         !error.startYear &&
//         !error.location &&
//         !error.job_profile &&
//         !error.startMonth &&
//         !error.endYear &&
//         !error.ongoing
//       // !error.experience_certificate
//     )

//     return isValid
//   }

//   const handleRemove = async id => {
//     try {
//       const confirmationResult = await Swal.fire({
//         title: t('jobseekerEducation.removeConfirmTitle'),
//         text: t('jobseekerEducation.removeConfirmTxt'),
//         icon: 'question',
//         showCancelButton: true,
//         confirmButtonText: t('jobseekerEducation.yes'),
//         cancelButtonText: t('jobseekerEducation.no')
//       })
//       if (confirmationResult.isConfirmed) {
//         const response = await axios.post(
//           BaseApi + `/candidates/deleteeducation/${id}`,
//           null,
//           {
//             headers: {
//               'Content-Type': 'application/json',
//               key: ApiKey,
//               token: tokenKey
//             }
//           }
//         )
//         if (response.data.status === 200) {
//           getData()
//           Swal.fire({
//             title: t('jobseekerEducation.removeSuccessTitle'),
//             icon: 'success',
//             confirmButtonText: t('jobseekerEducation.close')
//           })
//         } else if (response.data.status === 400) {
//           Cookies.remove('tokenClient')
//           Cookies.remove('user_type')
//           Cookies.remove('fname')
//           navigate('/')
//           Swal.fire({
//             title: response.data.message,
//             icon: 'warning',
//             confirmButtonText: t('jobseekerEducation.close')
//           })
//         } else {
//           Swal.fire({
//             title: response.data.message,
//             icon: 'error',
//             confirmButtonText: t('jobseekerEducation.close')
//           })
//         }
//       }
//     } catch (error) {
//       setLoading(false)
//       if (error.message === 'Network Error') {
//         Cookies.remove('tokenClient')
//         Cookies.remove('user_type')
//         Cookies.remove('fname')
//         navigate('/')
//         Swal.fire({
//           title: t('tokenExpired.tokenExpired'),
//           icon: 'warning',
//           confirmButtonText: t('jobDescription.close')
//         })
//         setTimeout(function () {
//           window.location.reload()
//         }, 3000)
//       }
//       Swal.fire({
//         title: t('jobseekerEducation.removeFailedTitle'),
//         text: t('jobseekerEducation.removeFailedTxt'),
//         icon: 'error',
//         confirmButtonText: t('jobseekerEducation.close')
//       })
//     }
//   }

//   const handleRemoveWithoutId = indexToRemove => {
//     // Remove internship entry without ID (local state update)
//     setExperience(prevInternshipDetails =>
//       prevInternshipDetails.filter((_, index) => index !== indexToRemove)
//     )
//   }

//   // Function to handle form submission
//   const handleClick = async () => {
//     const updatedExperience = { ...experience, selected_value: selected_value }
//     if (!validateFields()) {
//       window.scrollTo(0, 0) // Scroll to the top to see validation errors
//       return
//     }

//     try {
//       const confirmationResult = await Swal.fire({
//         title: 'Confirm Experience Details',
//         text: 'Are you sure you want to save these details?',
//         icon: 'question',
//         showCancelButton: true,
//         confirmButtonText: 'Yes',
//         cancelButtonText: 'No'
//       })

//       if (confirmationResult.isConfirmed) {
//         setLoading(true)
//         console.log()

//         const response = await axios.post(
//           BaseApi + '/candidates/editExperience',
//           { Experience: updatedExperience }, // Send education details as payload
//           {
//             headers: {
//               'Content-Type': 'multipart/form-data',
//               key: ApiKey,
//               token: tokenKey
//             }
//           }
//         )

//         setLoading(false)

//         if (response.data.status === 200) {
//           Swal.fire({
//             title: 'Success!',
//             text: 'Experience details updated successfully.',
//             icon: 'success',
//             confirmButtonText: 'Close'
//           })
//           setEducationDetails([]) // Reset education details
//           // Optional: Reload the page or call a function to update UI
//           navigate('/candidates/internships')
//         } else if (response.data.status === 400) {
//           Cookies.remove('tokenClient')
//           Cookies.remove('user_type')
//           Cookies.remove('fname')
//           navigate('/candidates/parttime') // Redirect if token expired or other issue
//           Swal.fire({
//             title: response.data.message,
//             icon: 'warning',
//             confirmButtonText: 'Close'
//           })
//         } else {
//           Swal.fire({
//             title: response.data.message,
//             icon: 'error',
//             confirmButtonText: 'Close'
//           })
//         }
//       }
//     } catch (error) {
//       setLoading(false)
//       // if (error.message === "Network Error") {
//       //   Cookies.remove("tokenClient");
//       //   Cookies.remove("user_type");
//       //   Cookies.remove("fname");
//       //   navigate("/candidates/internships");
//       //   Swal.fire({
//       //     title: "Token Expired",
//       //     icon: "warning",
//       //     confirmButtonText: "Close",
//       //   });
//       //   setTimeout(function () {
//       //     window.location.reload(); // Reload page if the token is expired
//       //   }, 3000);
//       // }

//       Swal.fire({
//         title: 'Failed to update Experience Details',
//         text: 'There was an error updating your experience details. Please try again.',
//         icon: 'error',
//         confirmButtonText: 'Close'
//       })
//     }
//   }

//   const handleNativeSuggestionClick = suggestion => {
//     // Update the input value with the clicked suggestion
//     handleNativeLocationChange({
//       target: { name: 'location', value: suggestion }
//     })

//     setSuggestionTakenNative(true)
//     // Clear the suggestions
//     setSuggestionsNative([])
//     // console.log(filterItem);
//   }

//   useEffect(() => {
//     // Check if tokenKey is not present
//     if (!tokenKey) {
//       // Redirect to the home page
//       navigate('/user/jobseekerlogin')
//     } else {
//       // TokenKey is present, fetch data or perform other actions
//       getData()

//       window.scrollTo(0, 0)
//       // if(educationDetails.basic_course_id) {
//       // getSpecializationList(educationDetails.basic_course_id);
//       // }
//     }
//   }, [tokenKey, navigate])

//   const handleSelectChange = async event => {
//     const selectedValue = event.target.value
//     setSelected_value(selectedValue) // Update state

//     setExperience({
//       ...editProfile,
//       selected_value: selectedValue // Update the selected_value in the state
//     })

//     console.log('Selected Value:', selectedValue)

//     if (selectedValue === 'Experience') {
//       // // Navigate to the Experience page and fetch data
//       // try {
//       //   const response = await axios.get(BaseApi + '/candidates/editExperience');
//       //   console.log('Experience Data:', response.data);
//       //   // navigate('/experience');
//       // } catch (error) {
//       //   console.error('Error fetching Experience data:', error);
//       // }
//     } else if (selectedValue === 'Internship') {
//       // Navigate to the Internship page and fetch data
//       try {
//         const response = await axios.get(
//           BaseApi + '/candidates/addinternships',
//           {
//             headers: {
//               'Content-Type': 'application/json',
//               key: ApiKey,
//               token: tokenKey
//             }
//           }
//         )
//         console.log('Internship Data:', response.data)
//         navigate('/candidates/internships')
//       } catch (error) {
//         console.error('Error fetching Internship data:', error)
//       }
//     } else if (selectedValue === 'Part Time') {
//       // Navigate to the Part-Time page and fetch data
//       try {
//         const response = await axios.get(
//           BaseApi + '/candidates/addparttimejobs',
//           {
//             headers: {
//               'Content-Type': 'application/json',
//               key: ApiKey,
//               token: tokenKey
//             }
//           }
//         )
//         console.log('Part-Time Data:', response.data)
//         navigate('/candidates/parttime')
//       } catch (error) {
//         console.error('Error fetching Part-Time data:', error)
//       }
//     }
//   }

//   const getData = async () => {
//     try {
//       setLoading(true)
//       const response = await axios.post(
//         BaseApi + '/candidates/editExperience',
//         null, // Pass null as the request body if not required
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             key: ApiKey,
//             token: tokenKey
//           }
//         }
//       )
//       if (response.data.status === 200) {
//         // if (Array.isArray(response.data)) {
//         //   setInternships(response.data);
//         // } else {
//         //   setInternships([]); // Fallback if the data is not an array
//         // }

//         setExperience(response.data.response.expDetails)
//         setYearsList(response.data.response.yearList)
//         setSelected_value(response.data.response.expDetails.selected_value)
//         setOptions(response.data.response.options)
//         // console.log(response.data.response.selected_value)

//         // Extract selected_value from the correct path in the response

//         console.log(response.data.response.expDetails)

//         // console.log("object");

//         // code to handel pre selected interest category

//         var categoryList = response.data.response.categoryList
//         var interestCategory = response.data.response.interest_categories
//         var selectedCat = []

//         categoryList.forEach(element => {
//           for (let i = 0; i < interestCategory.length; i++) {
//             if (parseInt(interestCategory[i]) === element.id) {
//               let obj = {
//                 value: element.id,
//                 label: element.name
//               }
//               selectedCat.push(obj)
//             }
//           }
//         })

//         setLoading(false)
//         setEditProfile(response.data.response)

//         console.log('check')
//         // setDesignationList(response.data.response.designationlList);
//         // console.log(skillList);
//       } else if (response.data.status === 400) {
//         Cookies.remove('tokenClient')
//         Cookies.remove('user_type')
//         Cookies.remove('fname')
//         navigate('/')
//         Swal.fire({
//           title: response.data.message,
//           icon: 'warning',
//           confirmButtonText: t('searchJobPage.close')
//         })
//       } else {
//         Swal.fire({
//           title: response.data.message,
//           icon: 'error',
//           confirmButtonText: t('searchJobPage.close')
//         })
//       }
//     } catch (error) {
//       setLoading(false)
//       if (error.message === 'Network Error') {
//         Cookies.remove('tokenClient')
//         Cookies.remove('user_type')
//         Cookies.remove('fname')
//         navigate('/')
//         Swal.fire({
//           title: t('tokenExpired.tokenExpired'),
//           icon: 'warning',
//           confirmButtonText: t('jobDescription.close')
//         })
//         setTimeout(function () {
//           window.location.reload()
//         }, 3000)
//       }
//       console.log('Cannot get data of edit profile page')
//     }
//   }

//   return (
//     <>
//       <NavBar />
//       {loading ? (
//         <div className='loader-container'></div>
//       ) : (
//         <>
//           <div className='container editProfile'>
//             <div className='row'>
//               <div className='col-lg-3'>
//                 <JSSidebar />
//               </div>

//               <div
//                 className='col-lg-9 mb-5'
//                 style={{
//                   borderLeft: '2px solid #e6e8e7',
//                   borderRight: '2px solid #e6e8e7'
//                 }}
//               >
//                 <div className='mx-3 d-flex PageHeader'>
//                   <h3 className=''>Professional experience</h3>
//                 </div>
//                 <p className='mx-3'>
//                   Begin with your most recent or current position and work
//                   backward.<br /> (If you have a lot of experience, just provide your
//                   most recent positions)
//                 </p>

//                 <form>
//                   <div className='mb-5 mt-4 mx-3'>
//                     <select
//                       className='form-select'
//                       aria-label='Default select example'
//                       onChange={handleSelectChange}
//                       value={selected_value}
//                       name='selected_value'
//                     >
//                       {Object.entries(options).map(([index, value]) => {
//                         return <option value={value}>{value}</option>
//                       })}
//                     </select>
//                     {Array.isArray(experience) &&
//                       experience.map((j, index) => {
//                         return (
//                           <>
//                             <h4 className='mt-5 mb-5'>
//                               Experience Details {index + 1}:
//                             </h4>

//                             <div className='form-outline mb-5 DashBoardInputBx'>
//                               <label
//                                 className='form-label'
//                                 htmlFor='form3Example1'
//                               >
//                                 Job Position
//                                 <span className='RedStar'>*</span>
//                               </label>

//                               <input
//                                 type='text'
//                                 id='form3Example1'
//                                 className={`form-control ${
//                                   validationErrors[index]?.job_position &&
//                                   'input-error'
//                                 }`}
//                                 placeholder='Job Position'
//                                 name='job_position'
//                                 value={j.job_position || ''} // Ensure fallback to an empty string
//                                 onChange={e => handleChange(e, index, j.id)}
//                               />

//                               {validationErrors[index]?.job_position && (
//                                 <div className='text-danger'>
//                                   {validationErrors[index].job_position}
//                                 </div>
//                               )}
//                             </div>

//                             <div className='form-outline mb-5 DashBoardInputBx'>
//                               <label
//                                 className='form-label'
//                                 htmlFor='form3Example3'
//                               >
//                                 Employer (company Name)
//                               </label>
//                               <input
//                                 type='text'
//                                 id='form3Example3'
//                                 // className={`form-control ${
//                                 //   errors.company_name && 'input-error'
//                                 // }`}
//                                 className='form-control'
//                                 placeholder='Employer (company Name)'
//                                 name='company_name'
//                                 value={j.company_name}
//                                 onChange={e => handleChange(e, index, j.id)}
//                               />

//                               {validationErrors[index]?.company_name && (
//                                 <div className='text-danger'>
//                                   {validationErrors[index].company_name}
//                                 </div>
//                               )}
//                             </div>

//                             <div className='form-outline mb-5 DashBoardInputBx'>
//                               <label
//                                 className='form-label'
//                                 htmlFor='form3Example3'
//                               >
//                                 Location
//                                 <span className='RedStar'>*</span>
//                               </label>
//                               <input
//                                 type='text'
//                                 id='form3Example3'
//                                 className={`form-control ${
//                                   errors.location && 'input-error'
//                                 }`}
//                                 placeholder='Location'
//                                 name='location'
//                                 value={j.location}
//                                 onChange={e => handleChange(e, index, j.id)}
//                               />
//                               {suggestionsNative.length > 0 && (
//                                 <div
//                                   className='suggestions'
//                                   style={{
//                                     display: suggestionTakenNative ? 'none' : ''
//                                   }}
//                                 >
//                                   <ul className='locationDropdown'>
//                                     {suggestionsNative.map(
//                                       (suggestion, index) => (
//                                         <div
//                                           key={index}
//                                           className='suggestion-item'
//                                         >
//                                           <li
//                                             onClick={() =>
//                                               handleNativeSuggestionClick(
//                                                 suggestion
//                                               )
//                                             }
//                                           >
//                                             <div className='eachLocation'>
//                                               <div className='locationIcon'>
//                                                 <LocationOnIcon fontSize='small' />
//                                               </div>{' '}
//                                               <div className='locationSuggestion'>
//                                                 {suggestion}
//                                               </div>
//                                             </div>{' '}
//                                           </li>
//                                         </div>
//                                       )
//                                     )}
//                                   </ul>
//                                 </div>
//                               )}

//                               {validationErrors[index]?.location && (
//                                 <div className='text-danger'>
//                                   {validationErrors[index].location}
//                                 </div>
//                               )}
//                             </div>
//                             <div className='form-outline mb-5 DashBoardInputBx'>
//                               <h5>Duration</h5>
//                             </div>

//                             <div className='form-outline mb-5 DashBoardInputBx d-flex gap-4'>
//                               <label
//                                 className='form-label'
//                                 htmlFor='startEmployment'
//                               >
//                                 Start of Employment
//                                 <span className='RedStar'>*</span>
//                               </label>

//                               <div className='d-flex gap-3 form-control'>
//                                 <div className='w-50'>
//                                   <select
//                                     name='from_month'
//                                     id='startMonth'
//                                     className={`form-control form-select ${
//                                       validationErrors[index]?.startMonth &&
//                                       'input-error'
//                                     }`}
//                                     value={j.from_month}
//                                     onChange={e => handleChange(e, index, j.id)}
//                                   >
//                                     <option value=''>Select Month</option>
//                                     <option value='01'>January</option>
//                                     <option value='02'>February</option>
//                                     <option value='03'>March</option>
//                                     <option value='04'>April</option>
//                                     <option value='05'>May</option>
//                                     <option value='06'>June</option>
//                                     <option value='07'>July</option>
//                                     <option value='08'>August</option>
//                                     <option value='09'>September</option>
//                                     <option value='10'>October</option>
//                                     <option value='11'>November</option>
//                                     <option value='12'>December</option>
//                                   </select>
//                                   {validationErrors[index]?.startMonth && (
//                                     <div className='text-danger small'>
//                                       {validationErrors[index].startMonth}
//                                     </div>
//                                   )}
//                                 </div>

//                                 <div className='w-50'>
//                                   <select
//                                     name='from_year'
//                                     id='startYear'
//                                     className={`form-control form-select ${
//                                       validationErrors[index]?.startYear &&
//                                       'input-error'
//                                     }`}
//                                     value={j.from_year}
//                                     onChange={e => handleChange(e, index, j.id)}
//                                   >
//                                     <option selected value=''>
//                                       {t('jobseekerExperience.selectYear')}
//                                     </option>
//                                     {Object.entries(yearsList).map(
//                                       ([index, value]) => {
//                                         return (
//                                           <option value={value}>{value}</option>
//                                         )
//                                       }
//                                     )}
//                                   </select>
//                                   {validationErrors[index]?.startYear && (
//                                     <div className='text-danger small'>
//                                       {validationErrors[index].startYear}
//                                     </div>
//                                   )}
//                                 </div>
//                               </div>

//                               <div className='d-flex gap-3  form-control'>
//                                 {j.ongoing ? (
//                                   <div className='w-100 d-flex align-items-center'>
//                                     <span className='fw-bold'>Present</span>
//                                   </div>
//                                 ) : (
//                                   <>
//                                     {/* End Month */}
//                                     <div className='w-50'>
//                                       <label className='form-emplyoer w-25'>
//                                         End of Employment
//                                         <span className='RedStar'>*</span>
//                                       </label>
//                                       <select
//                                         name='to_month'
//                                         className={`form-control form-select ${
//                                           validationErrors[index]?.endMonth &&
//                                           'input-error'
//                                         }`}
//                                         value={j.to_month}
//                                         onChange={e =>
//                                           handleChange(e, index, j.id)
//                                         }
//                                       >
//                                         <option value=''>Select Month</option>
//                                         <option value='01'>January</option>
//                                         <option value='02'>February</option>
//                                         <option value='03'>March</option>
//                                         <option value='04'>April</option>
//                                         <option value='05'>May</option>
//                                         <option value='06'>June</option>
//                                         <option value='07'>July</option>
//                                         <option value='08'>August</option>
//                                         <option value='09'>September</option>
//                                         <option value='10'>October</option>
//                                         <option value='11'>November</option>
//                                         <option value='12'>December</option>
//                                       </select>
//                                       {validationErrors[index]?.endMonth && (
//                                         <div className='text-danger small'>
//                                           {validationErrors[index].endMonth}
//                                         </div>
//                                       )}
//                                     </div>

//                                     {/* End Year */}
//                                     <div className='w-50'>
//                                       <select
//                                         name='to_year'
//                                         className={`form-control form-select ${
//                                           validationErrors[index]?.endYear &&
//                                           'input-error'
//                                         }`}
//                                         value={j.to_year}
//                                         onChange={e =>
//                                           handleChange(e, index, j.id)
//                                         }
//                                       >
//                                         <option selected value=''>
//                                           {t('jobseekerExperience.selectYear')}
//                                         </option>
//                                         {Object.entries(yearsList).map(
//                                           ([index, value]) => {
//                                             return (
//                                               <option value={value}>
//                                                 {value}
//                                               </option>
//                                             )
//                                           }
//                                         )}
//                                       </select>
//                                       {validationErrors[index]?.endYear && (
//                                         <div className='text-danger small'>
//                                           {validationErrors[index].endYear}
//                                         </div>
//                                       )}
//                                     </div>
//                                   </>
//                                 )}
//                               </div>
//                             </div>

//                             <div className='form-outline mb-5 DashBoardInputBx ongoing-container'>
//                               <input
//                                 type='checkbox'
//                                 id='ongoing'
//                                 name='ongoing'
//                                 checked={j.ongoing}
//                                 onChange={e => handleChange(e, index, j.id)}
//                                 className='form-check-input'
//                               />
//                               <label
//                                 htmlFor='ongoing'
//                                 className='ongoing-label'
//                               >
//                                 Ongoing<span className='RedStar'>*</span>
//                               </label>
//                             </div>

//                             <div className='form-outline mb-5 DashBoardInputBx'>
//                               <label
//                                 className='form-label'
//                                 htmlFor='form3Example3'
//                               >
//                                 Job Profile
//                                 <span className='RedStar'>*</span>
//                               </label>
//                               <input
//                                 type='text'
//                                 id='form3Example3'
//                                 className={`form-control ${
//                                   errors.job_profile && 'input-error'
//                                 }`}
//                                 placeholder='Job Profile'
//                                 name='job_profile'
//                                 value={j.job_profile}
//                                 onChange={e => handleChange(e, index, j.id)}
//                               />

//                               {validationErrors[index]?.job_profile && (
//                                 <div className='text-danger'>
//                                   {validationErrors[index].job_profile}
//                                 </div>
//                               )}
//                             </div>

//                             {/* <div className='row'>
//                               <div className='col-md-6'>

//                                 <div className='col-md-6'>
//                                   <div className='upload-box'>
//                                     <div
//                                       className='upload-method'
//                                       style={{
//                                         border: '1px dashed #ccc',
//                                         padding: '20px',
//                                         textAlign: 'center',
//                                         borderRadius: '8px',
//                                         cursor: 'pointer'
//                                       }}
//                                       onClick={() =>
//                                         document
//                                           .getElementById(`file-input-${index}`)
//                                           .click()
//                                       }
//                                     >
//                                       <input
//                                         type='file'
//                                         id={`file-input-${index}`}
//                                         name='experience_certificate'
//                                         style={{ display: 'none' }}
//                                         accept='.pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg'
//                                         multiple // Allow multiple file selection
//                                         onChange={e =>
//                                           handleFileChange(e, index)
//                                         }
//                                       />
//                                       {selectedFiles[index]?.length === 0 ||
//                                       !selectedFiles[index] ? (
//                                         <>
//                                           <p>
//                                             📎{' '}
//                                             <span
//                                               style={{
//                                                 color: '#007BFF',
//                                                 textDecoration: 'underline'
//                                               }}
//                                             >
//                                               Add files
//                                             </span>{' '}
//                                             or drag and drop files here
//                                           </p>
//                                           <p
//                                             style={{
//                                               fontSize: '12px',
//                                               color: '#666'
//                                             }}
//                                           >
//                                             Supported formats: .pdf, .doc,
//                                             .docx, .txt, .xls, .csv, .jpg, .jpeg
//                                             (max size: 5 MB)
//                                           </p>
//                                         </>
//                                       ) : (
//                                         <div
//                                           style={{
//                                             marginTop: '10px',
//                                             textAlign: 'center'
//                                           }}
//                                         >
//                                           <strong>Selected Files:</strong>
//                                           <ul>
//                                             {selectedFiles[index]?.map(
//                                               (file, idx) => (
//                                                 <li key={idx}>{file.name}</li>
//                                               )
//                                             )}
//                                           </ul>
//                                         </div>
//                                       )}
//                                     </div>

//                                     <div className='upload-options'>
//                                       <Link to='' className='google-drive'>
//                                         <i className='fa-brands fa-google-drive'></i>
//                                         Google Drive
//                                       </Link>
//                                       <Link to='' className='dropbox'>
//                                         <i className='fa-brands fa-dropbox'></i>
//                                         Dropbox
//                                       </Link>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                               <div className='col-md-6'>
//                                 <div className='scan-box'>
//                                   <img
//                                     src='/images/qr-scan.png'
//                                     alt='Scan Icon'
//                                   />
//                                   <p className='scan-text'>
//                                     Scan with your mobile phone
//                                   </p>
//                                   <p>
//                                     (Only possible when using the Job to Date
//                                     app)
//                                   </p>
//                                 </div>
//                               </div>
//                             </div> */}
//                             <div className='file-upload-container'>
//                               <div className='upload-box'>
//                                 <input
//                                   type='file'
//                                   name='experience_certificate'
//                                   id={`file-input-${index}`}
//                                   accept='.pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg'
//                                   multiple
//                                   onChange={e => handleFileChange(e, index)} // Handle file change
//                                   style={{ display: 'none' }}
//                                 />
//                                 <div
//                                   className='upload-method'
//                                   onClick={() =>
//                                     document
//                                       .getElementById(`file-input-${index}`)
//                                       .click()
//                                   }
//                                 >
//                                   <p>
//                                     <span role='img' aria-label='attachment'>
//                                       📎
//                                     </span>{' '}
//                                     <span className='upload-text'>
//                                       Add files
//                                     </span>{' '}
//                                     or drag and drop files here
//                                   </p>
//                                   <p className='upload-info'>
//                                     Supported formats: .pdf, .doc, .docx, .txt,
//                                     .xls, .csv, .jpg, .jpeg
//                                   </p>
//                                 </div>

//                                 {selectedFiles[index] &&
//                                   selectedFiles[index].length > 0 && (
//                                     <div className='file-preview'>
//                                       <strong>Selected Files:</strong>
//                                       <ul>
//                                         {selectedFiles[index].map(
//                                           (file, fileIndex) => (
//                                             <li
//                                               key={fileIndex}
//                                               className='file-item'
//                                             >
//                                               <i
//                                                 className={`fas ${getFileIcon(
//                                                   file.name
//                                                 )} file-icon`}
//                                               ></i>
//                                               <span className='file-name'>
//                                                 {file.name.length > 30
//                                                   ? `${file.name.slice(
//                                                       0,
//                                                       30
//                                                     )}...`
//                                                   : file.name}
//                                               </span>
//                                               <button
//                                                 type='button'
//                                                 onClick={() =>
//                                                   handleRemoveFile(
//                                                     index,
//                                                     fileIndex
//                                                   )
//                                                 } // Remove file by index
//                                                 className='remove-btn'
//                                               >
//                                                 &times;
//                                               </button>
//                                             </li>
//                                           )
//                                         )}
//                                       </ul>
//                                     </div>
//                                   )}
//                               </div>
//                             </div>

//                             <div className='removeButtonJobseeker mt-4'>
//                               {j.id ? (
//                                 <>
//                                   <button
//                                     type='button'
//                                     className='btn btn-primary button2'
//                                     onClick={() => handleRemove(j.id)}
//                                     style={{
//                                       color: hoverFourthButtonColor
//                                         ? primaryColor
//                                         : secondaryColor,
//                                       backgroundColor: 'white',
//                                       border: hoverFourthButtonColor
//                                         ? `2px solid ${primaryColor}`
//                                         : `2px solid ${secondaryColor}`
//                                     }}
//                                     onMouseEnter={handleFourthButtonMouseEnter}
//                                     onMouseLeave={handleFourthButtonMouseLeave}
//                                   >
//                                     {t('jobseekerEducation.removeButton')}
//                                   </button>
//                                 </>
//                               ) : (
//                                 <>
//                                   <button
//                                     type='button'
//                                     className='btn btn-primary button2'
//                                     onClick={() => handleRemoveWithoutId(index)}
//                                     style={{
//                                       color: hoverFourthButtonColor
//                                         ? primaryColor
//                                         : secondaryColor,
//                                       backgroundColor: 'white',
//                                       border: hoverFourthButtonColor
//                                         ? `2px solid ${primaryColor}`
//                                         : `2px solid ${secondaryColor}`
//                                     }}
//                                     onMouseEnter={handleFourthButtonMouseEnter}
//                                     onMouseLeave={handleFourthButtonMouseLeave}
//                                   >
//                                     {t('jobseekerEducation.removeButton')}
//                                   </button>
//                                 </>
//                               )}
//                             </div>
//                           </>
//                         )
//                       })}

//                     {experience.length <= 0 && (
//                       <>
//                         <div className='EduAddMore mb-4'>
//                           <button
//                             type='button'
//                             className='btn btn-primary button1'
//                             onClick={handleAdd}
//                             style={{
//                               backgroundColor: primaryColor,
//                               color: 'white',
//                               border: primaryColor
//                             }}
//                           >
//                             {t('jobseekerEducation.addDetails')}
//                           </button>
//                         </div>
//                       </>
//                     )}
//                   </div>
//                 </form>
//                 {experience.length > 0 && (
//                   <>
//                     <div className='EduAddMore mb-4'>
//                       <button
//                         type='button'
//                         className='btn btn-primary button1'
//                         onClick={handleAdd}
//                         style={{
//                           backgroundColor: primaryColor,
//                           color: 'white',
//                           border: primaryColor
//                         }}
//                       >
//                         {t('jobseekerEducation.addMoreButton')}
//                       </button>
//                     </div>
//                   </>
//                 )}

//                 <div className='JSEPFinalButton d-flex justify-content-between'>
//                   <Link
//                     to={`/candidates/create-resume`}
//                     type='button'
//                     // onClick={handleClick}
//                     className='btn btn-primary button1'
//                     style={{
//                       backgroundColor: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor,
//                       border: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor
//                     }}
//                     onMouseEnter={handleFirstButtonMouseEnter}
//                     onMouseLeave={handleFirstButtonMouseLeave}
//                   >
//                     Back
//                   </Link>
//                   <button
//                     // to={`/candidates/professions/experience`}
//                     type='button'
//                     onClick={handleClick}
//                     className='btn btn-primary button1'
//                     style={{
//                       backgroundColor: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor,
//                       border: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor
//                     }}
//                     onMouseEnter={handleFirstButtonMouseEnter}
//                     onMouseLeave={handleFirstButtonMouseLeave}
//                   >
//                     Next
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <Footer />
//         </>
//       )}
//     </>
//   )
// }

// export default ExperienceDetails

// import React, { useEffect, useState } from 'react'
// import JSSidebar from './JSSidebar'
// import NavBar from '../element/NavBar'
// import Footer from '../element/Footer'
// import { Link, useNavigate } from 'react-router-dom'
// import axios from 'axios'
// import ApiKey from '../api/ApiKey'
// import BaseApi from '../api/BaseApi'
// // import Multiselect from "multiselect-react-dropdown";
// import JoditEditor from 'jodit-react'
// import Select from 'react-select'
// import { useRef } from 'react'
// import Swal from 'sweetalert2'
// import Cookies from 'js-cookie'
// import LocationOnIcon from '@mui/icons-material/LocationOn'
// import { useTranslation } from 'react-i18next'
// import ReactQuill from 'react-quill'
// import 'react-quill/dist/quill.snow.css' // import styles
// import { event } from 'jquery'

// const ExperienceDetails = () => {
//   let primaryColor = Cookies.get('primaryColor')
//   let secondaryColor = Cookies.get('secondaryColor')
//   const mapKey = Cookies.get('mapKey')
//   const [t, i18n] = useTranslation('global')
//   const [editProfile, setEditProfile] = useState({
//     experience_certificate: '',
//     job_position: '',
//     company_name: '',
//     location: '',
//     job_profile: '',
//     from_month: '',
//     to_month: '',
//     from_year: '',
//     to_year: '',
//     // startMonth: '',
//     // startYear: '',
//     // endMonth: '',
//     // endYear: '',
//     ongoing: false,
//     selected_value: ''
//   })
//   const [selectedFiles, setSelectedFiles] = useState([])
//   // const [internships, setInternships] = useState([])
//   const [experience, setExperience] = useState([])
//   const [error, setError] = useState('')
//   const [validationErrors, setValidationErrors] = useState([])
//   const [yearsList, setYearsList] = useState([])
//   const [selected_value, setSelected_value] = useState('')
//   const [options, setOptions] = useState([])
//   const [errors, setErrors] = useState({})
//   const [educationDetails, setEducationDetails] = useState([])
//   const [loading, setLoading] = useState(false)
//   const [autocompleteService, setAutocompleteService] = useState(null)
//   const [suggestionsPreferred, setSuggestionsPreferred] = useState([])
//   const [suggestionsNative, setSuggestionsNative] = useState([])
//   const [suggestionTakenNative, setSuggestionTakenNative] = useState(false)

//   const navigate = useNavigate()
//   const tokenKey = Cookies.get('tokenClient') // Assuming you're using cookies for the token

//   const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false)

//   const handleFirstButtonMouseEnter = () => {
//     setHoverFirstButtonColor(true)
//   }

//   const handleFirstButtonMouseLeave = () => {
//     setHoverFirstButtonColor(false)
//   }

//   const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false)

//   const handleSecondButtonMouseEnter = () => {
//     setHoverSecondButtonColor(true)
//   }

//   const handleSecondButtonMouseLeave = () => {
//     setHoverSecondButtonColor(false)
//   }

//   const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false)

//   const handleThirdButtonMouseEnter = () => {
//     setHoverThirdButtonColor(true)
//   }

//   const handleThirdButtonMouseLeave = () => {
//     setHoverThirdButtonColor(false)
//   }

//   const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

//   const handleFourthButtonMouseEnter = () => {
//     setHoverFourthButtonColor(true)
//   }
//   const [fileName, setFileName] = useState('')

//   const handleFourthButtonMouseLeave = () => {
//     setHoverFourthButtonColor(false)
//   }

//   const getFileIcon = fileName => {
//     const ext = fileName.split('.').pop().toLowerCase()
//     const icons = {
//       pdf: 'fa-file-pdf',
//       doc: 'fa-file-word',
//       docx: 'fa-file-word',
//       txt: 'fa-file-alt',
//       xls: 'fa-file-excel',
//       xlsx: 'fa-file-excel',
//       csv: 'fa-file-csv',
//       jpg: 'fa-file-image',
//       jpeg: 'fa-file-image'
//     }
//     return icons[ext] || 'fa-file' // Default to a generic file icon
//   }

//   const handleFileChange = (e, index) => {
//     const files = Array.from(e.target.files) // Convert FileList to an array

//     if (files.length > 0) {
//       console.log(`Files selected for index ${index}:`, files)

//       // Get the current selected files for this index
//       const currentFiles = selectedFiles[index] || []

//       // Check if more than 5 files are selected
//       if (currentFiles.length + files.length > 5) {
//         Swal.fire({
//           icon: 'error',
//           title: 'Maximum files limit reached',
//           text: 'You can upload a maximum of 5 files.'
//         })
//         return
//       }

//       // Check for duplicate files by comparing file names
//       const newFiles = files.filter(
//         file =>
//           !currentFiles.some(existingFile => existingFile.name === file.name)
//       )

//       // If there are duplicate files, show an error
//       if (newFiles.length !== files.length) {
//         Swal.fire({
//           icon: 'error',
//           title: 'Duplicate files',
//           text: 'Duplicate files are not allowed.'
//         })
//         return
//       }

//       // Update the `selectedFiles` state for the specific index
//       setSelectedFiles(prevFiles => {
//         const updatedFiles = { ...prevFiles } // Create a shallow copy of the object
//         updatedFiles[index] = [...currentFiles, ...newFiles] // Append new files to the existing ones
//         return updatedFiles
//       })

//       // Update the `experience` state for the specific index
//       setExperience(prevExperience => {
//         const updatedExperience = [...prevExperience] // Create a shallow copy of the array
//         updatedExperience[index] = {
//           ...updatedExperience[index], // Create a shallow copy of the specific object
//           experience_certificate: [
//             ...(updatedExperience[index]?.experience_certificate || []), // Keep existing certificates
//             ...newFiles // Append new files
//           ]
//         }
//         return updatedExperience // Return the updated array
//       })
//     } else {
//       console.warn(`No files selected for index ${index}.`)
//     }
//   }

//   const handleRemoveFile = (index, fileIndex) => {
//     setSelectedFiles(prevFiles => {
//       const updatedFiles = { ...prevFiles } // Create a shallow copy of the object
//       updatedFiles[index] = updatedFiles[index].filter(
//         (_, idx) => idx !== fileIndex
//       ) // Remove the file by index
//       return updatedFiles
//     })

//     setExperience(prevEducation => {
//       const updatedEducation = [...prevEducation] // Create a shallow copy of the array
//       updatedEducation[index] = {
//         ...updatedEducation[index], // Create a shallow copy of the specific object
//         experience_certificate: updatedEducation[
//           index
//         ].experience_certificate.filter(
//           (_, idx) => idx !== fileIndex // Remove the file by index
//         )
//       }
//       return updatedEducation // Return the updated array
//     })
//   }

//   const handleChange = (e, index, mainID) => {
//     const { name, value, type, checked } = e.target

//     // For checkboxes, we need to use `checked` instead of `value`
//     const fieldValue = type === 'checkbox' ? checked : value

//     setExperience(prevInternships => {
//       const updatedDetails = [...prevInternships] // Create a shallow copy of the array
//       updatedDetails[index] = {
//         ...updatedDetails[index], // Create a shallow copy of the specific internship object
//         [name]: fieldValue // Update the specific field with the new value or checked state
//       }
//       return updatedDetails // Return the updated array
//     })
//     setValidationErrors(prevErrors => {
//       const updatedErrors = [...prevErrors]
//       if (updatedErrors[index]) {
//         updatedErrors[index] = {
//           ...updatedErrors[index],
//           [name]: '' // Clear the validation error for the specific field
//         }
//       }
//       return updatedErrors
//     })
//   }

//   const handleAdd = () => {
//     const newQualification = {
//       experience_certificate: '',
//       job_position: '',
//       company_name: '',
//       location: '',
//       job_profile: '',
//       from_year: '',
//       to_year: '',
//       from_month: '',
//       to_month: '',
//       // startMonth: '',
//       // startYear: '',
//       // endMonth: '',
//       // endYear: '',
//       ongoing: false
//     }

//     // setEducationDetails(prevEducationDetails => [
//     //   ...prevEducationDetails,
//     //   newQualification
//     // ])
//     setExperience(prevInternships => [...prevInternships, newQualification])
//   }
//   const [suggestionTakenPreferred, setSuggestionTakenPreferred] =
//     useState(false)
//   const handlePreferredLocationChange = e => {
//     const { value } = e.target
//     setSuggestionTakenPreferred(false)
//     if (value == '') {
//       setSuggestionTakenPreferred(true)
//     }
//     if (value != '') {
//       setErrors({
//         ...errors,
//         pre_location: ''
//       })
//     }

//     setEditProfile(prevFilter => ({
//       ...prevFilter,
//       pre_location: value
//     }))

//     if (autocompleteService) {
//       // Call Google Maps Autocomplete API
//       autocompleteService.getPlacePredictions(
//         {
//           input: value,
//           types: ['(cities)'] // Restrict to cities if needed
//         },
//         (predictions, status) => {
//           if (status === 'OK' && predictions) {
//             setSuggestionsPreferred(
//               predictions.map(prediction => prediction.description)
//             )
//           } else {
//             setSuggestionsPreferred([])
//           }
//         }
//       )
//     }
//     if (editProfile.pre_location === '') {
//       setSuggestionsPreferred([])
//     }
//   }

//   const handleNativeLocationChange = e => {
//     const { value } = e.target
//     setSuggestionTakenNative(false)
//     if (value == '') {
//       setSuggestionTakenNative(true)
//     }
//     if (value != '') {
//       setErrors({
//         ...errors,
//         location: ''
//       })
//     }

//     setEditProfile(prevFilter => ({
//       ...prevFilter,
//       location: value
//     }))

//     if (autocompleteService) {
//       // Call Google Maps Autocomplete API
//       autocompleteService.getPlacePredictions(
//         {
//           input: value,
//           types: ['(cities)'] // Restrict to cities if needed
//         },
//         (predictions, status) => {
//           if (status === 'OK' && predictions) {
//             setSuggestionsNative(
//               predictions.map(prediction => prediction.description)
//             )
//           } else {
//             setSuggestionsNative([])
//           }
//         }
//       )
//     }
//     if (editProfile.location === '') {
//       setSuggestionsNative([])
//     }
//   }

//   const validateFields = () => {
//     // Ensure experience is an array
//     if (!Array.isArray(experience)) {
//       setValidationErrors([{ error: 'Experience data is invalid' }])
//       return false
//     }

//     const errors = experience.map((exp, index) => ({
//       from_month:
//         exp?.from_month?.trim() === '' ? 'Start month is required' : '',
//       to_month: exp?.to_month?.trim() === '' ? 'End Month is required' : '',
//       // from_year: exp?.from_year?.trim() === '' ? 'Start year is required' : '',

//       from_year:
//         exp?.from_year &&
//         typeof exp?.from_year === 'string' &&
//         exp?.from_year.trim() === ''
//           ? 'Start year is required'
//           : '',

//       // to_year: exp?.to_year?.trim() === '' ? 'End year is required' : '',
//       to_year:
//         exp?.to_year &&
//         typeof exp?.to_year === 'string' &&
//         exp?.to_year.trim() === ''
//           ? 'End year is required'
//           : '',

//       job_position:
//         exp?.job_position?.trim() === '' ? 'Job position is required' : '',
//       company_name:
//         exp?.company_name?.trim() === '' ? 'Company name is required' : '',
//       location: exp?.location?.trim() === '' ? 'Location is required' : '',
//       job_profile:
//         exp?.job_profile?.trim() === '' ? 'Job profile is required' : '',
//       ongoing: exp?.ongoing === false ? 'Ongoing status is required' : ''
//     }))

//     setValidationErrors(errors) // Set the validation errors in the state

//     const isValid = errors.every(
//       error =>
//         !error.job_position &&
//         !error.company_name &&
//         !error.location &&
//         !error.job_profile &&
//         !error.from_year &&
//         !error.from_month &&
//         !error.to_month &&
//         !error.to_year &&
//         !error.ongoing
//       // !error.experience_certificate
//     )

//     return isValid
//   }

//   const handleRemove = async id => {
//     try {
//       const confirmationResult = await Swal.fire({
//         title: t('jobseekerEducation.removeConfirmTitle'),
//         text: t('jobseekerEducation.removeConfirmTxt'),
//         icon: 'question',
//         showCancelButton: true,
//         confirmButtonText: t('jobseekerEducation.yes'),
//         cancelButtonText: t('jobseekerEducation.no')
//       })
//       if (confirmationResult.isConfirmed) {
//         const response = await axios.post(
//           BaseApi + `/candidates/deleteeducation/${id}`,
//           null,
//           {
//             headers: {
//               'Content-Type': 'application/json',
//               key: ApiKey,
//               token: tokenKey
//             }
//           }
//         )
//         if (response.data.status === 200) {
//           getData()
//           Swal.fire({
//             title: t('jobseekerEducation.removeSuccessTitle'),
//             icon: 'success',
//             confirmButtonText: t('jobseekerEducation.close')
//           })
//         } else if (response.data.status === 400) {
//           Cookies.remove('tokenClient')
//           Cookies.remove('user_type')
//           Cookies.remove('fname')
//           navigate('/')
//           Swal.fire({
//             title: response.data.message,
//             icon: 'warning',
//             confirmButtonText: t('jobseekerEducation.close')
//           })
//         } else {
//           Swal.fire({
//             title: response.data.message,
//             icon: 'error',
//             confirmButtonText: t('jobseekerEducation.close')
//           })
//         }
//       }
//     } catch (error) {
//       setLoading(false)
//       if (error.message === 'Network Error') {
//         Cookies.remove('tokenClient')
//         Cookies.remove('user_type')
//         Cookies.remove('fname')
//         navigate('/')
//         Swal.fire({
//           title: t('tokenExpired.tokenExpired'),
//           icon: 'warning',
//           confirmButtonText: t('jobDescription.close')
//         })
//         setTimeout(function () {
//           window.location.reload()
//         }, 3000)
//       }
//       Swal.fire({
//         title: t('jobseekerEducation.removeFailedTitle'),
//         text: t('jobseekerEducation.removeFailedTxt'),
//         icon: 'error',
//         confirmButtonText: t('jobseekerEducation.close')
//       })
//     }
//   }

//   const handleRemoveWithoutId = indexToRemove => {
//     // Remove internship entry without ID (local state update)
//     setExperience(prevInternshipDetails =>
//       prevInternshipDetails.filter((_, index) => index !== indexToRemove)
//     )
//   }

//   // Function to handle form submission
//   const handleClick = async () => {
//     const updatedExperience = {
//       ...experience,
//       selected_value: selected_value,
//       experience_certificate: experience.experience_certificate
//     }
//     if (!validateFields()) {
//       window.scrollTo(0, 0) // Scroll to the top to see validation errors
//       return
//     }

//     try {
//       const confirmationResult = await Swal.fire({
//         title: 'Confirm Experience Details',
//         text: 'Are you sure you want to save these details?',
//         icon: 'question',
//         showCancelButton: true,
//         confirmButtonText: 'Yes',
//         cancelButtonText: 'No'
//       })

//       if (confirmationResult.isConfirmed) {
//         setLoading(true)

//         const response = await axios.post(
//           BaseApi + '/candidates/editExperience',
//           { Experience: updatedExperience }, // Send education details as payload
//           {
//             headers: {
//               'Content-Type': 'multipart/form-data',
//               key: ApiKey,
//               token: tokenKey
//             }
//           }
//         )

//         setLoading(false)

//         if (response.data.status === 200) {
//           Swal.fire({
//             title: 'Success!',
//             text: 'Experience details updated successfully.',
//             icon: 'success',
//             confirmButtonText: 'Close'
//           })
//           setEducationDetails([]) // Reset education details
//           // Optional: Reload the page or call a function to update UI
//           navigate('/candidates/internships')
//         } else if (response.data.status === 400) {
//           Cookies.remove('tokenClient')
//           Cookies.remove('user_type')
//           Cookies.remove('fname')
//           navigate('/candidates/parttime') // Redirect if token expired or other issue
//           Swal.fire({
//             title: response.data.message,
//             icon: 'warning',
//             confirmButtonText: 'Close'
//           })
//         } else {
//           Swal.fire({
//             title: response.data.message,
//             icon: 'error',
//             confirmButtonText: 'Close'
//           })
//         }
//       }
//     } catch (error) {
//       setLoading(false)
//       // if (error.message === "Network Error") {
//       //   Cookies.remove("tokenClient");
//       //   Cookies.remove("user_type");
//       //   Cookies.remove("fname");
//       //   navigate("/candidates/internships");
//       //   Swal.fire({
//       //     title: "Token Expired",
//       //     icon: "warning",
//       //     confirmButtonText: "Close",
//       //   });
//       //   setTimeout(function () {
//       //     window.location.reload(); // Reload page if the token is expired
//       //   }, 3000);
//       // }

//       Swal.fire({
//         title: 'Failed to update Experience Details',
//         text: 'There was an error updating your experience details. Please try again.',
//         icon: 'error',
//         confirmButtonText: 'Close'
//       })
//     }
//   }

//   const handleNativeSuggestionClick = suggestion => {
//     // Update the input value with the clicked suggestion
//     handleNativeLocationChange({
//       target: { name: 'location', value: suggestion }
//     })

//     setSuggestionTakenNative(true)
//     // Clear the suggestions
//     setSuggestionsNative([])
//   }

//   useEffect(() => {
//     // Check if tokenKey is not present
//     if (!tokenKey) {
//       // Redirect to the home page
//       navigate('/user/jobseekerlogin')
//     } else {
//       // TokenKey is present, fetch data or perform other actions
//       getData()

//       window.scrollTo(0, 0)
//       // if(educationDetails.basic_course_id) {
//       // getSpecializationList(educationDetails.basic_course_id);
//       // }
//     }
//   }, [tokenKey, navigate])

//   const handleSelectChange = async event => {
//     const selectedValue = event.target.value
//     setSelected_value(selectedValue) // Update state

//     setExperience({
//       ...editProfile,
//       selected_value: selectedValue // Update the selected_value in the state
//     })

//     console.log('Selected Value:', selectedValue)

//     if (selectedValue === 'Professional Experience') {
//       // // Navigate to the Experience page and fetch data
//       // try {
//       //   const response = await axios.get(BaseApi + '/candidates/editExperience');
//       //   console.log('Experience Data:', response.data);
//       //   // navigate('/experience');
//       // } catch (error) {
//       //   console.error('Error fetching Experience data:', error);
//       // }
//     } else if (selectedValue === 'Internship') {
//       // Navigate to the Internship page and fetch data
//       try {
//         const response = await axios.get(
//           BaseApi + '/candidates/addinternships',
//           {
//             headers: {
//               'Content-Type': 'application/json',
//               key: ApiKey,
//               token: tokenKey
//             }
//           }
//         )
//         // console.log('Internship Data:', response.data)
//         navigate('/candidates/internships')
//       } catch (error) {
//         console.error('Error fetching Internship data:', error)
//       }
//     } else if (selectedValue === 'Part Time Job') {
//       // Navigate to the Part-Time page and fetch data
//       try {
//         const response = await axios.get(
//           BaseApi + '/candidates/addparttimejobs',
//           {
//             headers: {
//               'Content-Type': 'application/json',
//               key: ApiKey,
//               token: tokenKey
//             }
//           }
//         )
//         // console.log('Part-Time Data:', response.data)
//         navigate('/candidates/parttime')
//       } catch (error) {
//         console.error('Error fetching Part-Time data:', error)
//       }
//     }
//   }

//   const getData = async () => {
//     try {
//       setLoading(true)
//       const response = await axios.post(
//         BaseApi + '/candidates/editExperience',
//         null, // Pass null as the request body if not required
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             key: ApiKey,
//             token: tokenKey
//           }
//         }
//       )
//       if (response.data.status === 200) {
//         setExperience(response.data.response.expDetails)

//         setYearsList(response.data.response.yearList)

//         setSelected_value(response.data.response.expDetails.selected_value)

//         setOptions(response.data.response.options)

//         console.log(response.data.response.expDetails)

//         // var categoryList = response.data.response.categoryList
//         // var interestCategory = response.data.response.interest_categories
//         // var selectedCat = []

//         // categoryList.forEach(element => {
//         //   for (let i = 0; i < interestCategory.length; i++) {
//         //     if (parseInt(interestCategory[i]) === element.id) {
//         //       let obj = {
//         //         value: element.id,
//         //         label: element.name
//         //       }
//         //       selectedCat.push(obj)
//         //     }
//         //   }
//         // })
//         console.log('Heres')

//         setLoading(false)
//         setEditProfile(response.data.response)

//         console.log('check')
//       } else if (response.data.status === 400) {
//         Cookies.remove('tokenClient')
//         Cookies.remove('user_type')
//         Cookies.remove('fname')
//         navigate('/')
//         Swal.fire({
//           title: response.data.message,
//           icon: 'warning',
//           confirmButtonText: t('searchJobPage.close')
//         })
//       } else {
//         Swal.fire({
//           title: response.data.message,
//           icon: 'error',
//           confirmButtonText: t('searchJobPage.close')
//         })
//       }
//     } catch (error) {
//       setLoading(false)
//       if (error.message === 'Network Error') {
//         Cookies.remove('tokenClient')
//         Cookies.remove('user_type')
//         Cookies.remove('fname')
//         navigate('/')
//         Swal.fire({
//           title: t('tokenExpired.tokenExpired'),
//           icon: 'warning',
//           confirmButtonText: t('jobDescription.close')
//         })
//         setTimeout(function () {
//           window.location.reload()
//         }, 3000)
//       }
//       console.log('Cannot get data of edit profile page')
//     }
//   }

//   return (
//     <>
//       <NavBar />
//       {loading ? (
//         <div className='loader-container'></div>
//       ) : (
//         <>
//           <div className='container editProfile'>
//             <div className='row'>
//               <div className='col-lg-3'>
//                 <JSSidebar />
//               </div>

//               <div
//                 className='col-lg-9 mb-5'
//                 style={{
//                   borderLeft: '2px solid #e6e8e7',
//                   borderRight: '2px solid #e6e8e7'
//                 }}
//               >
//                 <div className='mx-3 d-flex PageHeader'>
//                   <h3 className=''>Professional Experience</h3>
//                 </div>
//                 <p className='mx-3'>
//                   Begin with your most recent or current position and work
//                   backward. <br /> (If you have a lot of experience, just
//                   provide your most recent positions)
//                 </p>

//                 <form>
//                   <div className='mb-5 mt-4 mx-3'>
//                     <select
//                       className='form-select mb-3'
//                       aria-label='Default select example'
//                       onChange={handleSelectChange}
//                       value={selected_value}
//                       name='selected_value'
//                     >
//                       {Object.entries(options).map(([index, value]) => {
//                         return <option value={value}>{value}</option>
//                       })}
//                     </select>
//                     {Array.isArray(experience) &&
//                       experience.length > 0 &&
//                       experience.map((j, index) => {
//                         return (
//                           <>
//                             <h4 className='mt-5 mb-5'>
//                               Experience Details {index + 1}:
//                             </h4>

//                             <div className='form-outline mb-5 DashBoardInputBx'>
//                               <label
//                                 className='form-label'
//                                 htmlFor='form3Example1'
//                               >
//                                 Job Position
//                                 <span className='RedStar'>*</span>
//                               </label>

//                               <input
//                                 type='text'
//                                 id='form3Example1'
//                                 className={`form-control ${
//                                   validationErrors[index]?.job_position &&
//                                   'input-error'
//                                 }`}
//                                 placeholder='Job Position'
//                                 name='job_position'
//                                 value={j.job_position || ''}
//                                 onChange={e => handleChange(e, index, j.id)}
//                               />

//                               {validationErrors[index]?.job_position && (
//                                 <div className='text-danger'>
//                                   {validationErrors[index].job_position}
//                                 </div>
//                               )}
//                             </div>

//                             <div className='form-outline mb-5 DashBoardInputBx'>
//                               <label
//                                 className='form-label'
//                                 htmlFor='form3Example3'
//                               >
//                                 Employer (company Name){' '}
//                                 <span className='RedStar'>*</span>
//                               </label>
//                               <input
//                                 type='text'
//                                 id='form3Example3'
//                                 className={`form-control ${
//                                   validationErrors[index]?.company_name &&
//                                   'input-error'
//                                 }`}
//                                 placeholder='Employer (company Name)'
//                                 name='company_name'
//                                 value={j.company_name}
//                                 onChange={e => handleChange(e, index, j.id)}
//                               />

//                               {validationErrors[index]?.company_name && (
//                                 <div className='text-danger'>
//                                   {validationErrors[index].company_name}
//                                 </div>
//                               )}
//                             </div>

//                             <div className='form-outline mb-5 DashBoardInputBx'>
//                               <label
//                                 className='form-label'
//                                 htmlFor='form3Example3'
//                               >
//                                 Location
//                                 <span className='RedStar'>*</span>
//                               </label>
//                               <input
//                                 type='text'
//                                 id='form3Example3'
//                                 className={`form-control ${
//                                   validationErrors[index]?.location &&
//                                   'input-error'
//                                 }`}
//                                 placeholder='Location'
//                                 name='location'
//                                 value={j.location}
//                                 onChange={e => handleChange(e, index, j.id)}
//                               />
//                               {suggestionsNative.length > 0 && (
//                                 <div
//                                   className='suggestions'
//                                   style={{
//                                     display: suggestionTakenNative ? 'none' : ''
//                                   }}
//                                 >
//                                   <ul className='locationDropdown'>
//                                     {suggestionsNative.map(
//                                       (suggestion, index) => (
//                                         <div
//                                           key={index}
//                                           className='suggestion-item'
//                                         >
//                                           <li
//                                             onClick={() =>
//                                               handleNativeSuggestionClick(
//                                                 suggestion
//                                               )
//                                             }
//                                           >
//                                             <div className='eachLocation'>
//                                               <div className='locationIcon'>
//                                                 <LocationOnIcon fontSize='small' />
//                                               </div>{' '}
//                                               <div className='locationSuggestion'>
//                                                 {suggestion}
//                                               </div>
//                                             </div>{' '}
//                                           </li>
//                                         </div>
//                                       )
//                                     )}
//                                   </ul>
//                                 </div>
//                               )}

//                               {validationErrors[index]?.location && (
//                                 <div className='text-danger'>
//                                   {validationErrors[index].location}
//                                 </div>
//                               )}
//                             </div>
//                             <div className='form-outline mb-5 DashBoardInputBx'>
//                               <h5>Duration</h5>
//                             </div>

//                             <div className='form-outline mb-5 DashBoardInputBx d-flex gap-4'>
//                               <label
//                                 className='form-label'
//                                 htmlFor='startEmployment'
//                               >
//                                 Start of Employment
//                                 <span className='RedStar'>*</span>
//                               </label>

//                               <div className='d-flex gap-3 form-control'>
//                                 <div className='w-50'>
//                                   <select
//                                     name='from_month'
//                                     id='startMonth'
//                                     className={`form-control form-select ${
//                                       validationErrors[index]?.from_month &&
//                                       'input-error'
//                                     }`}
//                                     value={j.from_month}
//                                     onChange={e => handleChange(e, index, j.id)}
//                                   >
//                                     <option value=''>Select Month</option>
//                                     <option value='01'>January</option>
//                                     <option value='02'>February</option>
//                                     <option value='03'>March</option>
//                                     <option value='04'>April</option>
//                                     <option value='05'>May</option>
//                                     <option value='06'>June</option>
//                                     <option value='07'>July</option>
//                                     <option value='08'>August</option>
//                                     <option value='09'>September</option>
//                                     <option value='10'>October</option>
//                                     <option value='11'>November</option>
//                                     <option value='12'>December</option>
//                                   </select>
//                                   {validationErrors[index]?.from_month && (
//                                     <div className='text-danger small'>
//                                       {validationErrors[index].from_month}
//                                     </div>
//                                   )}
//                                 </div>

//                                 <div className='w-50'>
//                                   <select
//                                     name='from_year'
//                                     id='startYear'
//                                     className={`form-control form-select ${
//                                       validationErrors[index]?.from_year &&
//                                       'input-error'
//                                     }`}
//                                     value={j.from_year}
//                                     onChange={e => handleChange(e, index, j.id)}
//                                   >
//                                     <option selected value=''>
//                                       {t('jobseekerExperience.selectYear')}
//                                     </option>
//                                     {Object.entries(yearsList).map(
//                                       ([index, value]) => {
//                                         return (
//                                           <option value={value}>{value}</option>
//                                         )
//                                       }
//                                     )}
//                                   </select>
//                                   {validationErrors[index]?.from_year && (
//                                     <div className='text-danger small'>
//                                       {validationErrors[index].from_year}
//                                     </div>
//                                   )}
//                                 </div>
//                               </div>

//                               <div className='d-flex gap-3  form-control'>
//                                 {j.ongoing ? (
//                                   <div className='w-100 d-flex align-items-center'>
//                                     <span className='fw-bold'>Present</span>
//                                   </div>
//                                 ) : (
//                                   <>
//                                     <div className='w-50'>
//                                       <label className='form-emplyoer w-25'>
//                                         End of Employment
//                                         <span className='RedStar'>*</span>
//                                       </label>
//                                       <select
//                                         name='to_month'
//                                         className={`form-control form-select ${
//                                           validationErrors[index]?.to_month &&
//                                           'input-error'
//                                         }`}
//                                         value={j.to_month}
//                                         onChange={e =>
//                                           handleChange(e, index, j.id)
//                                         }
//                                       >
//                                         <option value=''>Select Month</option>
//                                         <option value='01'>January</option>
//                                         <option value='02'>February</option>
//                                         <option value='03'>March</option>
//                                         <option value='04'>April</option>
//                                         <option value='05'>May</option>
//                                         <option value='06'>June</option>
//                                         <option value='07'>July</option>
//                                         <option value='08'>August</option>
//                                         <option value='09'>September</option>
//                                         <option value='10'>October</option>
//                                         <option value='11'>November</option>
//                                         <option value='12'>December</option>
//                                       </select>
//                                       {validationErrors[index]?.to_month && (
//                                         <div className='text-danger small'>
//                                           {validationErrors[index].to_month}
//                                         </div>
//                                       )}
//                                     </div>

//                                     <div className='w-50'>
//                                       <select
//                                         name='to_year'
//                                         className={`form-control form-select ${
//                                           validationErrors[index]?.to_year &&
//                                           'input-error'
//                                         }`}
//                                         value={j.to_year}
//                                         onChange={e =>
//                                           handleChange(e, index, j.id)
//                                         }
//                                       >
//                                         <option selected value=''>
//                                           {t('jobseekerExperience.selectYear')}
//                                         </option>
//                                         {Object.entries(yearsList).map(
//                                           ([index, value]) => {
//                                             return (
//                                               <option value={value}>
//                                                 {value}
//                                               </option>
//                                             )
//                                           }
//                                         )}
//                                       </select>
//                                       {validationErrors[index]?.to_year && (
//                                         <div className='text-danger small'>
//                                           {validationErrors[index].to_year}
//                                         </div>
//                                       )}
//                                     </div>
//                                   </>
//                                 )}
//                               </div>
//                             </div>

//                             <div className='form-outline mb-5 DashBoardInputBx ongoing-container'>
//                               <input
//                                 type='checkbox'
//                                 id='ongoing'
//                                 name='ongoing'
//                                 checked={j.ongoing}
//                                 onChange={e => handleChange(e, index, j.id)}
//                                 className='form-check-input'
//                               />
//                               <label
//                                 htmlFor='ongoing'
//                                 className='ongoing-label'
//                               >
//                                 Ongoing<span className='RedStar'>*</span>
//                               </label>
//                             </div>

//                             <div className='form-outline mb-5 DashBoardInputBx'>
//                               <label
//                                 className='form-label'
//                                 htmlFor='form3Example3'
//                               >
//                                 Job Profile
//                                 <span className='RedStar'>*</span>
//                               </label>
//                               <input
//                                 type='text'
//                                 id='form3Example3'
//                                 className={`form-control ${
//                                   validationErrors[index]?.job_profile &&
//                                   'input-error'
//                                 }`}
//                                 placeholder='Job Profile'
//                                 name='job_profile'
//                                 value={j.job_profile}
//                                 onChange={e => handleChange(e, index, j.id)}
//                               />

//                               {validationErrors[index]?.job_profile && (
//                                 <div className='text-danger'>
//                                   {validationErrors[index].job_profile}
//                                 </div>
//                               )}
//                             </div>

//                             <div className='file-upload-container'>
//                               <div className='upload-box'>
//                                 <input
//                                   type='file'
//                                   name='experience_certificate'
//                                   id={`file-input-${index}`}
//                                   accept='.pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg'
//                                   multiple
//                                   onChange={e => handleFileChange(e, index)}
//                                   style={{ display: 'none' }}
//                                 />
//                                 <div
//                                   className='upload-method'
//                                   onClick={() =>
//                                     document
//                                       .getElementById(`file-input-${index}`)
//                                       .click()
//                                   }
//                                 >
//                                   <p>
//                                     <span role='img' aria-label='attachment'>
//                                       📎
//                                     </span>{' '}
//                                     <span className='upload-text'>
//                                       Add files
//                                     </span>{' '}
//                                     or drag and drop files here
//                                   </p>
//                                   <p className='upload-info'>
//                                     Supported formats: .pdf, .doc, .docx, .txt,
//                                     .xls, .csv, .jpg, .jpeg
//                                   </p>
//                                 </div>

//                                 {selectedFiles[index] &&
//                                   selectedFiles[index].length > 0 && (
//                                     <div className='file-preview'>
//                                       <strong>Selected Files:</strong>
//                                       <ul>
//                                         {selectedFiles[index].map(
//                                           (file, fileIndex) => (
//                                             <li
//                                               key={fileIndex}
//                                               className='file-item'
//                                             >
//                                               <i
//                                                 className={`fas ${getFileIcon(
//                                                   file.name
//                                                 )} file-icon`}
//                                               ></i>
//                                               <span className='file-name'>
//                                                 {file.name.length > 30
//                                                   ? `${file.name.slice(
//                                                       0,
//                                                       30
//                                                     )}...`
//                                                   : file.name}
//                                               </span>
//                                               <button
//                                                 type='button'
//                                                 onClick={() =>
//                                                   handleRemoveFile(
//                                                     index,
//                                                     fileIndex
//                                                   )
//                                                 }
//                                                 className='remove-btn'
//                                               >
//                                                 &times;
//                                               </button>
//                                             </li>
//                                           )
//                                         )}
//                                       </ul>
//                                     </div>
//                                   )}

//                                 {selectedFiles[index] &&
//                                   selectedFiles[index].length > 5 && (
//                                     <div className='text-danger small'>
//                                       You can upload a maximum of 5 files.
//                                     </div>
//                                   )}

//                                 {selectedFiles[index] &&
//                                   selectedFiles[index].some(
//                                     (file, idx, arr) =>
//                                       arr.findIndex(
//                                         f => f.name === file.name
//                                       ) !== idx
//                                   ) && (
//                                     <div className='text-danger small'>
//                                       Duplicate files are not allowed.
//                                     </div>
//                                   )}
//                               </div>
//                             </div>

//                             <div className='removeButtonJobseeker mt-4'>
//                               {j.id ? (
//                                 <>
//                                   <button
//                                     type='button'
//                                     className='btn btn-primary button2'
//                                     onClick={() => handleRemove(j.id)}
//                                     style={{
//                                       color: hoverFourthButtonColor
//                                         ? primaryColor
//                                         : secondaryColor,
//                                       backgroundColor: 'white',
//                                       border: hoverFourthButtonColor
//                                         ? `2px solid ${primaryColor}`
//                                         : `2px solid ${secondaryColor}`
//                                     }}
//                                     onMouseEnter={handleFourthButtonMouseEnter}
//                                     onMouseLeave={handleFourthButtonMouseLeave}
//                                   >
//                                     {t('jobseekerEducation.removeButton')}
//                                   </button>
//                                 </>
//                               ) : (
//                                 <>
//                                   <button
//                                     type='button'
//                                     className='btn btn-primary button2'
//                                     onClick={() => handleRemoveWithoutId(index)}
//                                     style={{
//                                       color: hoverFourthButtonColor
//                                         ? primaryColor
//                                         : secondaryColor,
//                                       backgroundColor: 'white',
//                                       border: hoverFourthButtonColor
//                                         ? `2px solid ${primaryColor}`
//                                         : `2px solid ${secondaryColor}`
//                                     }}
//                                     onMouseEnter={handleFourthButtonMouseEnter}
//                                     onMouseLeave={handleFourthButtonMouseLeave}
//                                   >
//                                     {t('jobseekerEducation.removeButton')}
//                                   </button>
//                                 </>
//                               )}
//                             </div>
//                           </>
//                         )
//                       })}
//                     {experience.length <= 0 && (
//                       <>
//                         <div className='EduAddMore mb-4'>
//                           <button
//                             type='button'
//                             className='btn btn-primary button1'
//                             onClick={handleAdd}
//                             style={{
//                               backgroundColor: primaryColor,
//                               color: 'white',
//                               border: primaryColor
//                             }}
//                           >
//                             {t('jobseekerEducation.addDetails')}
//                           </button>
//                         </div>
//                       </>
//                     )}
//                   </div>
//                 </form>

//                 {experience.length > 0 && (
//                   <>
//                     <div className='EduAddMore mb-4'>
//                       <button
//                         type='button'
//                         className='btn btn-primary button1'
//                         onClick={handleAdd}
//                         style={{
//                           backgroundColor: primaryColor,
//                           color: 'white',
//                           border: primaryColor
//                         }}
//                       >
//                         {t('jobseekerEducation.addMoreButton')}
//                       </button>
//                     </div>
//                   </>
//                 )}

//                 <div className='JSEPFinalButton d-flex justify-content-between'>
//                   <Link
//                     to={`/candidates/create-resume`}
//                     type='button'
//                     // onClick={handleClick}
//                     className='btn btn-primary button1'
//                     style={{
//                       backgroundColor: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor,
//                       border: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor
//                     }}
//                     onMouseEnter={handleFirstButtonMouseEnter}
//                     onMouseLeave={handleFirstButtonMouseLeave}
//                   >
//                     Back
//                   </Link>
//                   <button
//                     // to={`/candidates/professions/experience`}
//                     type='button'
//                     onClick={handleClick}
//                     className='btn btn-primary button1'
//                     style={{
//                       backgroundColor: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor,
//                       border: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor
//                     }}
//                     onMouseEnter={handleFirstButtonMouseEnter}
//                     onMouseLeave={handleFirstButtonMouseLeave}
//                   >
//                     Next
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <Footer />
//         </>
//       )}
//     </>
//   )
// }

// export default ExperienceDetails

// import React, { useEffect, useState } from 'react'
// import JSSidebar from './JSSidebar'
// import NavBar from '../element/NavBar'
// import Footer from '../element/Footer'
// import { Link, useNavigate } from 'react-router-dom'
// import axios from 'axios'
// import ApiKey from '../api/ApiKey'
// import BaseApi from '../api/BaseApi'
// import Swal from 'sweetalert2'
// import Cookies from 'js-cookie'
// import { useTranslation } from 'react-i18next'

// const JobForm = ({ jobType, onRemove, j, index }) => {
//   let primaryColor = Cookies.get('primaryColor')
//   let secondaryColor = Cookies.get('secondaryColor')
//   const [t, i18n] = useTranslation('global')
//   const [validationErrors, setValidationErrors] = useState([])
//   const [yearsList, setYearsList] = useState([])
//   const [selected_value, setSelected_value] = useState('')
//   const [loading, setLoading] = useState(false)
//   const [options, setOptions] = useState([])
//   const [experience, setExperience] = useState([
//     {
//       experience_certificate: '',
//       job_position: '',
//       company_name: '',
//       location: '',
//       job_profile: '',
//       from_year: '',
//       to_year: '',
//       from_month: '',
//       to_month: '',
//       ongoing: false
//     }
//   ])
//   const [selectedFiles, setSelectedFiles] = useState([])
//   const navigate = useNavigate()
//   const tokenKey = Cookies.get('tokenClient') // Assuming you're using cookies for the token

//   const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false)

//   const handleFirstButtonMouseEnter = () => {
//     setHoverFirstButtonColor(true)
//   }

//   const handleFirstButtonMouseLeave = () => {
//     setHoverFirstButtonColor(false)
//   }

//   const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false)

//   const handleSecondButtonMouseEnter = () => {
//     setHoverSecondButtonColor(true)
//   }

//   const handleSecondButtonMouseLeave = () => {
//     setHoverSecondButtonColor(false)
//   }

//   const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false)

//   const handleThirdButtonMouseEnter = () => {
//     setHoverThirdButtonColor(true)
//   }

//   const handleThirdButtonMouseLeave = () => {
//     setHoverThirdButtonColor(false)
//   }

//   const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

//   const handleFourthButtonMouseEnter = () => {
//     setHoverFourthButtonColor(true)
//   }
//   const [fileName, setFileName] = useState('')

//   const handleFourthButtonMouseLeave = () => {
//     setHoverFourthButtonColor(false)
//   }

//   const getData = async () => {
//     try {
//       setLoading(true)
//       const response = await axios.post(
//         BaseApi + '/candidates/editExperience',
//         null, // Pass null as the request body if not required
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             key: ApiKey,
//             token: tokenKey
//           }
//         }
//       )

//       if (response.data.status === 200) {
//         // Check if experience details are available
//         if (response.data.response.expDetails.length === 0) {
//           // Set default empty fields if no experience details are returned
//           setExperience([
//             {
//               experience_certificate: '',
//               job_position: '',
//               company_name: '',
//               location: '',
//               job_profile: '',
//               from_year: '',
//               to_year: '',
//               from_month: '',
//               to_month: '',
//               ongoing: false
//             }
//           ])
//         } else {
//           // Set the experience details from the response
//           setExperience(response.data.response.expDetails)
//         }

//         // Set other state variables from the response
//         setYearsList(response.data.response.yearList)
//         setSelected_value(response.data.response.expDetails.selected_value)
//         setOptions(response.data.response.options)

//         console.log(response.data.response.expDetails)
//         setLoading(false)
//         // setEditProfile(response.data.response);
//       } else if (response.data.status === 400) {
//         // Handle token expiration or other issues
//         Cookies.remove('tokenClient')
//         Cookies.remove('user_type')
//         Cookies.remove('fname')
//         navigate('/')
//         Swal.fire({
//           title: response.data.message,
//           icon: 'warning',
//           confirmButtonText: t('searchJobPage.close')
//         })
//       } else {
//         // Handle other errors
//         Swal.fire({
//           title: response.data.message,
//           icon: 'error',
//           confirmButtonText: t('searchJobPage.close')
//         })
//       }
//     } catch (error) {
//       setLoading(false)
//       if (error.message === 'Network Error') {
//         // Handle network error
//         Cookies.remove('tokenClient')
//         Cookies.remove('user_type')
//         Cookies.remove('fname')
//         navigate('/')
//         Swal.fire({
//           title: t('tokenExpired.tokenExpired'),
//           icon: 'warning',
//           confirmButtonText: t('jobDescription.close')
//         })
//         setTimeout(() => {
//           window.location.reload()
//         }, 3000)
//       }
//       console.log('Cannot get data of edit profile page')
//     }
//   }

//   const getFileIcon = fileName => {
//     const ext = fileName.split('.').pop().toLowerCase()
//     const icons = {
//       pdf: 'fa-file-pdf',
//       doc: 'fa-file-word',
//       docx: 'fa-file-word',
//       txt: 'fa-file-alt',
//       xls: 'fa-file-excel',
//       xlsx: 'fa-file-excel',
//       csv: 'fa-file-csv',
//       jpg: 'fa-file-image',
//       jpeg: 'fa-file-image'
//     }
//     return icons[ext] || 'fa-file' // Default to a generic file icon
//   }

//   const handleFileChange = (e, index) => {
//     const files = Array.from(e.target.files) // Convert FileList to an array

//     if (files.length > 0) {
//       console.log(`Files selected for index ${index}:`, files)

//       // Get the current selected files for this index
//       const currentFiles = selectedFiles[index] || []

//       // Check if more than 5 files are selected
//       if (currentFiles.length + files.length > 5) {
//         Swal.fire({
//           icon: 'error',
//           title: 'Maximum files limit reached',
//           text: 'You can upload a maximum of 5 files.'
//         })
//         return
//       }

//       // Check for duplicate files by comparing file names
//       const newFiles = files.filter(
//         file =>
//           !currentFiles.some(existingFile => existingFile.name === file.name)
//       )

//       // If there are duplicate files, show an error
//       if (newFiles.length !== files.length) {
//         Swal.fire({
//           icon: 'error',
//           title: 'Duplicate files',
//           text: 'Duplicate files are not allowed.'
//         })
//         return
//       }

//       // Update the `selectedFiles` state for the specific index
//       setSelectedFiles(prevFiles => {
//         const updatedFiles = { ...prevFiles } // Create a shallow copy of the object
//         updatedFiles[index] = [...currentFiles, ...newFiles] // Append new files to the existing ones
//         return updatedFiles
//       })

//       // Update the `experience` state for the specific index
//       setExperience(prevExperience => {
//         const updatedExperience = [...prevExperience] // Create a shallow copy of the array
//         updatedExperience[index] = {
//           ...updatedExperience[index], // Create a shallow copy of the specific object
//           experience_certificate: [
//             ...(updatedExperience[index]?.experience_certificate || []), // Keep existing certificates
//             ...newFiles // Append new files
//           ]
//         }
//         return updatedExperience // Return the updated array
//       })
//     } else {
//       console.warn(`No files selected for index ${index}.`)
//     }
//   }

//   const handleRemoveFile = (index, fileIndex) => {
//     setSelectedFiles(prevFiles => {
//       const updatedFiles = { ...prevFiles } // Create a shallow copy of the object
//       updatedFiles[index] = updatedFiles[index].filter(
//         (_, idx) => idx !== fileIndex
//       ) // Remove the file by index
//       return updatedFiles
//     })

//     setExperience(prevEducation => {
//       const updatedEducation = [...prevEducation] // Create a shallow copy of the array
//       updatedEducation[index] = {
//         ...updatedEducation[index], // Create a shallow copy of the specific object
//         experience_certificate: updatedEducation[
//           index
//         ].experience_certificate.filter(
//           (_, idx) => idx !== fileIndex // Remove the file by index
//         )
//       }
//       return updatedEducation // Return the updated array
//     })
//   }

//   const handleRemove = async id => {
//     console.log('id', id)
//     try {
//       const confirmationResult = await Swal.fire({
//         title: t('jobseekerEducation.removeConfirmTitle'),
//         text: t('jobseekerEducation.removeConfirmTxt'),
//         icon: 'question',
//         showCancelButton: true,
//         confirmButtonText: t('jobseekerEducation.yes'),
//         cancelButtonText: t('jobseekerEducation.no')
//       })
//       if (confirmationResult.isConfirmed) {
//         const response = await axios.post(
//           BaseApi + `/candidates/deleteeducation/${id}`,
//           null,
//           {
//             headers: {
//               'Content-Type': 'application/json',
//               key: ApiKey,
//               token: tokenKey
//             }
//           }
//         )
//         if (response.data.status === 200) {
//           getData()
//           Swal.fire({
//             title: t('jobseekerEducation.removeSuccessTitle'),
//             icon: 'success',
//             confirmButtonText: t('jobseekerEducation.close')
//           })
//         } else if (response.data.status === 400) {
//           Cookies.remove('tokenClient')
//           Cookies.remove('user_type')
//           Cookies.remove('fname')
//           navigate('/')
//           Swal.fire({
//             title: response.data.message,
//             icon: 'warning',
//             confirmButtonText: t('jobseekerEducation.close')
//           })
//         } else {
//           Swal.fire({
//             title: response.data.message,
//             icon: 'error',
//             confirmButtonText: t('jobseekerEducation.close')
//           })
//         }
//       }
//     } catch (error) {
//       setLoading(false)
//       if (error.message === 'Network Error') {
//         Cookies.remove('tokenClient')
//         Cookies.remove('user_type')
//         Cookies.remove('fname')
//         navigate('/')
//         Swal.fire({
//           title: t('tokenExpired.tokenExpired'),
//           icon: 'warning',
//           confirmButtonText: t('jobDescription.close')
//         })
//         setTimeout(function () {
//           window.location.reload()
//         }, 3000)
//       }
//       Swal.fire({
//         title: t('jobseekerEducation.removeFailedTitle'),
//         text: t('jobseekerEducation.removeFailedTxt'),
//         icon: 'error',
//         confirmButtonText: t('jobseekerEducation.close')
//       })
//     }
//   }
//   const handleChange = (e, index, mainID) => {
//     const { name, value, type, checked } = e.target

//     // For checkboxes, we need to use `checked` instead of `value`
//     const fieldValue = type === 'checkbox' ? checked : value

//     setExperience(prevInternships => {
//       const updatedDetails = [...prevInternships] // Create a shallow copy of the array
//       updatedDetails[index] = {
//         ...updatedDetails[index], // Create a shallow copy of the specific internship object
//         [name]: fieldValue // Update the specific field with the new value or checked state
//       }
//       return updatedDetails // Return the updated array
//     })
//     setValidationErrors(prevErrors => {
//       const updatedErrors = [...prevErrors]
//       if (updatedErrors[index]) {
//         updatedErrors[index] = {
//           ...updatedErrors[index],
//           [name]: '' // Clear the validation error for the specific field
//         }
//       }
//       return updatedErrors
//     })
//   }

//   const handleRemoveWithoutId = indexToRemove => {
//     console.log('with vedanta', indexToRemove)
//     // Remove internship entry without ID (local state update)
//     setExperience(prevInternshipDetails =>
//       prevInternshipDetails.filter((_, index) => index !== indexToRemove)
//     )
//   }

//   return (
//     <div
//     // style={{
//     //   marginBottom: '20px',
//     //   border: '1px solid #ccc',
//     //   padding: '10px'
//     // }}
//     >
//       {/* <h4>{jobType.charAt(0).toUpperCase() + jobType.slice(1)} Details</h4> */}

//       {experience.map((j, index) => (
//         <div key={index}>
//           <h4 className='mt-5 mb-5'>
//             {jobType.charAt(0).toUpperCase() + jobType.slice(1)} {index + 1}:
//           </h4>
//           <div className='form-outline mb-5 DashBoardInputBx'>
//             <label className='form-label' htmlFor='form3Example1'>
//               Job Position
//               <span className='RedStar'>*</span>
//             </label>

//             <input
//               type='text'
//               id='form3Example1'
//               className={`form-control ${
//                 validationErrors[index]?.job_position && 'input-error'
//               }`}
//               placeholder='Job Position'
//               name='job_position'
//               value={j.job_position || ''}
//               onChange={e => handleChange(e, index, j.id)}
//             />

//             {validationErrors[index]?.job_position && (
//               <div className='text-danger'>
//                 {validationErrors[index].job_position}
//               </div>
//             )}
//           </div>

//           <div className='form-outline mb-5 DashBoardInputBx'>
//             <label className='form-label' htmlFor='form3Example3'>
//               Employer (company Name) <span className='RedStar'>*</span>
//             </label>
//             <input
//               type='text'
//               id='form3Example3'
//               className={`form-control ${
//                 validationErrors[index]?.company_name && 'input-error'
//               }`}
//               placeholder='Employer (company Name)'
//               name='company_name'
//               value={j.company_name}
//               onChange={e => handleChange(e, index, j.id)}
//             />

//             {validationErrors[index]?.company_name && (
//               <div className='text-danger'>
//                 {validationErrors[index].company_name}
//               </div>
//             )}
//           </div>

//           <div className='form-outline mb-5 DashBoardInputBx'>
//             <label className='form-label' htmlFor='form3Example3'>
//               Location
//               <span className='RedStar'>*</span>
//             </label>
//             <input
//               type='text'
//               id='form3Example3'
//               className={`form-control ${
//                 validationErrors[index]?.location && 'input-error'
//               }`}
//               placeholder='Location'
//               name='location'
//               value={j.location}
//               onChange={e => handleChange(e, index, j.id)}
//             />

//             {validationErrors[index]?.location && (
//               <div className='text-danger'>
//                 {validationErrors[index].location}
//               </div>
//             )}
//           </div>
//           <div className='form-outline mb-5 DashBoardInputBx'>
//             <h5>Duration</h5>
//           </div>

//           <div className='form-outline mb-5 DashBoardInputBx d-flex gap-4'>
//             <label className='form-label' htmlFor='startEmployment'>
//               Start of Employment
//               <span className='RedStar'>*</span>
//             </label>

//             <div className='d-flex gap-3 form-control'>
//               <div className='w-50'>
//                 <select
//                   name='from_month'
//                   id='startMonth'
//                   className={`form-control form-select ${
//                     validationErrors[index]?.from_month && 'input-error'
//                   }`}
//                   value={j.from_month}
//                   onChange={e => handleChange(e, index, j.id)}
//                 >
//                   <option value=''>Select Month</option>
//                   <option value='01'>January</option>
//                   <option value='02'>February</option>
//                   <option value='03'>March</option>
//                   <option value='04'>April</option>
//                   <option value='05'>May</option>
//                   <option value='06'>June</option>
//                   <option value='07'>July</option>
//                   <option value='08'>August</option>
//                   <option value='09'>September</option>
//                   <option value='10'>October</option>
//                   <option value='11'>November</option>
//                   <option value='12'>December</option>
//                 </select>
//                 {validationErrors[index]?.from_month && (
//                   <div className='text-danger small'>
//                     {validationErrors[index].from_month}
//                   </div>
//                 )}
//               </div>

//               <div className='w-50'>
//                 <select
//                   name='from_year'
//                   id='startYear'
//                   className={`form-control form-select ${
//                     validationErrors[index]?.from_year && 'input-error'
//                   }`}
//                   value={j.from_year}
//                   onChange={e => handleChange(e, index, j.id)}
//                 >
//                   <option selected value=''>
//                     {t('jobseekerExperience.selectYear')}
//                   </option>
//                   {Object.entries(yearsList).map(([index, value]) => {
//                     return <option value={value}>{value}</option>
//                   })}
//                 </select>
//                 {validationErrors[index]?.from_year && (
//                   <div className='text-danger small'>
//                     {validationErrors[index].from_year}
//                   </div>
//                 )}
//               </div>
//             </div>

//             <div className='d-flex gap-3  form-control'>
//               {j.ongoing ? (
//                 <div className='w-100 d-flex align-items-center'>
//                   <span className='fw-bold'>Present</span>
//                 </div>
//               ) : (
//                 <>
//                   <div className='w-50'>
//                     <label className='form-emplyoer w-25'>
//                       End of Employment
//                       <span className='RedStar'>*</span>
//                     </label>
//                     <select
//                       name='to_month'
//                       className={`form-control form-select ${
//                         validationErrors[index]?.to_month && 'input-error'
//                       }`}
//                       value={j.to_month}
//                       onChange={e => handleChange(e, index, j.id)}
//                     >
//                       <option value=''>Select Month</option>
//                       <option value='01'>January</option>
//                       <option value='02'>February</option>
//                       <option value='03'>March</option>
//                       <option value='04'>April</option>
//                       <option value='05'>May</option>
//                       <option value='06'>June</option>
//                       <option value='07'>July</option>
//                       <option value='08'>August</option>
//                       <option value='09'>September</option>
//                       <option value='10'>October</option>
//                       <option value='11'>November</option>
//                       <option value='12'>December</option>
//                     </select>
//                     {validationErrors[index]?.to_month && (
//                       <div className='text-danger small'>
//                         {validationErrors[index].to_month}
//                       </div>
//                     )}
//                   </div>

//                   <div className='w-50'>
//                     <select
//                       name='to_year'
//                       className={`form-control form-select ${
//                         validationErrors[index]?.to_year && 'input-error'
//                       }`}
//                       value={j.to_year}
//                       onChange={e => handleChange(e, index, j.id)}
//                     >
//                       <option selected value=''>
//                         {t('jobseekerExperience.selectYear')}
//                       </option>
//                       {Object.entries(yearsList).map(([index, value]) => {
//                         return <option value={value}>{value}</option>
//                       })}
//                     </select>
//                     {validationErrors[index]?.to_year && (
//                       <div className='text-danger small'>
//                         {validationErrors[index].to_year}
//                       </div>
//                     )}
//                   </div>
//                 </>
//               )}
//             </div>
//           </div>

//           <div className='form-outline mb-5 DashBoardInputBx ongoing-container'>
//             <input
//               type='checkbox'
//               id='ongoing'
//               name='ongoing'
//               checked={j.ongoing}
//               onChange={e => handleChange(e, index, j.id)}
//               className='form-check-input'
//             />
//             <label htmlFor='ongoing' className='ongoing-label'>
//               Ongoing<span className='RedStar'>*</span>
//             </label>
//           </div>

//           <div className='form-outline mb-5 DashBoardInputBx'>
//             <label className='form-label' htmlFor='form3Example3'>
//               Job Profile
//               <span className='RedStar'>*</span>
//             </label>
//             <input
//               type='text'
//               id='form3Example3'
//               className={`form-control ${
//                 validationErrors[index]?.job_profile && 'input-error'
//               }`}
//               placeholder='Job Profile'
//               name='job_profile'
//               value={j.job_profile}
//               onChange={e => handleChange(e, index, j.id)}
//             />

//             {validationErrors[index]?.job_profile && (
//               <div className='text-danger'>
//                 {validationErrors[index].job_profile}
//               </div>
//             )}
//           </div>

//           <div className='file-upload-container'>
//             <div className='upload-box'>
//               <input
//                 type='file'
//                 name='experience_certificate'
//                 id={`file-input-${index}`}
//                 accept='.pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg'
//                 multiple
//                 onChange={e => handleFileChange(e, index)}
//                 style={{ display: 'none' }}
//               />
//               <div
//                 className='upload-method'
//                 onClick={() =>
//                   document.getElementById(`file-input-${index}`).click()
//                 }
//               >
//                 <p>
//                   <span role='img' aria-label='attachment'>
//                     📎
//                   </span>{' '}
//                   <span className='upload-text'>Add files</span> or drag and
//                   drop files here
//                 </p>
//                 <p className='upload-info'>
//                   Supported formats: .pdf, .doc, .docx, .txt, .xls, .csv, .jpg,
//                   .jpeg
//                 </p>
//               </div>

//               {selectedFiles[index] && selectedFiles[index].length > 0 && (
//                 <div className='file-preview'>
//                   <strong>Selected Files:</strong>
//                   <ul>
//                     {selectedFiles[index].map((file, fileIndex) => (
//                       <li key={fileIndex} className='file-item'>
//                         <i
//                           className={`fas ${getFileIcon(file.name)} file-icon`}
//                         ></i>
//                         <span className='file-name'>
//                           {file.name.length > 30
//                             ? `${file.name.slice(0, 30)}...`
//                             : file.name}
//                         </span>
//                         <button
//                           type='button'
//                           onClick={() => handleRemoveFile(index, fileIndex)}
//                           className='remove-btn'
//                         >
//                           &times;
//                         </button>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}

//               {selectedFiles[index] && selectedFiles[index].length > 5 && (
//                 <div className='text-danger small'>
//                   You can upload a maximum of 5 files.
//                 </div>
//               )}

//               {selectedFiles[index] &&
//                 selectedFiles[index].some(
//                   (file, idx, arr) =>
//                     arr.findIndex(f => f.name === file.name) !== idx
//                 ) && (
//                   <div className='text-danger small'>
//                     Duplicate files are not allowed.
//                   </div>
//                 )}
//             </div>
//           </div>

//           <div className='removeButtonJobseeker mt-4'>
//             {j.id ? (
//               <>
//                 <button
//                   type='button'
//                   className='btn btn-primary button2'
//                   onClick={() => handleRemove(j.id)}
//                   style={{
//                     color: hoverFourthButtonColor
//                       ? primaryColor
//                       : secondaryColor,
//                     backgroundColor: 'white',
//                     border: hoverFourthButtonColor
//                       ? `2px solid ${primaryColor}`
//                       : `2px solid ${secondaryColor}`
//                   }}
//                   onMouseEnter={handleFourthButtonMouseEnter}
//                   onMouseLeave={handleFourthButtonMouseLeave}
//                 >
//                   {t('jobseekerEducation.removeButton')}
//                 </button>
//               </>
//             ) : (
//               <>
//                 <button
//                   type='button'
//                   className='btn btn-primary button2'
//                   onClick={() => handleRemoveWithoutId(index)}
//                   style={{
//                     color: hoverFourthButtonColor
//                       ? primaryColor
//                       : secondaryColor,
//                     backgroundColor: 'white',
//                     border: hoverFourthButtonColor
//                       ? `2px solid ${primaryColor}`
//                       : `2px solid ${secondaryColor}`
//                   }}
//                   onMouseEnter={handleFourthButtonMouseEnter}
//                   onMouseLeave={handleFourthButtonMouseLeave}
//                 >
//                   {t('jobseekerEducation.removeButton')}
//                 </button>
//               </>
//             )}
//           </div>
//         </div>
//       ))}

//       {/* <label>Job Position:</label>
//       <input type="text" placeholder="Job Position" />
//       <br />
//       <label>Job Profile:</label>
//       <input type="text" placeholder="Job Profile" />
//       <br />
//       <label>Start Month:</label>
//       <input type="text" placeholder="Start Month" />
//       <br />
//       <label>Start Year:</label>
//       <input type="text" placeholder="Start Year" />
//       <br />
//       <label>End Month:</label>
//       <input type="text" placeholder="End Month" />
//       <br />
//       <label>End Year:</label>
//       <input type="text" placeholder="End Year" />
//       <br />
//       <label>Location:</label>
//       <input type="text" placeholder="Location" />
//       <br />
//       <label>Company Name:</label>
//       <input type="text" placeholder="Company Name" />
//       <br />
//       <button onClick={onRemove} className="btn btn-danger">
//         Remove
//       </button> */}
//     </div>
//   )
// }

// const JobTypeSelector = ({ jobType, onJobTypeChange, index, options }) => {
//   return (
//     <div style={{ marginBottom: '10px', marginTop: '10px' }}>
//       {/* <label>Select Job Type:</label> */}
//       <select
//         name='jobType'
//         value={jobType}
//         onChange={e => onJobTypeChange(e, index)}
//         className='form-select'
//       >
//         <option value=''>Select...</option>

//         {Object.entries(options).map(([index, value]) => {
//           return <option value={value}>{value}</option>
//         })}
//       </select>
//     </div>
//   )
// }

// const ExperienceDetails = () => {
//   let primaryColor = Cookies.get('primaryColor')
//   let secondaryColor = Cookies.get('secondaryColor')
//   const [validationErrors, setValidationErrors] = useState([])
//   const [selected_value, setSelected_value] = useState('')
//   const [jobForms, setJobForms] = useState([])
//   const [jobTypeSelections, setJobTypeSelections] = useState([{ jobType: '' }])
//   const [experience, setExperience] = useState([
//     {
//       experience_certificate: '',
//       job_position: '',
//       company_name: '',
//       location: '',
//       job_profile: '',
//       from_year: '',
//       to_year: '',
//       from_month: '',
//       to_month: '',

//       ongoing: false
//     }
//   ])
//   const [educationDetails, setEducationDetails] = useState([])
//   const [options, setOptions] = useState([])
//   const [loading, setLoading] = useState(false)
//   const { t } = useTranslation('global')
//   const navigate = useNavigate()
//   const tokenKey = Cookies.get('tokenClient')

//   const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false)

//   const handleFirstButtonMouseEnter = () => {
//     setHoverFirstButtonColor(true)
//   }

//   const handleFirstButtonMouseLeave = () => {
//     setHoverFirstButtonColor(false)
//   }

//   const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false)

//   const handleSecondButtonMouseEnter = () => {
//     setHoverSecondButtonColor(true)
//   }

//   const handleSecondButtonMouseLeave = () => {
//     setHoverSecondButtonColor(false)
//   }

//   const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false)

//   const handleThirdButtonMouseEnter = () => {
//     setHoverThirdButtonColor(true)
//   }

//   const handleThirdButtonMouseLeave = () => {
//     setHoverThirdButtonColor(false)
//   }

//   const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

//   const handleFourthButtonMouseEnter = () => {
//     setHoverFourthButtonColor(true)
//   }
//   const [fileName, setFileName] = useState('')

//   const handleFourthButtonMouseLeave = () => {
//     setHoverFourthButtonColor(false)
//   }

//   const getData = async () => {
//     try {
//       setLoading(true)
//       const response = await axios.post(
//         `${BaseApi}/candidates/editExperience`,
//         null,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             key: ApiKey,
//             token: tokenKey
//           }
//         }
//       )

//       if (response.data.status === 200) {
//         setOptions(response.data.response.options || [])
//         setLoading(false)
//       } else if (response.data.status === 400) {
//         handleSessionExpiration(response.data.message)
//       } else {
//         showError(response.data.message)
//       }
//     } catch (error) {
//       setLoading(false)
//       handleError(error)
//     }
//   }

//   const handleSessionExpiration = message => {
//     Cookies.remove('tokenClient')
//     Cookies.remove('user_type')
//     Cookies.remove('fname')
//     navigate('/')
//     Swal.fire({
//       title: message,
//       icon: 'warning',
//       confirmButtonText: t('searchJobPage.close')
//     })
//   }

//   const showError = message => {
//     Swal.fire({
//       title: message,
//       icon: 'error',
//       confirmButtonText: t('searchJobPage.close')
//     })
//   }

//   const handleError = error => {
//     if (error.message === 'Network Error') {
//       handleSessionExpiration(t('tokenExpired.tokenExpired'))
//     } else {
//       console.error('Error fetching data:', error)
//     }
//   }

//   const validateFields = () => {
//     // Ensure experience is an array
//     if (!Array.isArray(experience)) {
//       setValidationErrors([{ error: 'Experience data is invalid' }])
//       return false
//     }

//     const errors = experience.map((exp, index) => ({
//       from_month:
//         exp?.from_month?.trim() === '' ? 'Start month is required' : '',
//       to_month: exp?.to_month?.trim() === '' ? 'End Month is required' : '',
//       // from_year: exp?.from_year?.trim() === '' ? 'Start year is required' : '',

//       from_year:
//         exp?.from_year &&
//         typeof exp?.from_year === 'string' &&
//         exp?.from_year.trim() === ''
//           ? 'Start year is required'
//           : '',

//       // to_year: exp?.to_year?.trim() === '' ? 'End year is required' : '',
//       to_year:
//         exp?.to_year &&
//         typeof exp?.to_year === 'string' &&
//         exp?.to_year.trim() === ''
//           ? 'End year is required'
//           : '',

//       job_position:
//         exp?.job_position?.trim() === '' ? 'Job position is required' : '',
//       company_name:
//         exp?.company_name?.trim() === '' ? 'Company name is required' : '',
//       location: exp?.location?.trim() === '' ? 'Location is required' : '',
//       job_profile:
//         exp?.job_profile?.trim() === '' ? 'Job profile is required' : '',
//       ongoing: exp?.ongoing === false ? 'Ongoing status is required' : ''
//     }))

//     setValidationErrors(errors) // Set the validation errors in the state
//     console.log(errors)

//     const isValid = errors.every(
//       error =>
//         !error.job_position &&
//         !error.company_name &&
//         !error.location &&
//         !error.job_profile &&
//         !error.from_year &&
//         !error.from_month &&
//         !error.to_month &&
//         !error.to_year &&
//         !error.ongoing
//       // !error.experience_certificate
//     )

//     return isValid
//   }

//   const handleClick = async () => {
//     const updatedExperience = {
//       ...experience,
//       selected_value: selected_value,
//       experience_certificate: experience.experience_certificate
//     }
//     if (!validateFields()) {
//       window.scrollTo(0, 0) // Scroll to the top to see validation errors
//       return
//     }

//     try {
//       const confirmationResult = await Swal.fire({
//         title: 'Confirm Experience Details',
//         text: 'Are you sure you want to save these details?',
//         icon: 'question',
//         showCancelButton: true,
//         confirmButtonText: 'Yes',
//         cancelButtonText: 'No'
//       })

//       if (confirmationResult.isConfirmed) {
//         setLoading(true)

//         const response = await axios.post(
//           BaseApi + '/candidates/editExperience',
//           { Experience: updatedExperience }, // Send education details as payload
//           {
//             headers: {
//               'Content-Type': 'multipart/form-data',
//               key: ApiKey,
//               token: tokenKey
//             }
//           }
//         )

//         setLoading(false)

//         if (response.data.status === 200) {
//           Swal.fire({
//             title: 'Success!',
//             text: 'Experience details updated successfully.',
//             icon: 'success',
//             confirmButtonText: 'Close'
//           })
//           setEducationDetails([]) // Reset education details
//           // Optional: Reload the page or call a function to update UI
//           navigate('/candidates/internships')
//         } else if (response.data.status === 400) {
//           Cookies.remove('tokenClient')
//           Cookies.remove('user_type')
//           Cookies.remove('fname')
//           navigate('/candidates/parttime') // Redirect if token expired or other issue
//           Swal.fire({
//             title: response.data.message,
//             icon: 'warning',
//             confirmButtonText: 'Close'
//           })
//         } else {
//           Swal.fire({
//             title: response.data.message,
//             icon: 'error',
//             confirmButtonText: 'Close'
//           })
//         }
//       }
//     } catch (error) {
//       setLoading(false)
//       // if (error.message === "Network Error") {
//       //   Cookies.remove("tokenClient");
//       //   Cookies.remove("user_type");
//       //   Cookies.remove("fname");
//       //   navigate("/candidates/internships");
//       //   Swal.fire({
//       //     title: "Token Expired",
//       //     icon: "warning",
//       //     confirmButtonText: "Close",
//       //   });
//       //   setTimeout(function () {
//       //     window.location.reload(); // Reload page if the token is expired
//       //   }, 3000);
//       // }

//       Swal.fire({
//         title: 'Failed to update Experience Details',
//         text: 'There was an error updating your experience details. Please try again.',
//         icon: 'error',
//         confirmButtonText: 'Close'
//       })
//     }
//   }

//   const handleAddDropdown = () => {
//     setJobTypeSelections([...jobTypeSelections, { jobType: '' }])
//   }

//   const handleJobTypeChange = (e, index) => {
//     console.log(e.target.value)
//     const newJobTypeSelections = [...jobTypeSelections]
//     newJobTypeSelections[index].jobType = e.target.value
//     setJobTypeSelections(newJobTypeSelections)
//   }

//   const handleRemoveJobForm = index => {
//     const newJobTypeSelections = [...jobTypeSelections]
//     newJobTypeSelections.splice(index, 1)
//     setJobTypeSelections(newJobTypeSelections)
//   }

//   useEffect(() => {
//     getData()
//   }, [])

//   return (
//     <>
//       <NavBar />
//       {loading ? (
//         <div className='loader-container'></div>
//       ) : (
//         <>
//           <div className='container editProfile'>
//             <div className='row'>
//               <div className='col-lg-3'>
//                 <JSSidebar />
//               </div>
//               <div
//                 className='col-lg-9 mb-5'
//                 style={{
//                   borderLeft: '2px solid #e6e8e7',
//                   borderRight: '2px solid #e6e8e7'
//                 }}
//               >
//                 <div className='mx-3 d-flex PageHeader'>
//                   <h3>Professional Experience</h3>
//                 </div>
//                 <p className='mx-3'>
//                   Begin with your most recent or current position and work
//                   backward.
//                   <br />
//                   (If you have a lot of experience, just provide your most
//                   recent positions)
//                 </p>

//                 {jobTypeSelections.map((jobSelection, index) => (
//                   <div key={index}>
//                     <JobTypeSelector
//                       jobType={jobSelection.jobType}
//                       onJobTypeChange={handleJobTypeChange}
//                       index={index}
//                       options={options}
//                     />
//                     {jobSelection.jobType && (
//                       <JobForm
//                         jobType={jobSelection.jobType}
//                         // onRemove={() => handleRemoveJobForm(index)}
//                       />
//                     )}
//                   </div>
//                 ))}

//                 <div className='EduAddMore mb-4'>
//                   <button
//                     type='button'
//                     className='btn btn-primary button1'
//                     onClick={handleAddDropdown}
//                     style={{
//                       backgroundColor: primaryColor,
//                       color: 'white',
//                       border: primaryColor
//                     }}
//                   >
//                     {t('jobseekerEducation.addMoreButton')}
//                   </button>
//                 </div>

//                 {/* <div className='JSEPFinalButton d-flex justify-content-between mt-4'>
//               <Link to='/candidates/create-resume' className='btn btn-primary'>
//                 Back
//               </Link>
//               <button type='button' className='btn btn-primary'>
//                 Next
//               </button>
//             </div> */}
//                 <div className='JSEPFinalButton d-flex justify-content-between'>
//                   <Link
//                     to={`/candidates/create-resume`}
//                     type='button'
//                     // onClick={handleClick}
//                     className='btn btn-primary button1'
//                     style={{
//                       backgroundColor: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor,
//                       border: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor
//                     }}
//                     onMouseEnter={handleFirstButtonMouseEnter}
//                     onMouseLeave={handleFirstButtonMouseLeave}
//                   >
//                     Back
//                   </Link>
//                   <button
//                     // to={`/candidates/professions/experience`}
//                     type='button'
//                     onClick={handleClick}
//                     className='btn btn-primary button1'
//                     style={{
//                       backgroundColor: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor,
//                       border: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor
//                     }}
//                     onMouseEnter={handleFirstButtonMouseEnter}
//                     onMouseLeave={handleFirstButtonMouseLeave}
//                   >
//                     Next
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <Footer />
//         </>
//       )}
//     </>
//   )
// }

// export default ExperienceDetails

import React, { useEffect, useState } from 'react'
import JSSidebar from './JSSidebar'
import NavBar from '../element/NavBar'
import Footer from '../element/Footer'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import ApiKey from '../api/ApiKey'
import BaseApi from '../api/BaseApi'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

const JobForm = ({
  index,
  jobType,
  initialData,
  onValidate,
  onRemove,
  onDataChange,
  yearsList,
  experience_id
  // getData
}) => {
  const [formData, setFormData] = useState({
    job_position: '',
    company_name: '',
    location: '',
    job_profile: '',
    from_year: '',
    to_year: '',
    from_month: '',
    to_month: '',
    // ongoing: false,
    experience_certificate: ''
  })

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const tokenKey = Cookies.get('tokenClient')
  // const [yearsList, setYearsList] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [formErrors, setFormErrors] = useState({})
  const { t } = useTranslation('global')

  let primaryColor = Cookies.get('primaryColor')
  let secondaryColor = Cookies.get('secondaryColor')
  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true)
  }

  useEffect(() => {
    if (initialData) {
      setFormData(prev => ({
        ...prev,
        ...initialData,
        experience_certificate: initialData.experience_certificate || []
      }))
    }
  }, [initialData])

  // Helper function for session expiration handling
  const handleSessionExpiration = message => {
    Cookies.remove('tokenClient')
    Cookies.remove('user_type')
    Cookies.remove('fname')
    navigate('/')
    Swal.fire({
      title: message,
      icon: 'warning',
      confirmButtonText: t('searchJobPage.close')
    })
  }

  const handleChange = e => {
    const { name, value, type, checked } = e.target
    const updatedValue = type === 'checkbox' ? checked : value
    const updatedData = { ...formData, [name]: updatedValue }
    setFormData(updatedData)
    onDataChange(index, updatedData)
  }

  const handleFileChange = (event, index) => {
    const files = Array.from(event.target.files) // Convert FileList to an array
    console.log(files, 'Files uploaded')

    // Ensure selectedFiles[index] is an array
    const currentFiles = Array.isArray(selectedFiles[index])
      ? selectedFiles[index]
      : []

    // Check for file limit
    if (currentFiles.length + files.length > 5) {
      Swal.fire({
        title: 'File Limit Exceeded',
        text: 'You can upload a maximum of 5 files.',
        icon: 'error'
      })
      return
    }

    // Check for duplicates
    const isDuplicate = files.some(newFile =>
      currentFiles.some(existingFile => existingFile.name === newFile.name)
    )

    if (isDuplicate) {
      Swal.fire({
        title: 'Duplicate Files',
        text: 'Some files are duplicates and were not added.',
        icon: 'error'
      })
      return
    }

    // Update selectedFiles state
    setSelectedFiles(prevFiles => {
      const updatedFiles = [...prevFiles]
      updatedFiles[index] = [...(updatedFiles[index] || []), ...files]
      console.log(updatedFiles, 'Updated selected files') // Log updated files here
      return updatedFiles
    })

    // Update formData state
    setFormData(prevData => {
      const updatedData = { ...prevData }

      if (!updatedData[index]) {
        updatedData[index] = { experience_certificate: [] }
      }

      updatedData.experience_certificate = [
        ...(updatedData[index].experience_certificate || []),
        ...files
      ]

      console.log('FormData updated:', updatedData) // Log updated formData here

      onDataChange(index, updatedData)

      return updatedData
    })
  }

  const handleRemoveFile = fileIndex => {
    const updatedFiles = selectedFiles.filter((_, idx) => idx !== fileIndex)
    setSelectedFiles(updatedFiles)
    setFormData(prevData => ({
      ...prevData,
      experience_certificate: updatedFiles
    }))
  }

  // const handleRemoveFileWithName = async file => {
  //   try {
  //     console.log(file, 'File')
  //   } catch (error) {
  //     console.log(error.message)
  //   }
  // }

  const handleRemoveFileWithName = async (file, index) => {
    try {
      // Log to check what education_id is
      console.log('Education ID before parsing:', file)

      // Check if education_id is an array or object and extract the ID correctly
      // const educationId = Array.isArray(education_id)
      //   ? education_id[0]?.id
      //   : parseInt(education_id, 10)

      // console.log('Education ID after parsing:', educationId)

      // Check if educationId is valid
      // if (isNaN(educationId) || !Number.isInteger(educationId)) {
      //   console.error('Invalid education ID:', educationId)
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Invalid Education ID',
      //     text: 'The Education ID is invalid. Please try again.'
      //   })
      //   return
      // }

      // SweetAlert confirmation dialog
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `Are you sure you want to delete the file "${file}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      })

      if (result.isConfirmed) {
        // Proceed with the API call to delete the file
        const response = await axios.post(
          BaseApi + '/candidates/deleteExperienceDocuments',
          {
            file_id: file, // Send as a single integer
            file_name: file // Send the file name to be deleted
          },
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey, // Replace with your actual API key
              token: tokenKey // Replace with the user's token
            }
          }
        )

        // Check the response from the API
        if (response.data.status === 200) {
          // getData()
          setLoading(false)

          // Successfully deleted file, update state or remove file from selectedFiles
          setSelectedFiles(prevFiles => {
            const newFiles = [...prevFiles]
            newFiles.splice(index, 1) // Remove the file from the list
            return newFiles
          })

          // Show success message with SweetAlert
          Swal.fire({
            icon: 'success',
            title: 'File Deleted Successfully',
            text: `The file "${file}" has been deleted.`
          })
          console.log('File deleted successfully')
        } else {
          console.error('Failed to delete file')
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete the file. Please try again.'
          })
        }
      } else {
        // User canceled the deletion
        console.log('File deletion canceled')
      }
    } catch (error) {
      console.error('Error deleting file:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting the file. Please try again.'
      })
    }
  }

  useEffect(() => {
    const errors = {}
    Object.keys(formData).forEach(key => {
      // Check if the key is 'experience_certificate' and validate based on files
      if (key === 'experience_certificate') {
        if (!formData[key] || formData[key].length === 0) {
          errors[key] = 'This field is required' // Add error if no files are uploaded
        }
      }

      // else if (!formData[key]) {
      //   // For other fields, check if they are empty
      //   errors[key] = 'This field is required'
      // }
    })
    setFormData(formData)

    setFormErrors(errors)
    onValidate(errors)
  }, [formData])
  return (
    <div className='job-form'>
      <h4 className='mt-5 mb-5'>
        {jobType.charAt(0).toUpperCase() + jobType.slice(1)} {index + 1}:
      </h4>
      {/* Job Position */}
      <div className='form-outline mb-5 DashBoardInputBx'>
        <label className='form-label'>
          Job Position <span className='RedStar'>*</span>
        </label>
        <input
          type='text'
          className={`form-control ${formErrors.job_position && 'input-error'}`}
          name='job_position'
          value={formData.job_position}
          onChange={handleChange}
        />
        {formErrors.job_position && (
          <p className='text-danger'>{formErrors.job_position}</p>
        )}
      </div>

      <div className='form-outline mb-5 DashBoardInputBx'>
        <label className='form-label'>
          Employer (Company Name) <span className='RedStar'>*</span>
        </label>
        <input
          type='text'
          className={`form-control ${formErrors.company_name && 'input-error'}`}
          name='company_name'
          value={formData.company_name}
          onChange={handleChange}
        />
        {formErrors.company_name && (
          <p className='text-danger'>{formErrors.company_name}</p>
        )}
      </div>

      <div className='form-outline mb-5 DashBoardInputBx'>
        <label className='form-label'>
          Location <span className='RedStar'>*</span>
        </label>
        <input
          type='text'
          className={`form-control ${formErrors.location && 'input-error'}`}
          name='location'
          value={formData.location}
          onChange={handleChange}
        />
        {formErrors.location && (
          <p className='text-danger'>{formErrors.location}</p>
        )}
      </div>

      <div className='form-outline mb-5 DashBoardInputBx'>
        <h5>Duration</h5>
      </div>
      <div className='form-outline mb-5 DashBoardInputBx'>
        <div className='row'>
          <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
            <label className='form-label'>
              Start of Employment <span className='RedStar'>*</span>
            </label>
            <div className=' form-control d-md-flex gap-3'>
              <div className=''>
                <select
                  name='from_month'
                  // id={`startMonth-${index}`}
                  className={`form-control form-select ${
                    formErrors.from_month && 'input-error'
                  }`}
                  value={formData.from_month}
                  onChange={handleChange}
                >
                  <option value=''>Select Month</option>
                  {/* Month options */}
                  {[
                    '01',
                    '02',
                    '03',
                    '04',
                    '05',
                    '06',
                    '07',
                    '08',
                    '09',
                    '10',
                    '11',
                    '12'
                  ].map((month, idx) => (
                    <option key={idx} value={month}>
                      {new Date(0, month - 1).toLocaleString('default', {
                        month: 'long'
                      })}
                    </option>
                  ))}
                </select>
              </div>
              <div className=''>
                <select
                  name='from_year'
                  // id={`startYear-${index}`}
                  className={`form-control form-select ${
                    formErrors.from_year && 'input-error'
                  }`}
                  value={formData.from_year}
                  onChange={handleChange}
                >
                  <option value=''>
                    {t('jobseekerExperience.selectYear')}
                  </option>
                  {/* {Object.entries(yearsList).map(([idx, value]) => (
                    <option key={idx} value={value}>
                      {value}
                    </option>
                  ))} */}
                  {yearsList.map((year, idx) => (
                    <option key={idx} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
            <div className='d-flex gap-3 form-control'>
              {formData.ongoing ? (
                <div className='w-100 d-flex align-items-center'>
                  <span className='fw-bold'>Present</span>
                </div>
              ) : (
                <>
                  <div className='w-50'>
                    <label className='form-label'>
                      End of Employment
                      <span className='RedStar'>*</span>
                    </label>
                    <select
                      name='to_month'
                      className={`form-control form-select ${
                        formErrors.to_month && 'input-error'
                      }`}
                      value={formData.to_month}
                      onChange={handleChange}
                    >
                      <option value=''>Select Month</option>
                      {/* Month options */}
                      {[
                        '01',
                        '02',
                        '03',
                        '04',
                        '05',
                        '06',
                        '07',
                        '08',
                        '09',
                        '10',
                        '11',
                        '12'
                      ].map((month, idx) => (
                        <option key={idx} value={month}>
                          {new Date(0, month - 1).toLocaleString('default', {
                            month: 'long'
                          })}
                        </option>
                      ))}
                    </select>
                    {formErrors.to_month && (
                      <div className='text-danger small'>
                        {formErrors.to_month}
                      </div>
                    )}
                  </div>

                  <div className='w-50'>
                    <select
                      name='to_year'
                      className={`form-control form-select ${
                        formErrors.to_year && 'input-error'
                      }`}
                      value={formData.to_year}
                      onChange={handleChange}
                    >
                      <option value=''>
                        {t('jobseekerExperience.selectYear')}
                      </option>
                      {/* {Object.entries(yearsList).map(([idx, value]) => (
                        <option key={idx} value={value}>
                          {value}
                        </option>
                      ))} */}
                      {yearsList.map((year, idx) => (
                        <option key={idx} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    {formErrors.to_year && (
                      <div className='text-danger small'>
                        {formErrors.to_year}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='form-outline mb-5 DashBoardInputBx ongoing-container'>
        <input
          type='checkbox'
          name='ongoing'
          checked={formData.ongoing}
          onChange={handleChange}
          className='form-check-input'
        />
        <label htmlFor={`ongoing-${index}`} className='ongoing-label'>
          Ongoing<span className='RedStar'>*</span>
        </label>
      </div>

      <div className='form-outline mb-5 DashBoardInputBx'>
        <label className='form-label'>
          Job Profile <span className='RedStar'>*</span>
        </label>
        <input
          type='text'
          className={`form-control ${formErrors.job_profile && 'input-error'}`}
          name='job_profile'
          value={formData.job_profile}
          onChange={handleChange}
        />
        {formErrors.job_profile && (
          <p className='text-danger'>{formErrors.job_profile}</p>
        )}
      </div>

      <div className='file-upload-container'>
        <div className='upload-box'>
          <input
            type='file'
            name='experience_certificate'
            id={`file-input-${index}`}
            accept='.pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg'
            multiple
            onChange={e => handleFileChange(e, index)}
            style={{ display: 'none' }}
          />
          <div
            className='upload-method'
            onClick={() =>
              document.getElementById(`file-input-${index}`).click()
            }
          >
            <p>
              <span role='img' aria-label='attachment'>
                📎
              </span>{' '}
              <span className='upload-text'>Add files</span> or drag and drop
              files here
            </p>
            <p className='upload-info'>
              Supported formats: .pdf, .doc, .docx, .txt, .xls, .csv, .jpg,
              .jpeg
            </p>
          </div>

          <ul>
            {Array.isArray(formData.experience_certificate) &&
            formData.experience_certificate.length > 0 ? (
              formData.experience_certificate.map((file, fileIndex) => (
                <li key={fileIndex}>
                  <span className='file-name'>
                    {/* Check if file is an object and has a 'name' property */}
                    {typeof file === 'object' && file.name
                      ? file.name.length > 30
                        ? `${file.name.slice(0, 30)}...`
                        : file.name
                      : file}
                  </span>
                  <button
                    type='button'
                    onClick={() =>
                      handleRemoveFileWithName(
                        file,

                        fileIndex
                      )
                    }
                    className='remove-btn'
                  >
                    &times;
                  </button>
                </li>
              ))
            ) : (
              <p>No experience certificates available</p>
            )}
          </ul>
        </div>
      </div>

      <div className='removeButtonJobseeker mt-4'>
        <button
          type='button'
          onClick={() => onRemove(index)} // Pass the index to remove the specific form
          className='btn btn-primary button2'
          style={{
            color: hoverFourthButtonColor ? primaryColor : secondaryColor,
            backgroundColor: 'white',
            border: hoverFourthButtonColor
              ? `2px solid ${primaryColor}`
              : `2px solid ${secondaryColor}`
          }}
        >
          Remove
        </button>
      </div>
    </div>
  )
}

const JobTypeSelector = ({ jobType, onJobTypeChange, options }) => {
  return (
    <div className='form-group mb-3 mt-3'>
      <select
        value={jobType}
        onChange={onJobTypeChange}
        className='form-control'
      >
        <option value=''>Select Job Type</option>
        {/* {options.map((value, index) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))} */}
        {Object.entries(options).map(([index, value]) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  )
}

const ExperienceDetails = () => {
  const primaryColor = Cookies.get('primaryColor')
  const [selectedFiles, setSelectedFiles] = useState([])
  const secondaryColor = Cookies.get('secondaryColor')
  const [validationErrors, setValidationErrors] = useState([])
  const [jobTypeSelections, setJobTypeSelections] = useState([{ jobType: '' }])
  const [yearsList, setYearsList] = useState([])
  const [formData, setFormData] = useState([{}])
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const tokenKey = Cookies.get('tokenClient')
  const { t } = useTranslation('global')
  const [jobTypeSelectionsError, setJobTypeSelectionsError] = useState([
    { jobTypeError: '' }
  ])

  const getData = async () => {
    try {
      setLoading(true)
      const response = await axios.post(
        `${BaseApi}/candidates/newEditExperience`,
        null,
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey
          }
        }
      )

      if (response.data.status === 200) {
        const { expDetails, yearList, selected_value, options } =
          response.data.response
        setOptions(response.data.response.options || [])

        const certificates = response.data.response.expDetails
          .map(expDetails =>
            expDetails.experience_certificate
              ? expDetails.experience_certificate
              : []
          )
          .flat()
        setSelectedFiles(certificates)
        console.log('Selected Files:', certificates)

        //   setFormData(fetchedData)
        //  console.log(fetchedData)
        setFormData(response.data.response.expDetails)
        // if (Array.isArray(options)) {
        //   setOptions(options)
        //   console.log(options)
        // } else {
        //   console.error('Invalid yearList format', options)
        // }
        setFormData(response.data.response.expDetails || [])
        if (Array.isArray(yearList)) {
          setYearsList(yearList)
          console.log(yearList)
        } else {
          console.error('Invalid yearList format', yearList)
        }

        setJobTypeSelections(
          response.data.response.expDetails.map(detail => ({
            jobType: detail.selected_value
          }))
        )
        setLoading(false)
      } else {
        // Handle errors
        Swal.fire({
          title: response.data.message,
          icon: 'error',
          confirmButtonText: t('searchJobPage.close')
        })
      }
    } catch (error) {
      setLoading(false)
      Swal.fire({
        title: 'An error occurred',
        text: 'There was an issue with the request. Please try again later.',
        icon: 'error',
        confirmButtonText: 'Close'
      })
    }
  }

  const handleClick = async () => {
    const dropdownErrors = jobTypeSelections.map((selection, index) => {
      if (!selection.jobType) {
        return { jobTypeError: 'Please select job type' }
      }
      return { jobTypeError: '' }
    })

    setJobTypeSelectionsError(dropdownErrors)
    console.log('Dropdown Errors:', dropdownErrors)

    const hasDropdownErrors = dropdownErrors.some(
      error => error.jobTypeError !== ''
    )
    const hasFormErrors = validationErrors.some(
      errors => Object.keys(errors || {}).length > 0
    )
    console.log('Validation Errors:', validationErrors)

    console.log(hasDropdownErrors, 'hasDropdownErrors')
    console.log(hasFormErrors, 'hasFormErrors')

    if (hasDropdownErrors || hasFormErrors) {
      Swal.fire({
        title: 'Validation Error',
        text: 'Please fill all the details',
        icon: 'warning',
        confirmButtonText: 'Close'
      })

      console.log('Validation failed: Fix errors before proceeding.')
      return
    }

    try {
      const confirmationResult = await Swal.fire({
        title: 'Confirm Experience Details',
        text: 'Are you sure you want to save these details?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (confirmationResult.isConfirmed) {
        setLoading(true)
        console.log('Payload being sent:', { Experience: formData })

        const response = await axios.post(
          BaseApi + '/candidates/newEditExperience',
          { Experience: formData }, // Send education details as payload
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              key: ApiKey,
              token: tokenKey
            }
          }
        )

        setLoading(false)
        console.log(response.data.status)
        if (response.data.status === 200) {
          getData()
          // console.log(response.data.status)
          Swal.fire({
            title: 'Success!',
            text: 'Experience details updated successfully.',
            icon: 'success',
            confirmButtonText: 'Close'
          })

          navigate('/candidates/educations')
        } else if (response.data.status === 400) {
          Cookies.remove('tokenClient')
          Cookies.remove('user_type')
          Cookies.remove('fname')
          navigate('/candidates/educations') // Redirect if token expired or other issue
          Swal.fire({
            title: response.data.message,
            icon: 'warning',
            confirmButtonText: 'Close'
          })
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: 'Close'
          })
        }
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/candidates/educations')
        Swal.fire({
          title: 'Token Expired',
          icon: 'warning',
          confirmButtonText: 'Close'
        })
        setTimeout(function () {
          window.location.reload() // Reload page if the token is expired
        }, 3000)
      }

      Swal.fire({
        title: 'Failed to update Experience Details',
        text: 'There was an error updating your experience details. Please try again.',
        icon: 'error',
        confirmButtonText: 'Close'
      })
    }
  }

  const handleJobFormDataChange = (index, updatedData) => {
    setFormData(prevData => {
      const newData = [...prevData]
      newData[index] = updatedData
      return newData
    })
  }

  const handleAddDropdown = () => {
    setJobTypeSelections([...jobTypeSelections, { jobType: '' }])
    setFormData([...formData, {}])
  }

  const handleJobFormValidation = (index, errors) => {
    setValidationErrors(prevErrors => {
      const updatedErrors = [...prevErrors]
      updatedErrors[index] = errors
      return updatedErrors
    })
  }

  const handleJobTypeChange = (e, index) => {
    const selectedValue = e.target.value
    const newJobTypeSelections = [...jobTypeSelections]
    newJobTypeSelections[index].jobType = selectedValue
    setJobTypeSelections(newJobTypeSelections)
    setFormData(prevData => {
      const updatedData = [...prevData]
      updatedData[index] = {
        ...updatedData[index],
        selected_value: selectedValue
      }
      return updatedData
    })
  }

  const handleRemoveJobForm = index => {
    const newJobTypeSelections = [...jobTypeSelections]
    newJobTypeSelections.splice(index, 1)
    setJobTypeSelections(newJobTypeSelections)
    const newFormData = [...formData]
    newFormData.splice(index, 1)
    setFormData(newFormData)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <NavBar />
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <div className='container editProfile'>
          <div className='row'>
            <div className='col-lg-3'>
              <JSSidebar />
            </div>
            <div className='col-lg-9 mb-5'>
              <h3>Professional Experience</h3>
              {jobTypeSelections.map((jobSelection, index) => (
                <div key={index}>
                  <JobTypeSelector
                    jobType={jobSelection.jobType}
                    onJobTypeChange={e => handleJobTypeChange(e, index)}
                    options={options}
                  />
                  {jobSelection.jobType && (
                    <JobForm
                      index={index}
                      jobType={jobSelection.jobType}
                      initialData={formData[index]} // Pass initial data
                      onDataChange={handleJobFormDataChange}
                      onValidate={errors =>
                        handleJobFormValidation(index, errors)
                      }
                      onRemove={handleRemoveJobForm}
                      yearsList={yearsList}
                      // getData={getData}
                    />
                  )}
                </div>
              ))}
              {/* <button
                onClick={handleAddDropdown}
                className='btn btn-primary button1'
              >
                Add More
              </button> */}
              <div className='JSEPFinalButton d-flex justify-content-between my-3'>
                <button
                  onClick={handleAddDropdown}
                  className='btn btn-primary button1'
                >
                  Add More
                </button>
                <button
                  onClick={handleClick}
                  className='btn btn-primary button1'
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ExperienceDetails
