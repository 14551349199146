import React, { useEffect, useState } from 'react'
import JSSidebar from './JSSidebar'
import NavBar from '../element/NavBar'
import Footer from '../element/Footer'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import ApiKey from '../api/ApiKey'
import BaseApi from '../api/BaseApi'
// import Multiselect from "multiselect-react-dropdown";
import JoditEditor from 'jodit-react'
import Select from 'react-select'
import { useRef } from 'react'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // import styles

const PartTime = () => {
  let primaryColor = Cookies.get('primaryColor')
  let secondaryColor = Cookies.get('secondaryColor')
  const mapKey = Cookies.get('mapKey')
  const [t, i18n] = useTranslation('global')
  const [editProfile, setEditProfile] = useState({
    part_time_certificate: '',
    job_position: '',
    company_name: '',
    location: '',
    job_profile: '',
    startMonth: '',
    startYear: '',
    endMonth: '',
    endYear: '',
    ongoing: false
    //  selected_value: ''
  })
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selected_value, setSelected_value] = useState('')

  const [internships, setInternships] = useState([])
  const [validationErrors, setValidationErrors] = useState([])
  const [yearsList, setYearsList] = useState([])
  const [errors, setErrors] = useState({})
  const [educationDetails, setEducationDetails] = useState([])
  const [loading, setLoading] = useState(false)
  const [autocompleteService, setAutocompleteService] = useState(null)
  const [suggestionsPreferred, setSuggestionsPreferred] = useState([])
  const [suggestionsNative, setSuggestionsNative] = useState([])
  const [suggestionTakenNative, setSuggestionTakenNative] = useState(false)
  const [options, setOptions] = useState([])

  const navigate = useNavigate()
  const tokenKey = Cookies.get('tokenClient') // Assuming you're using cookies for the token

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false)

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true)
  }

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false)
  }

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false)

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true)
  }

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false)
  }

  const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false)

  const handleThirdButtonMouseEnter = () => {
    setHoverThirdButtonColor(true)
  }

  const handleThirdButtonMouseLeave = () => {
    setHoverThirdButtonColor(false)
  }

  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true)
  }

  const handleFourthButtonMouseLeave = () => {
    setHoverFourthButtonColor(false)
  }

  // const handleChange = (e, index) => {
  //   const { name, value, type, checked } = e.target

  //   // Update the internships array
  //   const updatedInternships = [...internships]
  //   updatedInternships[index][name] =
  //     type === 'checkbox' ? (checked ? '1' : '0') : value

  //   setInternships(updatedInternships)
  // }

  const handleChange = (e, index) => {
    const { name, value, type, checked } = e.target

    // Update the internships array
    const updatedInternships = [...internships]
    updatedInternships[index][name] =
      type === 'checkbox' ? (checked ? '1' : '0') : value

    setInternships(updatedInternships)

    // Optionally clear validation errors for the specific field when typing
    setValidationErrors(prevErrors => {
      const updatedErrors = [...prevErrors]
      if (updatedErrors[index]) {
        updatedErrors[index] = {
          ...updatedErrors[index], // Create a shallow copy of the specific error object
          [name]: '' // Clear the error for the specific field
        }
      }
      return updatedErrors // Return the updated errors array
    })
  }

  const [fileName, setFileName] = useState('')

  const getFileIcon = fileName => {
    const ext = fileName.split('.').pop().toLowerCase()
    const icons = {
      pdf: 'fa-file-pdf',
      doc: 'fa-file-word',
      docx: 'fa-file-word',
      txt: 'fa-file-alt',
      xls: 'fa-file-excel',
      xlsx: 'fa-file-excel',
      csv: 'fa-file-csv',
      jpg: 'fa-file-image',
      jpeg: 'fa-file-image'
    }
    return icons[ext] || 'fa-file' // Default to a generic file icon
  }

  const handleRemove = async id => {
    try {
      const confirmationResult = await Swal.fire({
        title: t('jobseekerEducation.removeConfirmTitle'),
        text: t('jobseekerEducation.removeConfirmTxt'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('jobseekerEducation.yes'),
        cancelButtonText: t('jobseekerEducation.no')
      })
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteeducation/${id}`,
          null,
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey
            }
          }
        )
        if (response.data.status === 200) {
          getData()
          Swal.fire({
            title: t('jobseekerEducation.removeSuccessTitle'),
            icon: 'success',
            confirmButtonText: t('jobseekerEducation.close')
          })
        } else if (response.data.status === 400) {
          Cookies.remove('tokenClient')
          Cookies.remove('user_type')
          Cookies.remove('fname')
          navigate('/')
          Swal.fire({
            title: response.data.message,
            icon: 'warning',
            confirmButtonText: t('jobseekerEducation.close')
          })
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: t('jobseekerEducation.close')
          })
        }
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: t('tokenExpired.tokenExpired'),
          icon: 'warning',
          confirmButtonText: t('jobDescription.close')
        })
        setTimeout(function () {
          window.location.reload()
        }, 3000)
      }
      Swal.fire({
        title: t('jobseekerEducation.removeFailedTitle'),
        text: t('jobseekerEducation.removeFailedTxt'),
        icon: 'error',
        confirmButtonText: t('jobseekerEducation.close')
      })
    }
  }

  const handleRemoveWithoutId = indexToRemove => {
    // Remove internship entry without ID (local state update)
    setInternships(prevInternshipDetails =>
      prevInternshipDetails.filter((_, index) => index !== indexToRemove)
    )
  }
  const handleAdd = () => {
    const newQualification = {
      // basic_course_id: '', // Set default values for the new block
      // basic_university: '',
      // basic_year: '',
      // // graduation_title: "",
      // specialization_id: '' // Include specialization_id in the new education detail
      job_position: '',
      company_name: '',
      location: '',
      job_profile: '',
      startMonth: '',
      startYear: '',
      endMonth: '',
      endYear: '',
      ongoing: false
    }

    setInternships(prevInternships => [...prevInternships, newQualification])
  }
  const [suggestionTakenPreferred, setSuggestionTakenPreferred] =
    useState(false)
  const handlePreferredLocationChange = e => {
    const { value } = e.target
    setSuggestionTakenPreferred(false)
    if (value == '') {
      setSuggestionTakenPreferred(true)
    }
    if (value != '') {
      setErrors({
        ...errors,
        pre_location: ''
      })
    }

    setEditProfile(prevFilter => ({
      ...prevFilter,
      pre_location: value
    }))

    /*************  ✨ Codeium Command ⭐  *************/
    /**
     * Handles when a user clicks on a suggestion from the native location
     * input. Updates the input value with the suggestion and clears the
     * suggestions list.
     * @param {string} suggestion The suggestion that was clicked.
     */
    /******  e9055c96-6b93-4213-85d5-0b5527fb5028  *******/

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ['(cities)'] // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === 'OK' && predictions) {
            setSuggestionsPreferred(
              predictions.map(prediction => prediction.description)
            )
          } else {
            setSuggestionsPreferred([])
          }
        }
      )
    }
    if (editProfile.pre_location === '') {
      setSuggestionsPreferred([])
    }
  }

  const handleNativeLocationChange = e => {
    const { value } = e.target
    setSuggestionTakenNative(false)
    if (value == '') {
      setSuggestionTakenNative(true)
    }
    if (value != '') {
      setErrors({
        ...errors,
        location: ''
      })
    }

    setEditProfile(prevFilter => ({
      ...prevFilter,
      location: value
    }))

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ['(cities)'] // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === 'OK' && predictions) {
            setSuggestionsNative(
              predictions.map(prediction => prediction.description)
            )
          } else {
            setSuggestionsNative([])
          }
        }
      )
    }
    if (editProfile.location === '') {
      setSuggestionsNative([])
    }
  }

  const validateFields = () => {
    const errors = internships.map((internship, index) => ({
      job_position:
        internship.job_position.trim() === '' ? 'Job position is required' : '',
      company_name:
        internship.company_name.trim() === '' ? 'Company name is required' : '',
      startYear:
        internship.startYear.trim() === '' ? 'Start year is required' : '',
      location: internship.location.trim() === '' ? 'Location is required' : '',
      job_profile:
        internship.job_profile.trim() === '' ? 'Job profile is required' : '',
      startMonth:
        internship.startMonth.trim() === '' ? 'Start month is required' : '',
      endMonth:
        internship.endMonth.trim() === '' ? 'End month is required' : '',

      endYear: internship.endYear.trim() === '' ? 'End year is required' : '',
      ongoing: internship.ongoing === false ? false : true // Check if the job is ongoing
      // You can add more fields to validate here
    }))

    setValidationErrors(errors) // Set the validation errors in the state

    // Check if all fields are valid (no error messages)
    return errors.every(
      error => !error.job_position && !error.company_name && !error.startYear
      // Add checks for other fields if needed
    )
  }

  // const handleFileChange = (e, index) => {
  //   const files = Array.from(e.target.files) // Convert FileList to an array

  //   if (files.length > 0) {
  //     console.log(`Files selected for index ${index}:`, files)

  //     // Update the `selectedFiles` state for the specific index
  //     setSelectedFiles(prevFiles => {
  //       const updatedFiles = { ...prevFiles } // Create a shallow copy of the object
  //       updatedFiles[index] = [...(updatedFiles[index] || []), ...files] // Append new files to the existing ones
  //       return updatedFiles
  //     })

  //     // Update the `educationDetails` state for the specific index
  //     setEducationDetails(prevEducation => {
  //       const updatedEducation = [...prevEducation] // Create a shallow copy of the array
  //       updatedEducation[index] = {
  //         ...updatedEducation[index], // Create a shallow copy of the specific object
  //         experience_certificate: files // Update the specific field with the new files
  //       }
  //       return updatedEducation // Return the updated array
  //     })
  //   } else {
  //     console.warn(`No files selected for index ${index}.`)
  //   }
  // }

  // const handleFileChange = (e, index) => {
  //   const files = Array.from(e.target.files) // Convert FileList to an array

  //   if (files.length > 0) {
  //     console.log(`Files selected for index ${index}:`, files)

  //     // Update the `selectedFiles` state for the specific index
  //     setSelectedFiles(prevFiles => {
  //       const updatedFiles = { ...prevFiles } // Create a shallow copy of the object
  //       updatedFiles[index] = [...(updatedFiles[index] || []), ...files] // Append new files to the existing ones
  //       return updatedFiles
  //     })

  //     // Update the `educationDetails` state for the specific index
  //     setEducationDetails(prevEducation => {
  //       const updatedEducation = [...prevEducation] // Create a shallow copy of the array
  //       updatedEducation[index] = {
  //         ...updatedEducation[index], // Create a shallow copy of the specific object
  //         experience_certificate: [
  //           ...(updatedEducation[index]?.experience_certificate || []), // Keep existing certificates
  //           ...files // Append new files
  //         ]
  //       }
  //       return updatedEducation // Return the updated array
  //     })
  //   } else {
  //     console.warn(`No files selected for index ${index}.`)
  //   }
  // }

  // const handleRemoveFile = (index, fileIndex) => {
  //   setSelectedFiles(prevFiles => {
  //     const updatedFiles = { ...prevFiles } // Create a shallow copy of the object
  //     updatedFiles[index] = updatedFiles[index].filter(
  //       (_, idx) => idx !== fileIndex
  //     ) // Remove the file by index
  //     return updatedFiles
  //   })

  //   setEducationDetails(prevEducation => {
  //     const updatedEducation = [...prevEducation] // Create a shallow copy of the array
  //     updatedEducation[index] = {
  //       ...updatedEducation[index], // Create a shallow copy of the specific object
  //       experience_certificate: updatedEducation[
  //         index
  //       ].experience_certificate.filter((_, idx) => idx !== fileIndex) // Remove the file by index
  //     }
  //     return updatedEducation // Return the updated array
  //   })
  // }

  const handleFileChange = (e, index) => {
    const files = Array.from(e.target.files) // Convert FileList to an array

    if (files.length > 0) {
      setSelectedFiles(prevFiles => {
        const currentFiles = prevFiles[index] || []
        const newFiles = files.filter(
          file =>
            !currentFiles.some(existingFile => existingFile.name === file.name) // Exclude duplicate files
        )

        if (currentFiles.length + newFiles.length > 5) {
          Swal.fire({
            title: 'File Limit Exceeded',
            text: 'You can upload a maximum of 5 files.',
            icon: 'error'
          })
          return prevFiles // Return without updating
        }

        if (newFiles.length < files.length) {
          Swal.fire({
            title: 'Duplicate Files',
            text: 'Some files are duplicates and were not added.',
            icon: 'error'
          })
        }

        const updatedFiles = {
          ...prevFiles,
          [index]: [...currentFiles, ...newFiles]
        }

        // Update the educationDetails state
        setEducationDetails(prevEducation => {
          const updatedEducation = [...prevEducation]
          updatedEducation[index] = {
            ...updatedEducation[index],
            experience_certificate: updatedFiles[index]
          }
          return updatedEducation
        })

        return updatedFiles
      })
    } else {
      console.warn(`No files selected for index ${index}.`)
    }
  }

  const handleRemoveFile = (index, fileIndex) => {
    setSelectedFiles(prevFiles => {
      const updatedFiles = { ...prevFiles } // Create a shallow copy of the object
      updatedFiles[index] = updatedFiles[index].filter(
        (_, idx) => idx !== fileIndex
      ) // Remove the file by index
      return updatedFiles
    })

    setEducationDetails(prevEducation => {
      const updatedEducation = [...prevEducation] // Create a shallow copy of the array
      updatedEducation[index] = {
        ...updatedEducation[index], // Create a shallow copy of the specific object
        experience_certificate: updatedEducation[
          index
        ].experience_certificate.filter((_, idx) => idx !== fileIndex) // Remove the file by index
      }
      return updatedEducation // Return the updated array
    })
  }

  const handleClick = async () => {
    // Remove selected_value from internships if it's already included
    const updatedInternships = {
      ...internships,
      selected_value: selected_value // You can directly set this without adding it twice
    }

    if (!validateFields()) {
      window.scrollTo(0, 0) // Scroll to the top to see validation errors
      return
    }

    try {
      const confirmationResult = await Swal.fire({
        title: 'Confirm Part Time Jobs Details',
        text: 'Are you sure you want to save these details?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (confirmationResult.isConfirmed) {
        setLoading(true)
        console.log()

        const response = await axios.post(
          BaseApi + '/candidates/addparttimejobs',
          { PartTimeJobs: updatedInternships }, // Send education details as payload
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              key: ApiKey, // Replace with your actual API key
              token: tokenKey
            }
          }
        )

        setLoading(false)

        if (response.data.status === 200) {
          Swal.fire({
            title: 'Success!',
            text: 'Part Time Jobs details updated successfully.',
            icon: 'success',
            confirmButtonText: 'Close'
          })
          setEducationDetails([]) // Reset education details
          navigate('/candidates/educations')
        } else if (response.data.status === 400) {
          Cookies.remove('tokenClient')
          Cookies.remove('user_type')
          Cookies.remove('fname')
          navigate('/candidates/parttime')
          Swal.fire({
            title: response.data.message,
            icon: 'warning',
            confirmButtonText: 'Close'
          })
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: 'Close'
          })
        }
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/candidates/parttime')
        Swal.fire({
          title: 'Token Expired',
          icon: 'warning',
          confirmButtonText: 'Close'
        })
        setTimeout(function () {
          window.location.reload() // Reload page if the token is expired
        }, 3000)
      }

      Swal.fire({
        title: 'Failed to update Part Time Jobs',
        text: 'There was an error updating your Part Time Jobs details. Please try again.',
        icon: 'error',
        confirmButtonText: 'Close'
      })
    }
  }

  const handleSelectChange = async event => {
    const selectedValue = event.target.value
    setSelected_value(selectedValue) // Update state

    // console.log()

    setEducationDetails({
      ...internships,
      selected_value: selectedValue // Update the selected_value in the state
    })
    console.log('Selected Value:', selectedValue)
    if (selectedValue === '1') {
      // Navigate to the Experience page and fetch data
      try {
        const response = await axios.get(
          BaseApi + '/candidates/editExperience',
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              key: ApiKey,
              token: tokenKey
            }
          }
        )
        console.log('Experience Data:', response.data)
        // n('/experience');
        navigate('/candidates/professions/experience')
      } catch (error) {
        console.error('Error fetching Experience data:', error)
      }
    } else if (selectedValue === '2') {
      // Navigate to the Internship page and fetch data
      try {
        const response = await axios.get(
          BaseApi + '/candidates/addinternships',
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey
            }
          }
        )
        console.log('Internship Data:', response.data)
        // router.push('/internship');
        navigate('/candidates/internships')
      } catch (error) {
        console.error('Error fetching Internship data:', error)
      }
    } else if (selectedValue === '3') {
      // Navigate to the Part-Time page and fetch data
      try {
        const response = await axios.get(
          BaseApi + '/candidates/addparttimejobs',
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey
            }
          }
        )
        console.log('Part-Time Data:', response.data)
        // router.push('/part-time');
        navigate('/candidates/parttime')
      } catch (error) {
        console.error('Error fetching Part-Time data:', error)
      }
    }
  }

  const handleNativeSuggestionClick = suggestion => {
    // Update the input value with the clicked suggestion
    handleNativeLocationChange({
      target: { name: 'location', value: suggestion }
    })

    setSuggestionTakenNative(true)
    // Clear the suggestions
    setSuggestionsNative([])
    // console.log(filterItem);
  }

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/user/jobseekerlogin')
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData()
      window.scrollTo(0, 0)
      // if(educationDetails.basic_course_id) {
      // getSpecializationList(educationDetails.basic_course_id);
      // }
    }
  }, [tokenKey, navigate])

  const getData = async () => {
    try {
      setLoading(true)
      const response = await axios.post(
        BaseApi + '/candidates/addparttimejobs',
        null, // Pass null as the request body if not required
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey
          }
        }
      )
      if (response.data.status === 200) {
        // if (Array.isArray(response.data)) {
        //   setInternships(response.data);
        // } else {
        //   setInternships([]); // Fallback if the data is not an array
        // }

        setInternships(response.data)
        // setInternships(Array.isArray(response.data.response.data.userDetailsArray) ? response.data.response.data.userDetailsArray : []);
        // console.log(Array.isArray(response.data.userDetailsArray) ? response.data.userDetailsArray : [])
        setYearsList(response.data.response.data.yearList)
        setOptions(response.data.response.data.options)

        const data = response?.data?.response?.userDetailsArray || []
        setInternships(data)

        setSelected_value(
          response?.data?.response?.userDetailsArray[0]?.selected_value || ''
        )

        console.log(response.data)
        var selectedSkillsName = response.data.response.skills

        if (selectedSkillsName) {
          // code to handel preselected skills
          var skillList = response.data.response.skillList

          var SelectSkills = []
          skillList.forEach(element => {
            for (let i = 0; i < selectedSkillsName.length; i++) {
              if (selectedSkillsName[i] === element.name) {
                let obj = {
                  value: element.id,
                  label: element.name
                }

                SelectSkills.push(obj)
              }
            }
          })
        }
        // console.log("object");

        console.log(SelectSkills)

        // code to handel pre selected interest category

        var categoryList = response.data.response.categoryList
        var interestCategory = response.data.response.interest_categories
        var selectedCat = []

        categoryList.forEach(element => {
          for (let i = 0; i < interestCategory.length; i++) {
            if (parseInt(interestCategory[i]) === element.id) {
              let obj = {
                value: element.id,
                label: element.name
              }
              selectedCat.push(obj)
            }
          }
        })

        setLoading(false)
        setEditProfile(response.data.response)

        console.log('check')
        // setDesignationList(response.data.response.designationlList);
        // console.log(skillList);
      } else if (response.data.status === 400) {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: response.data.message,
          icon: 'warning',
          confirmButtonText: t('searchJobPage.close')
        })
      } else {
        Swal.fire({
          title: response.data.message,
          icon: 'error',
          confirmButtonText: t('searchJobPage.close')
        })
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: t('tokenExpired.tokenExpired'),
          icon: 'warning',
          confirmButtonText: t('jobDescription.close')
        })
        setTimeout(function () {
          window.location.reload()
        }, 3000)
      }
      console.log('Cannot get data of edit profile page')
    }
  }

  return (
    <>
      <NavBar />
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <>
          <div className='container editProfile'>
            <div className='row'>
              <div className='col-lg-3'>
                <JSSidebar />
              </div>

              <div
                className='col-lg-9 mb-5'
                style={{
                  borderLeft: '2px solid #e6e8e7',
                  borderRight: '2px solid #e6e8e7'
                }}
              >
                <div className='mx-3 d-flex PageHeader'>
                  <h3 className=''>Part-time jobs</h3>
                </div>
                <p className='mx-3'>
                  If you have side jobs, you can add them here
                </p>

                <form>
                  <div className='mb-5 mt-4 mx-4'>
                    {/* <select
                      className='form-select'
                      aria-label='Default select example'
                      onChange={handleSelectChange}
                    >
                      <option selected>Select</option>
                      <option value='1'>Experience</option>
                      <option value='2'>Internship</option>
                      <option value='3'>Part Time</option>
                    </select> */}
                    <select
                      className='form-select mb-3'
                      aria-label='Default select example'
                      onChange={handleSelectChange}
                      value={selected_value}
                      name='selected_value'
                    >
                      {/* {Object.keys(options).map(key => (
                        <option key={key} value={key}>
                          {options[key]}
                          {options[selected_value]}
                        </option>
                      ))} */}

                      {/* {Object.keys(options).map(key => (
                        <option key={key} value={key}>
                        
                          {options[selected_value]}
                        </option>
                      ))} */}
                      {Object.entries(options).map(([index, value]) => {
                        return (
                          <option key={index} value={index}>
                            {value}
                            {/* {options[selected_value]} */}
                          </option>
                        )
                      })}
                    </select>

                    {/* Display the selected option for debugging or UI */}

                    {/* </select> */}

                    {/* <p>Selected Value: {options[selected_value]}</p> */}
                    {internships.map((j, index) => {
                      return (
                        <>
                          <h4 className='mt-5 mb-5'>
                            Part Time Details {index + 1}:
                          </h4>
                          {/* <div className='form-outline mb-5 DashBoardInputBx'>
                            <label
                              className='form-label'
                              htmlFor='form3Example1'
                            >
                              Job Position
                              <span className='RedStar'>*</span>
                            </label>
                            <input
                              type='text'
                              id='form3Example1'
                              
                              className={`form-control ${
                                errors.job_position && 'input-error'
                              }`}
                              placeholder='Job Position'
                              name='job_position'
                              value={editProfile.job_position}
                              onChange={handleChange}
                            />
                            {errors.job_position && (
                              <div className='text-danger'>
                                {errors.job_position}
                              </div>
                            )}
                          </div> */}
                          <div className='form-outline mb-5 DashBoardInputBx'>
                            <label
                              className='form-label'
                              htmlFor='form3Example1'
                            >
                              Job Position
                              <span className='RedStar'>*</span>
                            </label>
                            <input
                              type='text'
                              id='form3Example1'
                              // className={`form-control ${
                              //   errors.job_position && "input-error"
                              // }`}
                              className={`form-control  ${
                                validationErrors[index]?.job_position &&
                                'input-error'
                              }`}
                              placeholder='Job Position'
                              name='job_position'
                              value={j.job_position}
                              // onChange={handleChange}
                              onChange={e => handleChange(e, index, j.id)}
                            />
                            {/* {errors.job_position && (
                              <div className='text-danger'>
                                {errors.job_position}
                              </div>
                            )} */}
                            {validationErrors[index]?.job_position && (
                              <div className='text-danger'>
                                {validationErrors[index].job_position}
                              </div>
                            )}
                          </div>

                          <div className='form-outline mb-5 DashBoardInputBx'>
                            <label
                              className='form-label'
                              htmlFor='form3Example3'
                            >
                              Employer (company Name)
                              <span className='RedStar'>*</span>
                            </label>
                            <input
                              type='text'
                              id='form3Example3'
                              // className={`form-control ${
                              //   errors.company_name && 'input-error'
                              // }`}
                              // className="form-control"
                              className={`form-control  ${
                                validationErrors[index]?.company_name &&
                                'input-error'
                              }`}
                              placeholder='Employer (company Name)'
                              name='company_name'
                              value={j.company_name}
                              // onChange={handleChange}
                              onChange={e => handleChange(e, index, j.id)}
                            />
                            {/* {errors.company_name && (
                              <div className='text-danger'>
                                {errors.company_name}
                              </div>
                            )} */}
                            {validationErrors[index]?.company_name && (
                              <div className='text-danger'>
                                {validationErrors[index].company_name}
                              </div>
                            )}
                          </div>

                          <div className='form-outline mb-5 DashBoardInputBx'>
                            <label
                              className='form-label'
                              htmlFor='form3Example3'
                            >
                              Location
                              <span className='RedStar'>*</span>
                            </label>
                            <input
                              type='text'
                              id='form3Example3'
                              // className={`form-control ${
                              //   errors.location && "input-error"
                              // }`}
                              className={`form-control  ${
                                validationErrors[index]?.location &&
                                'input-error'
                              }`}
                              placeholder='Location'
                              name='location'
                              value={j.location}
                              // onChange={handleNativeLocationChange}
                              onChange={e => handleChange(e, index, j.id)}
                            />
                            {suggestionsNative.length > 0 && (
                              <div
                                className='suggestions'
                                style={{
                                  display: suggestionTakenNative ? 'none' : ''
                                }}
                              >
                                <ul className='locationDropdown'>
                                  {suggestionsNative.map(
                                    (suggestion, index) => (
                                      <div
                                        key={index}
                                        className='suggestion-item'
                                      >
                                        <li
                                          onClick={() =>
                                            handleNativeSuggestionClick(
                                              suggestion
                                            )
                                          }
                                        >
                                          <div className='eachLocation'>
                                            <div className='locationIcon'>
                                              <LocationOnIcon fontSize='small' />
                                            </div>{' '}
                                            <div className='locationSuggestion'>
                                              {suggestion}
                                            </div>
                                          </div>{' '}
                                        </li>
                                      </div>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                            {/* {errors.location && (
                              <div className='text-danger'>
                                {errors.location}
                              </div>
                            )} */}
                            {validationErrors[index]?.location && (
                              <div className='text-danger'>
                                {validationErrors[index].location}
                              </div>
                            )}
                          </div>
                          <div className='form-outline mb-5 DashBoardInputBx'>
                            <h5>Duration</h5>
                          </div>

                          <div className='form-outline mb-5 DashBoardInputBx d-flex gap-4'>
                            <label
                              className='form-label'
                              htmlFor='startEmployment'
                            >
                              Start of Employment
                              <span className='RedStar'>*</span>
                            </label>

                            <div className='d-flex gap-3 form-control'>
                              {/* Start Month Container */}
                              <div className='w-50'>
                                <select
                                  name='startMonth'
                                  id='startMonth'
                                  className={`form-control form-select ${
                                    validationErrors[index]?.startMonth &&
                                    'input-error'
                                  }`}
                                  value={j.startMonth}
                                  onChange={e => handleChange(e, index, j.id)}
                                >
                                  <option value=''>Select Month</option>
                                  <option value='01'>January</option>
                                  <option value='02'>February</option>
                                  <option value='03'>March</option>
                                  <option value='04'>April</option>
                                  <option value='05'>May</option>
                                  <option value='06'>June</option>
                                  <option value='07'>July</option>
                                  <option value='08'>August</option>
                                  <option value='09'>September</option>
                                  <option value='10'>October</option>
                                  <option value='11'>November</option>
                                  <option value='12'>December</option>
                                </select>
                                {validationErrors[index]?.startMonth && (
                                  <div className='text-danger small'>
                                    {validationErrors[index].startMonth}
                                  </div>
                                )}
                              </div>

                              {/* Start Year Container */}
                              <div className='w-50'>
                                <select
                                  name='startYear'
                                  id='startYear'
                                  className={`form-control form-select ${
                                    validationErrors[index]?.startYear &&
                                    'input-error'
                                  }`}
                                  value={j.startYear}
                                  onChange={e => handleChange(e, index, j.id)}
                                >
                                  {/* <option value=''>Select Year</option>
                                  <option value='2020'>2020</option>
                                  <option value='2021'>2021</option>
                                  <option value='2022'>2022</option>
                                  <option value='2023'>2023</option>
                                  <option value='2024'>2024</option> */}
                                  <option selected value=''>
                                    {t('jobseekerExperience.selectYear')}
                                  </option>
                                  {Object.entries(yearsList).map(
                                    ([index, value]) => {
                                      return (
                                        <option value={value}>{value}</option>
                                      )
                                    }
                                  )}
                                </select>
                                {validationErrors[index]?.startYear && (
                                  <div className='text-danger small'>
                                    {validationErrors[index].startYear}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className='d-flex gap-3  form-control'>
                              {/* Conditionally show "Present" if ongoing is checked */}
                              {j.ongoing ? (
                                <div className='w-100 d-flex align-items-center'>
                                  <span className='fw-bold'>Present</span>
                                </div>
                              ) : (
                                <>
                                  {/* End Month */}
                                  <div className='w-50'>
                                    <label className='form-cellphone w-25'>
                                      End of Employment
                                      <span className='RedStar'>*</span>
                                    </label>
                                    <select
                                      name='endMonth'
                                      className={`form-control form-select ${
                                        validationErrors[index]?.endMonth &&
                                        'input-error'
                                      }`}
                                      value={j.endMonth}
                                      onChange={e =>
                                        handleChange(e, index, j.id)
                                      }
                                    >
                                      <option value=''>Select Month</option>
                                      <option value='01'>January</option>
                                      <option value='02'>February</option>
                                      <option value='03'>March</option>
                                      <option value='04'>April</option>
                                      <option value='05'>May</option>
                                      <option value='06'>June</option>
                                      <option value='07'>July</option>
                                      <option value='08'>August</option>
                                      <option value='09'>September</option>
                                      <option value='10'>October</option>
                                      <option value='11'>November</option>
                                      <option value='12'>December</option>
                                    </select>
                                    {validationErrors[index]?.endMonth && (
                                      <div className='text-danger small'>
                                        {validationErrors[index].endMonth}
                                      </div>
                                    )}
                                  </div>

                                  {/* End Year */}
                                  <div className='w-50'>
                                    <select
                                      name='endYear'
                                      className={`form-control form-select ${
                                        validationErrors[index]?.endYear &&
                                        'input-error'
                                      }`}
                                      value={j.endYear}
                                      onChange={e =>
                                        handleChange(e, index, j.id)
                                      }
                                    >
                                      {/* <option value=''>Select Year</option>
                                      <option value='2020'>2020</option>
                                      <option value='2021'>2021</option>
                                      <option value='2022'>2022</option>
                                      <option value='2023'>2023</option>
                                      <option value='2024'>2024</option> */}
                                      <option selected value=''>
                                        {t('jobseekerExperience.selectYear')}
                                      </option>
                                      {Object.entries(yearsList).map(
                                        ([index, value]) => {
                                          return (
                                            <option value={value}>
                                              {value}
                                            </option>
                                          )
                                        }
                                      )}
                                    </select>
                                    {validationErrors[index]?.endYear && (
                                      <div className='text-danger small'>
                                        {validationErrors[index].endYear}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          {/* </div> */}

                          {/* <div className="form-outline mb-5 DashBoardInputBx ongoing-container">
                            <input
                              type="checkbox"
                              id="ongoing"
                              name="ongoing"
                              checked={j.ongoing}
                              // onChange={() =>
                              //   setEditProfile({
                              //     ...editProfile,
                              //     ongoing: !editProfile.ongoing,
                              //     endMonth: '',
                              //     endYear: ''
                              //   })
                              // }
                              onChange={(e) => handleChange(e, index, j.id)}
                              className="form-check-input"
                            />
                            <label htmlFor="ongoing" className="ongoing-label">
                              Ongoing<span className="RedStar">*</span>
                            </label>
                          </div> */}
                          <div className='form-outline mb-3 d-flex gap-3 ongoing-container mb-5'>
                            <input
                              type='checkbox'
                              id={`ongoing-${j.id}`}
                              name='ongoing'
                              checked={j.ongoing}
                              onChange={e => handleChange(e, index, j.id)}
                              className='form-check-input'
                            />
                            <label
                              htmlFor={`ongoing-${j.id}`}
                              className='form-check-label'
                            >
                              Ongoing
                            </label>
                          </div>

                          <div className='form-outline mb-5 DashBoardInputBx'>
                            <label
                              className='form-label'
                              htmlFor='form3Example3'
                            >
                              Job Profile
                              <span className='RedStar'>*</span>
                            </label>
                            <input
                              type='text'
                              id='form3Example3'
                              // className={`form-control ${
                              //   errors.job_profile && "input-error"
                              // }`}
                              className={`form-control  ${
                                validationErrors[index]?.job_profile &&
                                'input-error'
                              }`}
                              placeholder='Job Profile'
                              name='job_profile'
                              value={j.job_profile}
                              // onChange={handleChange}
                              // onChange={e => handleChange(e, index)}
                              onChange={e => handleChange(e, index, j.id)}
                            />

                            {validationErrors[index]?.job_profile && (
                              <div className='text-danger'>
                                {validationErrors[index].job_profile}
                              </div>
                            )}
                          </div>

                          {/* <div className='row'>
                         
                            <div className='col-md-6'>
                              <div className='upload-box'>
                                <div
                                  className='upload-method'
                                  style={{
                                    border: '1px dashed #ccc',
                                    padding: '20px',
                                    textAlign: 'center',
                                    borderRadius: '8px',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() =>
                                    document
                                      .getElementById(`file-input-${index}`)
                                      .click()
                                  }
                                >
                                  <input
                                    type='file'
                                    id={`file-input-${index}`}
                                    name='experience_certificate'
                                    style={{ display: 'none' }}
                                    accept='.pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg'
                                    multiple // Allow multiple file selection
                                    onChange={e => handleFileChange(e, index)}
                                  />
                                  {selectedFiles[index]?.length === 0 ||
                                  !selectedFiles[index] ? (
                                    <>
                                      <p>
                                        📎{' '}
                                        <span
                                          style={{
                                            color: '#007BFF',
                                            textDecoration: 'underline'
                                          }}
                                        >
                                          Add files
                                        </span>{' '}
                                        or drag and drop files here
                                      </p>
                                      <p
                                        style={{
                                          fontSize: '12px',
                                          color: '#666'
                                        }}
                                      >
                                        Supported formats: .pdf, .doc, .docx,
                                        .txt, .xls, .csv, .jpg, .jpeg (max size:
                                        5 MB)
                                      </p>
                                    </>
                                  ) : (
                                    <div
                                      style={{
                                        marginTop: '10px',
                                        textAlign: 'center'
                                      }}
                                    >
                                      <strong>Selected Files:</strong>
                                      <ul>
                                        {selectedFiles[index]?.map(
                                          (file, idx) => (
                                            <li key={idx}>{file.name}</li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                </div>

                                <div className='upload-options'>
                                  <Link to='' className='google-drive'>
                                    <i className='fa-brands fa-google-drive'></i>
                                    Google Drive
                                  </Link>
                                  <Link to='' className='dropbox'>
                                    <i className='fa-brands fa-dropbox'></i>
                                    Dropbox
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='scan-box'>
                                <img
                                  src='/images/qr-scan.png'
                                  alt='Scan Icon'
                                />
                                <p className='scan-text'>
                                  Scan with your mobile phone
                                </p>
                                <p>
                                  (Only possible when using the Job to Date app)
                                </p>
                              </div>
                            </div>
                          </div> */}

                          <div className='file-upload-container'>
                            <div className='upload-box'>
                              <input
                                type='file'
                                name='experience_certificate'
                                id={`file-input-${index}`}
                                accept='.pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg'
                                multiple
                                onChange={e => handleFileChange(e, index)} // Handle file change
                                style={{ display: 'none' }}
                              />
                              <div
                                className='upload-method'
                                onClick={() =>
                                  document
                                    .getElementById(`file-input-${index}`)
                                    .click()
                                }
                              >
                                <p>
                                  <span role='img' aria-label='attachment'>
                                    📎
                                  </span>{' '}
                                  <span className='upload-text'>Add files</span>{' '}
                                  or drag and drop files here
                                </p>
                                <p className='upload-info'>
                                  Supported formats: .pdf, .doc, .docx, .txt,
                                  .xls, .csv, .jpg, .jpeg
                                </p>
                              </div>

                              {selectedFiles[index] &&
                                selectedFiles[index].length > 0 && (
                                  <div className='file-preview'>
                                    <strong>Selected Files:</strong>
                                    <ul>
                                      {selectedFiles[index].map(
                                        (file, fileIndex) => (
                                          <li
                                            key={fileIndex}
                                            className='file-item'
                                          >
                                            <i
                                              className={`fas ${getFileIcon(
                                                file.name
                                              )} file-icon`}
                                            ></i>
                                            <span className='file-name'>
                                              {file.name.length > 30
                                                ? `${file.name.slice(0, 30)}...`
                                                : file.name}
                                            </span>
                                            <button
                                              type='button'
                                              onClick={() =>
                                                handleRemoveFile(
                                                  index,
                                                  fileIndex
                                                )
                                              } // Remove file by index
                                              className='remove-btn'
                                            >
                                              &times;
                                            </button>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className='removeButtonJobseeker mt-4'>
                            {j.id ? (
                              <>
                                <button
                                  type='button'
                                  className='btn btn-primary button2'
                                  onClick={() => handleRemove(j.id)}
                                  style={{
                                    color: hoverFourthButtonColor
                                      ? primaryColor
                                      : secondaryColor,
                                    backgroundColor: 'white',
                                    border: hoverFourthButtonColor
                                      ? `2px solid ${primaryColor}`
                                      : `2px solid ${secondaryColor}`
                                  }}
                                  // onMouseEnter={handleFourthButtonMouseEnter}
                                  // onMouseLeave={handleFourthButtonMouseLeave}
                                >
                                  {t('jobseekerEducation.removeButton')}
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  type='button'
                                  className='btn btn-primary button2'
                                  onClick={() => handleRemoveWithoutId(index)}
                                  style={{
                                    color: hoverFourthButtonColor
                                      ? primaryColor
                                      : secondaryColor,
                                    backgroundColor: 'white',
                                    border: hoverFourthButtonColor
                                      ? `2px solid ${primaryColor}`
                                      : `2px solid ${secondaryColor}`
                                  }}
                                  // onMouseEnter={handleFourthButtonMouseEnter}
                                  // onMouseLeave={handleFourthButtonMouseLeave}
                                >
                                  {t('jobseekerEducation.removeButton')}
                                </button>
                              </>
                            )}
                          </div>
                        </>
                      )
                    })}

                    {/* {educationDetails.length > 0 && (
                      <>
                        <div className='EduAddMore mb-4'>
                          <button
                            type='button'
                            className='btn btn-primary button1'
                            onClick={handleAdd}
                            style={{
                              backgroundColor: primaryColor,
                              color: 'white',
                              border: primaryColor
                            }}
                          >
                            {t('jobseekerEducation.addMoreButton')}
                          </button>
                        </div>
                      </>
                    )} */}
                    {/* {internships.length > 0 && (
                      <>
                        <div className='EduAddMore mb-4'>
                          <button
                            type='button'
                            className='btn btn-primary button1'
                            onClick={handleAdd}
                            style={{
                              backgroundColor: primaryColor,
                              color: 'white',
                              border: primaryColor
                            }}
                          >
                            {t('jobseekerEducation.addMoreButton')}
                          </button>
                        </div>
                      </>
                    )} */}
                    {/* {educationDetails.length <= 0 && (
                      <>
                        <div className='EduAddMore mb-4'>
                          <button
                            type='button'
                            className='btn btn-primary button1'
                            onClick={handleAdd}
                            style={{
                              backgroundColor: primaryColor,
                              color: 'white',
                              border: primaryColor
                            }}
                          >
                            {t('jobseekerEducation.addDetails')}
                          </button>
                        </div>
                      </>
                    )} */}
                    {internships.length <= 0 && (
                      <>
                        <div className='EduAddMore mb-4'>
                          <button
                            type='button'
                            className='btn btn-primary button1'
                            onClick={handleAdd}
                            style={{
                              backgroundColor: primaryColor,
                              color: 'white',
                              border: primaryColor
                            }}
                          >
                            {t('jobseekerEducation.addDetails')}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </form>
                {internships.length > 0 && (
                  <>
                    <div className='EduAddMore mb-4'>
                      <button
                        type='button'
                        className='btn btn-primary button1'
                        onClick={handleAdd}
                        style={{
                          backgroundColor: primaryColor,
                          color: 'white',
                          border: primaryColor
                        }}
                      >
                        {t('jobseekerEducation.addMoreButton')}
                      </button>
                    </div>
                  </>
                )}

                <div className='JSEPFinalButton d-flex justify-content-between'>
                  <Link
                    to={`/candidates/internships`}
                    type='button'
                    // onClick={handleClick}
                    className='btn btn-primary button1'
                    style={{
                      backgroundColor: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor
                    }}
                    onMouseEnter={handleFirstButtonMouseEnter}
                    onMouseLeave={handleFirstButtonMouseLeave}
                  >
                    Back
                  </Link>
                  <button
                    // to={`/candidates/professions/experience`}
                    type='button'
                    onClick={handleClick}
                    className='btn btn-primary button1'
                    style={{
                      backgroundColor: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor
                    }}
                    onMouseEnter={handleFirstButtonMouseEnter}
                    onMouseLeave={handleFirstButtonMouseLeave}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  )
}

export default PartTime
