import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ApiKey from '../api/ApiKey'
import BaseApi from '../api/BaseApi'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import JSSidebar from './JSSidebar'
import NavBar from '../element/NavBar'
import Footer from '../element/Footer'

const CreateResumePreview = () => {
  const [resumeData, setResumeData] = useState(null)
  const tokenKey = Cookies.get('tokenClient')
  const [countries, setCountries] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const countryMapping = {
    1: 'Afghanistan',
    2: 'Albania',
    3: 'Algeria',
    4: 'Andorra',
    5: 'Angola',
    6: 'Antigua and Barbuda',
    7: 'Argentina',
    8: 'Armenia',
    9: 'Australia',
    10: 'Austria',
    11: 'Azerbaijan',
    12: 'Bahamas',
    13: 'Bahrain',
    14: 'Bangladesh',
    15: 'Barbados',
    16: 'Belarus',
    17: 'Belgium',
    18: 'Belize',
    19: 'Benin',
    20: 'Bhutan',
    21: 'Bolivia',
    22: 'Bosnia and Herzegovina',
    23: 'Botswana',
    24: 'Brazil',
    25: 'Brunei',
    26: 'Bulgaria',
    27: 'Burkina Faso',
    28: 'Burundi',
    29: 'Cabo Verde',
    30: 'Cambodia',
    31: 'Cameroon',
    32: 'Canada',
    33: 'Central African Republic',
    34: 'Chad',
    35: 'Chile',
    36: 'China',
    37: 'Colombia',
    38: 'Comoros',
    39: 'Congo (Congo-Brazzaville)',
    40: 'Costa Rica',
    41: 'Croatia',
    42: 'Cuba',
    43: 'Cyprus',
    44: 'Czechia',
    45: 'Denmark',
    46: 'Djibouti',
    47: 'Dominica',
    48: 'Dominican Republic',
    49: 'Ecuador',
    50: 'Egypt',
    51: 'El Salvador',
    52: 'Equatorial Guinea',
    53: 'Eritrea',
    54: 'Estonia',
    55: 'Eswatini',
    56: 'Ethiopia',
    57: 'Fiji',
    58: 'Finland',
    59: 'France',
    60: 'Gabon',
    61: 'Gambia',
    62: 'Georgia',
    63: 'Germany',
    64: 'Ghana',
    65: 'Greece',
    66: 'Grenada',
    67: 'Guatemala',
    68: 'Guinea',
    69: 'Guinea-Bissau',
    70: 'Guyana',
    71: 'Haiti',
    72: 'Honduras',
    73: 'Hungary',
    74: 'Iceland',
    75: 'India',
    76: 'Indonesia',
    77: 'Iran',
    78: 'Iraq',
    79: 'Ireland',
    80: 'Israel',
    81: 'Italy',
    82: 'Jamaica',
    83: 'Japan',
    84: 'Jordan',
    85: 'Kazakhstan',
    86: 'Kenya',
    87: 'Kiribati',
    88: 'Korea (North)',
    89: 'Korea (South)',
    90: 'Kosovo',
    91: 'Kuwait',
    92: 'Kyrgyzstan',
    93: 'Laos',
    94: 'Latvia',
    95: 'Lebanon',
    96: 'Lesotho',
    97: 'Liberia',
    98: 'Libya',
    99: 'Liechtenstein',
    100: 'Lithuania',
    101: 'Luxembourg',
    102: 'Madagascar',
    103: 'Malawi',
    104: 'Malaysia',
    105: 'Maldives',
    106: 'Mali',
    107: 'Malta',
    108: 'Marshall Islands',
    109: 'Mauritania',
    110: 'Mauritius',
    111: 'Mexico',
    112: 'Micronesia',
    113: 'Moldova',
    114: 'Monaco',
    115: 'Mongolia',
    116: 'Montenegro',
    117: 'Morocco',
    118: 'Mozambique',
    119: 'Myanmar',
    120: 'Namibia',
    121: 'Nauru',
    122: 'Nepal',
    123: 'Netherlands',
    124: 'New Zealand',
    125: 'Nicaragua',
    126: 'Niger',
    127: 'Nigeria',
    128: 'North Macedonia',
    129: 'Norway',
    130: 'Oman',
    131: 'Pakistan',
    132: 'Palau',
    133: 'Palestine State',
    134: 'Panama',
    135: 'Papua New Guinea',
    136: 'Paraguay',
    137: 'Peru',
    138: 'Philippines',
    139: 'Poland',
    140: 'Portugal',
    141: 'Qatar',
    142: 'Romania',
    143: 'Russia',
    144: 'Rwanda',
    145: 'Saint Kitts and Nevis',
    146: 'Saint Lucia',
    147: 'Saint Vincent and the Grenadines',
    148: 'Samoa',
    149: 'San Marino',
    150: 'Sao Tome and Principe',
    151: 'Saudi Arabia',
    152: 'Senegal',
    153: 'Serbia',
    154: 'Seychelles',
    155: 'Sierra Leone',
    156: 'Singapore',
    157: 'Slovakia',
    158: 'Slovenia',
    159: 'Solomon Islands',
    160: 'Somalia',
    161: 'South Africa',
    162: 'South Sudan',
    163: 'Spain',
    164: 'Sri Lanka',
    165: 'Sudan',
    166: 'Suriname',
    167: 'Sweden',
    168: 'Switzerland',
    169: 'Syria',
    170: 'Tajikistan',
    171: 'Tanzania',
    172: 'Thailand',
    173: 'Timor-Leste',
    174: 'Togo',
    175: 'Tonga',
    176: 'Trinidad and Tobago',
    177: 'Tunisia',
    178: 'Turkey',
    179: 'Turkmenistan',
    180: 'Tuvalu',
    181: 'Uganda',
    182: 'Ukraine',
    183: 'United Arab Emirates',
    184: 'United Kingdom',
    185: 'United States',
    186: 'Uruguay',
    187: 'Uzbekistan',
    188: 'Vanuatu',
    189: 'Vatican City',
    190: 'Venezuela',
    191: 'Vietnam',
    192: 'Yemen',
    193: 'Zambia',
    194: 'Zimbabwe'
  }

  const drivingLicenseMapping = {
    1: 'Two Wheeler',
    2: 'Four Wheeler',
    3: 'Six Wheeler',
    4: 'Eight Wheeler'
  }

  const getData = async () => {
    try {
      const response = await axios.get(
        BaseApi + '/candidates/getallcvdetails',
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey
          }
        }
      )
      setResumeData(response.data.response) // Update this to access the response data correctly
    } catch (error) {
      console.error('Error fetching CV details:', error)
    }
  }

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/jobseekerlogin')
    } else {
      getData()
      window.scrollTo(0, 0)
    }
  }, [tokenKey, navigate])

  if (!resumeData) {
    return <p>Loading...</p>
  }

  const {
    personalDetailsData,
    educationDetails,
    experienceData,
    internshipData,
    partTimeJobData,
    specialSkillData,
    languagesData,
    hobbies,
    links
  } = resumeData

  const placeOfBirth = personalDetailsData?.place_of_birth
  const countryName = countryMapping[placeOfBirth]

  return (
    <>
      {/* <NavBar /> */}
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <>
          <div className='container editProfile'>
            <div className='row'>
              {/* <div className='col-lg-3'>
                <JSSidebar />
              </div> */}

              <div
                className='col-lg-12 mb-5'
                style={{
                  borderLeft: '2px solid #e6e8e7',
                  borderRight: '2px solid #e6e8e7'
                }}
              >
                <div className='resume-container'>
                  <div className='resume-content'>
                    <div className='resume-header'>
                      {/* <img src="profile-placeholder.jpg" alt="Profile" className="profile-picture" /> */}
                      <img
                        src={
                          personalDetailsData?.profile_image ||
                          'profile-placeholder.jpg'
                        }
                        alt='Profile'
                        className='profile-picture'
                      />

                      <div className='name-title'>
                        <h1>{personalDetailsData?.candidate_name}</h1>
                        <p>{personalDetailsData?.job_title}</p>
                      </div>
                    </div>

                    <div className='resume-section'>
                      <h2>Profile</h2>
                      <p>{personalDetailsData?.about_me}</p>
                    </div>

                    <div className='resume-section'>
                      <h2>Employment History</h2>
                      {experienceData?.map((job, index) => (
                        <div key={index}>
                          <h3>
                            {job.job_position}, {job.company_name},{' '}
                            {job.location}
                          </h3>
                          <p className='date'>
                            {job.start_month} {job.start_year} — {job.end_month}{' '}
                            {job.end_year || 'Present'}
                          </p>
                          <ul>
                            <li>{job.job_profile}</li>
                          </ul>
                        </div>
                      ))}
                    </div>

                    <div className='resume-section'>
                      <h2>Education</h2>
                      {educationDetails?.map((edu, index) => (
                        <div key={index}>
                          <h3>
                            {edu.qualifications}, {edu.institutions},{' '}
                            {edu.location}
                          </h3>
                          <p className='date'>
                            {edu.start_month} {edu.start_year} — {edu.end_month}{' '}
                            {edu.end_year}
                          </p>
                          <ul>
                            {edu.details?.map((detail, i) => (
                              <li key={i}>{detail}</li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>

                    <div className='resume-section'>
                      <h2>Courses</h2>
                      {personalDetailsData?.courses?.map((course, index) => (
                        <div key={index}>
                          <h3>
                            {course.name}, {course.institution}
                          </h3>
                          <p className='date'>
                            {course.start_month} {course.start_year} —{' '}
                            {course.end_month} {course.end_year}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className='resume-sidebar'>
                    <div className='details-section'>
                      <h3>Details</h3>
                      <p>{personalDetailsData?.address}</p>
                      <p>{personalDetailsData?.mobile_number}</p>
                      <p>{personalDetailsData?.email_address}</p>
                      <p>
                        <strong>Driving License</strong> <br />
                        {/* {personalDetailsData?.driving_license} */}
                        {drivingLicenseMapping[
                          personalDetailsData?.driving_license
                        ] || 'Unknown'}
                      </p>
                      <p>
                        <strong>Place of Birth</strong>
                        <br />
                        {/* {personalDetailsData?.placeOfBirth} */}
                        {countryName || 'Not specified'}
                      </p>
                    </div>

                    <div className='links-section'>
                      <h3>Links</h3>
                      <ul>
                        {links?.map((link, index) => (
                          <li key={index}>
                            <a href={link.url}>{link.name}</a>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className='sidebar-section'>
                      {/* <div className='skills-section'>
              <h3>Skills</h3>
              {skills?.map((skill, index) => (
                <div className='skill' key={index}>
                  <span>{skill.special_skills}</span>
                  <div className='progress-bar'>
                    <div
                      className='progress'
                      style={{ width: `${skill.level}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>

            <div className='languages-section'>
              <h3>Languages</h3>
              {languages?.map((language, index) => (
                <div className='language' key={index}>
                  <span>{language.name}</span>
                  <div className='progress-bar'>
                    <div
                      className='progress'
                      style={{ width: `${language.proficiency}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div> */}

                      <div className='skills-section'>
                        <h3>Skills</h3>
                        {specialSkillData?.map((skill, index) => (
                          <div className='skill' key={index}>
                            <span>{skill.special_skills}</span>
                            <div className='progress-bar'>
                              <div
                                className='progress'
                                // style={{ width: `${getSkillLevelPercentage(skill.level)}%` }}
                              ></div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className='languages-section'>
                        <h3>Languages</h3>
                        {languagesData?.map((language, index) => (
                          <div className='language' key={index}>
                            <span>{language.languages}</span>
                            <div className='progress-bar'>
                              <div
                                className='progress'
                                // style={{ width: `${getLanguageProficiencyPercentage(language.proficiency)}%` }}
                              ></div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className='hobbies-section'>
                        <h3>Hobbies</h3>
                        <p>{hobbies?.join(', ')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default CreateResumePreview
