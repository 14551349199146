import React, { useEffect, useState } from 'react'
import JSSidebar from './JSSidebar'
import NavBar from '../element/NavBar'
import Footer from '../element/Footer'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import ApiKey from '../api/ApiKey'
import BaseApi from '../api/BaseApi'

import Select from 'react-select'
import { useRef } from 'react'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // import styles

const ContactInformation = () => {
  const [loading, setLoading] = useState(false)
  const [editProfile, setEditProfile] = useState([])
  const [skillList, setSkillList] = useState([])

  // const [userData, setUserData] = useState([])
  const [selectedCV, setSelectedCV] = useState([])
  // const [isCVPicked, setIsCVPicked] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState([])

  const [docDownloadPath, setDocDownloadPath] = useState()
  const [downloadActive, setDownloadActive] = useState(false)

  const [countries, setCountries] = useState([])
  const [genders, setGenders] = useState([])
  const [maritalStatuses, setMaritalStatuses] = useState([])
  const [socialNetworks, setSocialNetworks] = useState([])
  const [drivingLicenses, setDrivingLicenses] = useState([])

  const editor = useRef(null)
  const navigate = useNavigate()

  const userId = Cookies.get('userId')
  console.log('User ID from cookies:', userId)

  const tokenKey = Cookies.get('tokenClient')
  let primaryColor = Cookies.get('primaryColor')
  let secondaryColor = Cookies.get('secondaryColor')
  const mapKey = Cookies.get('mapKey')
  const [t, i18n] = useTranslation('global')

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false)

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true)
  }

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false)
  }

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false)

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true)
  }

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false)
  }

  const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false)

  const handleThirdButtonMouseEnter = () => {
    setHoverThirdButtonColor(true)
  }

  const handleThirdButtonMouseLeave = () => {
    setHoverThirdButtonColor(false)
  }

  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true)
  }

  const handleFourthButtonMouseLeave = () => {
    setHoverFourthButtonColor(false)
  }

  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email_address: '',
    address: '',
    postcode: '',
    phone: '',
    mobile: '',
    dateOfBirth: '',
    placeOfBirth: '',
    nationality: '',
    placeOfResidence: '',
    gender: '',
    maritalStatus: '',
    drivingLicense: '',
    socialLinks: ''
  })

  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    email_address: '',
    address: '',
    gender: '',
    phone: '',
    mobile: '',
    dateOfBirth: '',
    placeOfBirth: '',
    nationality: '',
    placeOfResidence: '',
    maritalStatus: '',
    drivingLicense: '',
    socialLinks: ''

    // location: '',
    // contact: '',
    // pre_location: '',
    // skills: [],
    // exp_salary: '',
    // total_exp: '',
    // company_about: '',
    // url: ''
  })

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email_address, setEmailAddress] = useState('')
  const [address, setAddress] = useState('')
  const [postcode, setPostcode] = useState('')
  const [gender, setGender] = useState('')
  const [phone, setPhone] = useState('')
  const [mobile, setMobile] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [placeOfBirth, setPlaceOfBirth] = useState('')
  const [nationality, setNationality] = useState('')
  const [placeOfResidence, setPlaceOfResidence] = useState('')
  const [maritalStatus, setMaritalStatus] = useState('')
  const [drivingLicense, setDrivingLicense] = useState('')
  const [socialLinks, setSocialLinks] = useState([])

  // const [formData, setFormData] = useState({
  //   firstName: '',
  //   lastName: '',
  //   email_address: '',
  //   address: '',
  //   postcode: '',
  //   phone: '',
  //   mobile: '',
  //   dateOfBirth: '',
  //   placeOfBirth: '',
  //   nationality: '',
  //   placeOfResidence: '',
  //   gender: '',
  //   maritalStatus: '',
  //   drivingLicense: '',
  //   socialLinks: ''
  // })

  const handleDateChange = e => {
    setDateOfBirth(e.target.value)
  }
  const handleGenderChange = e => {
    setGender(e.target.value) // Update gender state with selected value
  }
  const fetchData = async () => {
    console.log('Requesting data...')
    setLoading(true)

    try {
      const response = await axios.post(
        BaseApi + '/users/contactinfo',
        { user_id: userId },
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey
          }
        }
      )

      // Ensure the response status and data are valid
      if (response.status === 200 && response.data.status === 200) {
        const {
          first_name,
          last_name,
          email_address,
          address,
          postcode,
          mobile,
          phone,
          placeOfBirth,
          placeOfResidence
        } = response.data.response.data // Extract first_name

        setFirstName(first_name) // Update the state
        setLastName(last_name) // Update the state
        setEmailAddress(email_address) // Update the state
        setAddress(address) // Update the state
        setPostcode(postcode) // Update the state
        setMobile(mobile) // Update the state
        // setPhone(phone) // Update the state
        setPlaceOfBirth(placeOfBirth) // Update the state
        // setPlaceOfResidence(placeOfResidence) // Update the state
        // setGender(gender) // Update the state
        // setMaritalStatus(maritalStatus) // Update the state
        // setDrivingLicense(drivingLicense) // Update the state
        setSocialLinks(socialLinks) // Update the state
        // setNationality(nationality)

        setUserData(userData) // Update the

        console.log('Fetched First Name:', first_name)
        console.log('Fetched Last Name:', last_name)

        const {
          // first_name, // Extract first_name
          country,
          gender,
          maritalStatus,
          socialNetwork,
          drivingLicense
        } = response.data.response.data // Access nested data correctly

        // Set the first_name in a state variable for display
        setFirstName(response.data.response.userData.first_name) // Assuming `setFirstName` is a state setter
        console.log(response.data.response.userData.first_name)

        // Set the last_name in a state variable for display
        setLastName(response.data.response.userData.last_name) // Assuming `setLastName` is a state setter
        console.log(response.data.response.userData.last_name)

        // Set the email_address in a state variable for display
        setEmailAddress(response.data.response.userData.email_address) // Assuming `setEmailAddress` is a state setter
        console.log(response.data.response.userData.email_address)

        // Set the address in a state variable for display
        setAddress(response.data.response.userData.address) // Assuming `setAddress` is a state setter
        console.log(response.data.response.userData.address)

        // // Set the postcode in a state variable for display
        setPostcode(response.data.response.userData.Postcode) // Assuming `setPostcode` is a state setter

        // // Set the gender in a state variable for display

        // // Set the phone in a state variable for display
        setPhone(response.data.response.userData.phone) // Assuming `setPhone` is a state setter
        // console.log(response.data.response.userData.phone)

        // // Set the mobile in a state variable for display
        setMobile(response.data.response.userData.mobile) // Assuming `setMobile` is a state setter
        // console.log(response.data.response.userData.mobile)

        // // Set the dateOfBirth in a state variable for display
        setDateOfBirth(response.data.response.userData.dateOfBirth) // Assuming `setDateOfBirth` is a state setter
        // console.log(response.data.response.userData.dateOfBirth)

        // // Set the placeOfBirth in a state variable for display
        setPlaceOfBirth(response.data.response.userData.placeOfBirth) // Assuming `setPlaceOfBirth` is a state setter
        // console.log(response.data.response.userData.placeOfBirth)

        // // Set the nationality in a state variable for display
        setNationality(response.data.response.userData.nationality) // Assuming `setNationality` is a state setter
        // console.log(response.data.response.userData.nationality)

        // // Set the placeOfResidence in a state variable for display
        setPlaceOfResidence(response.data.response.userData.placeOfResidence) // Assuming `setPlaceOfResidence` is a state setter
        // console.log(response.data.response.userData.placeOfResidence)

        // // Set the maritalStatus in a state variable for display
        // setMaritalStatus(response.data.response.userData.maritalStatus) // Assuming `setMaritalStatus` is a state setter
        // console.log(response.data.response.userData.maritalStatus)

        // // Set the drivingLicense in a state variable for display
        // setDrivingLicense(response.data.response.userData.drivingLicense) // Assuming `setDrivingLicense` is
        // console.log(response.data.response.userData.drivingLicense)

        // // Set the socialLinks in a state variable for display
        // setSocialLinks(
        //   response.data.response.userData.socialNetwork.map(social => ({
        //     id: social.id,
        //     name: social.name,
        //     url: social.url
        //   }))
        // )

        // Convert object data to arrays for mapping
        setCountries(
          Object.entries(country).map(([key, value]) => ({
            id: key,
            name: value
          }))
        )
        setGenders(
          Object.entries(gender).map(([key, value]) => ({
            id: key,
            name: value
          }))
        )
        setMaritalStatuses(
          Object.entries(maritalStatus).map(([key, value]) => ({
            id: key,
            name: value
          }))
        )
        setSocialNetworks(
          Object.entries(socialNetwork).map(([key, value]) => ({
            id: key,
            name: value
          }))
        )
        setDrivingLicenses(
          Object.entries(drivingLicense).map(([key, value]) => ({
            id: key,
            name: value
          }))
        )
      } else {
        // If response status is not 200, display the message from the API
        Swal.fire({
          title: response.data.message || 'Unexpected error occurred.',
          icon: 'error',
          confirmButtonText: 'Close'
        })
      }
    } catch (error) {
      // Handle errors by logging them and showing a user-friendly message
      console.error('Error occurred during fetch:', error)

      Swal.fire({
        title:
          'Error fetching data. Please check your network connection or try again later.',
        icon: 'error',
        confirmButtonText: 'Close'
      })
    } finally {
      setLoading(false)
    }
  }

  // Use the `first_name` state variable where needed
  useEffect(() => {
    fetchData()
  }, [])

  const handleDocDownload = async (path, doc) => {
    setDocDownloadPath(path + doc)
    setDownloadActive(true)
    // console.log(docDownloadPath);
  }
  useEffect(() => {
    // console.log(downloadActive, DOCDownloadURL)
    if (downloadActive && docDownloadPath) {
      // Create a hidden link element
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = docDownloadPath
      link.download = 'generated-cv.doc'
      document.body.appendChild(link)

      // Trigger a click on the link
      link.click()

      // Clean up
      document.body.removeChild(link)
      setDownloadActive(false)
    }
  }, [downloadActive, docDownloadPath])

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/user/jobseekerlogin')
    } else {
      // TokenKey is present, fetch data or perform other actions
      // getData()

      window.scrollTo(0, 0)
    }
  }, [tokenKey, navigate])

  // const handleChange = e => {
  //   const { name, value } = e.target

  //   // if (name === "skill") {
  //   //   setEditProfile((prevJobData) => ({
  //   //     ...prevJobData,
  //   //     skill: [...prevJobData.skill, value],
  //   //   }));
  //   // }

  //   // setAddress(value);
  //   if (name === 'gender') {
  //     // console.log(value);
  //     if (value === 'female') {
  //       setEditProfile({ ...editProfile, gender: '1' })
  //     }
  //     if (value === 'male') {
  //       setEditProfile({ ...editProfile, gender: '0' })
  //     }
  //     if (value === null) {
  //       setEditProfile({ ...editProfile, gender: '0' })
  //     }
  //     setErrors(prev => ({
  //       ...prev,
  //       gender: ''
  //     }))
  //   } else {
  //     setEditProfile(prevJobData => ({
  //       ...prevJobData,
  //       [name]: value
  //     }))
  //     setErrors(prev => ({
  //       ...prev,
  //       [name]: ''
  //     }))
  //   }
  //   // console.log(editProfile);
  // }

  // const handleClick = async () => {
  //   try {
  //     const newErrors = {}
  //     console.log(newErrors)

  //     if (editProfile.first_name === '' || editProfile.first_name === null) {
  //       newErrors.first_name = t('jobseekerEditProfile.firstNameRequired')
  //       window.scrollTo(0, 0)
  //     }

  //     if (editProfile.last_name === '' || editProfile.last_name === null) {
  //       newErrors.last_name = t('jobseekerEditProfile.lastNameRequired')
  //       window.scrollTo(0, 0)
  //     }
  //     if (editProfile.address === '' || editProfile.address === null) {
  //       newErrors.address = 'Please enter a valid address'
  //       window.scrollTo(0, 0)
  //     }
  //     if (editProfile.gender === '' || editProfile.gender === null) {
  //       newErrors.gender = t('jobseekerEditProfile.genderRequired')
  //       window.scrollTo(0, 0)
  //     }
  //     if (editProfile.location === '' || editProfile.location === null) {
  //       newErrors.location = t('jobseekerEditProfile.nativeLocationRequired')
  //       window.scrollTo(0, 0)
  //     }
  //     // if (editProfile.contact === '' || editProfile.contact === null) {
  //     //   newErrors.contact = t('jobseekerEditProfile.contactNumber')
  //     //   window.scrollTo(0, 0)
  //     // } else if (!/^\+?\d{1,3}-?\d{9,15}$/.test(editProfile.contact)) {
  //     //   newErrors.contact = t('jobseekerEditProfile.contactNumbervalidation')
  //     //   window.scrollTo(0, 0)
  //     // }

  //     if (
  //       editProfile.pre_location === '' ||
  //       editProfile.pre_location === null
  //     ) {
  //       newErrors.pre_location = t(
  //         'jobseekerEditProfile.preferredJobLocationRequired'
  //       )
  //       window.scrollTo(0, 0)
  //     }
  //     if (
  //       editProfile.company_about === null ||
  //       editProfile.company_about === ' '
  //     ) {
  //       newErrors.company_about = t('jobseekerEditProfile.descriptionRequired')
  //       window.scrollTo(0, 0)
  //     }
  //     if (editProfile.skills === '' || editProfile.skills === null) {
  //       newErrors.skills = t('jobseekerEditProfile.skillRequired')
  //       window.scrollTo(0, 0)
  //     }
  //     if (editProfile.exp_salary === '' || editProfile.exp_salary === null) {
  //       newErrors.exp_salary = t('jobseekerEditProfile.expectedSalaryRequired')
  //       window.scrollTo(0, 0)
  //     }
  //     if (editProfile.total_exp === '' || editProfile.total_exp === null) {
  //       newErrors.total_exp = t('jobseekerEditProfile.totalExperienceRequired')
  //       window.scrollTo(0, 0)
  //     }
  //     if (editProfile.url) {
  //       const urlFormat =
  //         /^(https?:\/\/)?(www\.)?(youtube\.com\/(channel\/|user\/|c\/)?[a-zA-Z0-9_-]{1,})|(youtu\.be\/[a-zA-Z0-9_-]{1,})$/i
  //       if (editProfile.url && !urlFormat.test(editProfile.url)) {
  //         newErrors.url = t('jobseekerEditProfile.youtubeUrlInvalid')
  //         window.scrollTo(0, 0)
  //       }
  //     }

  //     setErrors(newErrors)

  //     if (Object.keys(newErrors).length === 0) {
  //       // const confirmationResult = await Swal.fire({
  //       //   title: t('jobseekerEditProfile.editConfirmTitle'),
  //       //   text: t('jobseekerEditProfile.editConfirmTxt'),
  //       //   icon: 'question',
  //       //   showCancelButton: true,
  //       //   confirmButtonText: t('jobseekerEditProfile.yes'),
  //       //   cancelButtonText: t('jobseekerEditProfile.no')

  //       // })
  //       navigate('/candidates/professions/experience')
  //       // if (confirmationResult.isConfirmed) {
  //       //   // console.log(skillArray, "skillArray");
  //       //   // console.log(categoryArray, "categoryArray");
  //       //   // console.log(selectedCV, "selectedCV");
  //       //   // const formData = new FormData();

  //       //   // // Append selected CVs and file names to FormData
  //       //   //   formData.append("selectedCV", selectedCV);

  //       //   // // Append editProfile as JSON string
  //       //   // formData.append("editProfile", editProfile);
  //       //   // // Append other data to FormData
  //       //   // formData.append("CoverLetter", coverLetter);
  //       //   // formData.append("skills", skillArray.join(","));
  //       //   // formData.append("interest_categories", categoryArray.join(","));

  //       //   // setLoading(true);
  //       //   // window.scrollTo(0, 0);

  //       //   // console.log(formData, "check formData");

  //       //   const formData = new FormData()
  //       //   formData.append('selectedCV', selectedCV)
  //       //   selectedFileName.forEach((fileName, index) => {
  //       //     formData.append(`selectedFileNames[${index}]`, fileName)
  //       //   })
  //       //   // console.log(formData);

  //       //   // const updatedProfile = {
  //       //   //   ...editProfile,
  //       //   //   CoverLetter: coverLetter,
  //       //   //   selectedCV: selectedCV, // Include the selected CV here
  //       //   //   selectedFileName: selectedFileName,
  //       //   //   skills: skillArray,
  //       //   //   interest_categories: categoryArray
  //       //   // }

  //       //   // console.log(updatedProfile, "updated profile");

  //       //   const response = await axios.post(
  //       //     BaseApi + '/candidates/editProfile',
  //       //     null,
  //       //     {
  //       //       headers: {
  //       //         'Content-Type': 'application/json',
  //       //         key: ApiKey,
  //       //         token: tokenKey
  //       //       }
  //       //     }
  //       //   )
  //       //   setLoading(false)
  //       //   if (response.data.status === 200) {
  //       //     Swal.fire({
  //       //       title: t('jobseekerEditProfile.editSuccessTitle'),
  //       //       icon: 'success',
  //       //       confirmButtonText: t('jobseekerEditProfile.close')
  //       //     })
  //       //     navigate('/candidates/myaccount')
  //       //   } else if (response.data.status === 400) {
  //       //     Cookies.remove('tokenClient')
  //       //     Cookies.remove('user_type')
  //       //     Cookies.remove('fname')
  //       //     navigate('/')
  //       //     Swal.fire({
  //       //       title: response.data.message,
  //       //       icon: 'warning',
  //       //       confirmButtonText: t('searchJobPage.close')
  //       //     })
  //       //   } else {
  //       //     Swal.fire({
  //       //       title: response.data.message,
  //       //       icon: 'error',
  //       //       confirmButtonText: t('jobseekerEditProfile.close')
  //       //     })
  //       //   }
  //       // }
  //     }
  //   } catch (error) {
  //     setLoading(false)
  //     if (error.message === 'Network Error') {
  //       Cookies.remove('tokenClient')
  //       Cookies.remove('user_type')
  //       Cookies.remove('fname')
  //       navigate('/')
  //       Swal.fire({
  //         title: t('tokenExpired.tokenExpired'),
  //         icon: 'warning',
  //         confirmButtonText: t('jobDescription.close')
  //       })
  //       setTimeout(function () {
  //         window.location.reload()
  //       }, 3000)
  //     }
  //     Swal.fire({
  //       title: t('jobseekerEditProfile.editFailedTitle'),
  //       icon: 'error',
  //       confirmButtonText: t('jobseekerEditProfile.close')
  //     })
  //     console.log('Could not submit edit data!')
  //   }
  // }

  const handleChange = e => {
    const { name, value } = e.target
    setUserData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  // const handleClick = async () => {
  //   const newErrors = {}

  //   // Validation logic for each field
  //   if (!firstName.trim()) {
  //     newErrors.first_name = 'First name is required.'
  //   }

  //   if (!lastName) {
  //     newErrors.last_name = 'Last name is required.'
  //   }

  //   if (!email_address) {
  //     newErrors.email_address = 'Email address is required.'
  //   } else if (!/\S+@\S+\.\S+/.test(email_address)) {
  //     newErrors.email_address = 'Invalid email address format.'
  //   }

  //   if (!address) {
  //     newErrors.address = 'Address is required.'
  //   }

  //   if (!postcode) {
  //     newErrors.postcode = 'Postcode/Zip is required.'
  //   } else if (!/^\d{4,6}$/.test(postcode)) {
  //     newErrors.postcode = 'Invalid postcode/zip.'
  //   }

  //   if (!phone) {
  //     newErrors.phone = 'Phone number is required.'
  //   } else if (!/^\d{10,15}$/.test(phone)) {
  //     newErrors.phone = 'Invalid phone number format.'
  //   }

  //   if (!mobile) {
  //     newErrors.mobile = 'Mobile/Cell phone is required.'
  //   } else if (!/^\d{10,15}$/.test(mobile)) {
  //     newErrors.mobile = 'Invalid mobile number format.'
  //   }

  //   if (!dateOfBirth) {
  //     newErrors.dateOfBirth = 'Date of birth is required.'
  //   }

  //   if (!placeOfBirth) {
  //     newErrors.placeOfBirth = 'Place of birth is required.'
  //   }

  //   if (!nationality) {
  //     newErrors.nationality = 'Nationality is required.'
  //   }

  //   if (!placeOfResidence) {
  //     newErrors.placeOfResidence = 'Place of residence is required.'
  //   }

  //   if (!gender) {
  //     newErrors.gender = 'Gender is required.'
  //   }

  //   if (!maritalStatus) {
  //     newErrors.maritalStatus = 'Marital status is required.'
  //   }

  //   if (!drivingLicense) {
  //     newErrors.drivingLicense = 'Driving license selection is required.'
  //   }

  //   if (!socialLinks) {
  //     newErrors.socialLinks = 'Social network selection is required.'
  //   }

  //   // If there are validation errors, update the state and return
  //   setErrors(newErrors)
  //   if (Object.keys(newErrors).length > 0) {
  //     window.scrollTo(0, 0) // Scroll to top to show errors
  //     return
  //   }

  //   // Submit the form if validation passes
  //   try {
  //     const response = await axios.post(
  //       BaseApi + 'users/contactinfo',
  //       userData,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           key: ApiKey,
  //           token: tokenKey
  //         }
  //       }
  //     )
  //     setLoading(false)
  //     if (response.data.status === 200) {
  //       Swal.fire({
  //         title: t('jobseekerEditProfile.editSuccessTitle'),
  //         icon: 'success',
  //         confirmButtonText: t('jobseekerEditProfile.close')
  //       })
  //       navigate('/candidates/professions/experience')
  //     } else if (response.data.status === 400) {
  //       Cookies.remove('tokenClient')
  //       Cookies.remove('user_type')
  //       Cookies.remove('fname')
  //       navigate('/')
  //       Swal.fire({
  //         title: response.data.message,
  //         icon: 'warning',
  //         confirmButtonText: t('searchJobPage.close')
  //       })
  //     } else {
  //       Swal.fire({
  //         title: response.data.message,
  //         icon: 'error',
  //         confirmButtonText: t('jobseekerEditProfile.close')
  //       })
  //     }
  //   } catch (error) {
  //     setLoading(false)
  //     if (error.message === 'Network Error') {
  //       Cookies.remove('tokenClient')
  //       Cookies.remove('user_type')
  //       Cookies.remove('fname')
  //       navigate('/')
  //       Swal.fire({
  //         title: t('tokenExpired.tokenExpired'),
  //         icon: 'warning',
  //         confirmButtonText: t('jobDescription.close')
  //       })
  //       setTimeout(function () {
  //         window.location.reload()
  //       }, 3000)
  //     }
  //     Swal.fire({
  //       title: t('jobseekerEditProfile.editFailedTitle'),
  //       icon: 'error',
  //       confirmButtonText: t('jobseekerEditProfile.close')
  //     })
  //     console.log('Could not submit edit data!')
  //   }
  // }

  // const handleClick = async () => {
  //   const newErrors = {}

  //   // Validation logic for each field
  //   if (!firstName || !firstName.trim()) {
  //     newErrors.first_name = 'First name is required.'
  //   }

  //   if (!lastName || !lastName.trim()) {
  //     newErrors.last_name = 'Last name is required.'
  //   }

  //   if (!email_address || !email_address.trim()) {
  //     newErrors.email_address = 'Email address is required.'
  //   } else if (!/\S+@\S+\.\S+/.test(email_address)) {
  //     newErrors.email_address = 'Invalid email address format.'
  //   }

  //   if (!address || !address.trim()) {
  //     newErrors.address = 'Address is required.'
  //   }

  //   if (!postcode || !postcode.trim()) {
  //     newErrors.postcode = 'Postcode/Zip is required.'
  //   } else if (!/^\d{4,6}$/.test(postcode)) {
  //     newErrors.postcode = 'Invalid postcode/zip.'
  //   }

  //   if (!phone || !phone.trim()) {
  //     newErrors.phone = 'Phone number is required.'
  //   } else if (!/^\d{10,15}$/.test(phone)) {
  //     newErrors.phone = 'Invalid phone number format.'
  //   }

  //   if (!mobile || !mobile.trim()) {
  //     newErrors.mobile = 'Mobile/Cell phone is required.'
  //   } else if (!/^\d{10,15}$/.test(mobile)) {
  //     newErrors.mobile = 'Invalid mobile number format.'
  //   }

  //   if (!dateOfBirth) {
  //     newErrors.dateOfBirth = 'Date of birth is required.'
  //   }

  //   if (!placeOfBirth || !placeOfBirth.trim()) {
  //     newErrors.placeOfBirth = 'Place of birth is required.'
  //   }

  //   if (!nationality || !nationality.trim()) {
  //     newErrors.nationality = 'Nationality is required.'
  //   }

  //   if (!placeOfResidence || !placeOfResidence.trim()) {
  //     newErrors.placeOfResidence = 'Place of residence is required.'
  //   }

  //   if (!gender) {
  //     newErrors.gender = 'Gender is required.'
  //   }

  //   if (!maritalStatus) {
  //     newErrors.maritalStatus = 'Marital status is required.'
  //   }

  //   if (!drivingLicense) {
  //     newErrors.drivingLicense = 'Driving license selection is required.'
  //   }

  //   if (!socialLinks) {
  //     newErrors.socialLinks = 'Social network selection is required.'
  //   }

  //   // If there are validation errors, update the state and return
  //   setErrors(newErrors)
  //   if (Object.keys(newErrors).length > 0) {
  //     window.scrollTo(0, 0) // Scroll to top to show errors
  //     return
  //   }

  //   // Submit the form if validation passes
  //   try {
  //     const response = await axios.post(
  //       BaseApi + 'users/contactinfo',
  //       userData,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           key: ApiKey,
  //           token: tokenKey
  //         }
  //       }
  //     )
  //     setLoading(false)
  //     if (response.data.status === 200) {
  //       Swal.fire({
  //         title: t('jobseekerEditProfile.editSuccessTitle'),
  //         icon: 'success',
  //         confirmButtonText: t('jobseekerEditProfile.close')
  //       })
  //       navigate('/candidates/professions/experience')
  //     } else if (response.data.status === 400) {
  //       Cookies.remove('tokenClient')
  //       Cookies.remove('user_type')
  //       Cookies.remove('fname')
  //       navigate('/')
  //       Swal.fire({
  //         title: response.data.message,
  //         icon: 'warning',
  //         confirmButtonText: t('searchJobPage.close')
  //       })
  //     } else {
  //       Swal.fire({
  //         title: response.data.message,
  //         icon: 'error',
  //         confirmButtonText: t('jobseekerEditProfile.close')
  //       })
  //     }
  //   } catch (error) {
  //     setLoading(false)
  //     if (error.message === 'Network Error') {
  //       Cookies.remove('tokenClient')
  //       Cookies.remove('user_type')
  //       Cookies.remove('fname')
  //       navigate('/')
  //       Swal.fire({
  //         title: t('tokenExpired.tokenExpired'),
  //         icon: 'warning',
  //         confirmButtonText: t('jobDescription.close')
  //       })
  //       setTimeout(function () {
  //         window.location.reload()
  //       }, 3000)
  //     }
  //     Swal.fire({
  //       title: t('jobseekerEditProfile.editFailedTitle'),
  //       icon: 'error',
  //       confirmButtonText: t('jobseekerEditProfile.close')
  //     })
  //     console.log('Could not submit edit data!')
  //   }
  // }

  const handleClick = async () => {
    const newErrors = {}

    // // Validation logic for each field
    // if (!firstName || !firstName.trim()) {
    //   newErrors.first_name = 'First name is required.';
    // }

    // if (!lastName || !lastName.trim()) {
    //   newErrors.last_name = 'Last name is required.';
    // }

    // if (!email_address || !email_address.trim()) {
    //   newErrors.email_address = 'Email address is required.';
    // } else if (!/\S+@\S+\.\S+/.test(email_address)) {
    //   newErrors.email_address = 'Invalid email address format.';
    // }

    // if (!address || !address.trim()) {
    //   newErrors.address = 'Address is required.';
    // }

    // if (!postcode || !postcode.trim()) {
    //   newErrors.postcode = 'Postcode/Zip is required.';
    // } else if (!/^\d{4,6}$/.test(postcode)) {
    //   newErrors.postcode = 'Invalid postcode/zip.';
    // }

    // if (!phone || !phone.trim()) {
    //   newErrors.phone = 'Phone number is required.';
    // } else if (!/^\d{10,15}$/.test(phone)) {
    //   newErrors.phone = 'Invalid phone number format.';
    // }

    // if (!mobile || !mobile.trim()) {
    //   newErrors.mobile = 'Mobile/Cell phone is required.';
    // } else if (!/^\d{10,15}$/.test(mobile)) {
    //   newErrors.mobile = 'Invalid mobile number format.';
    // }

    // if (!dateOfBirth) {
    //   newErrors.dateOfBirth = 'Date of birth is required.';
    // }

    // if (!placeOfBirth || !placeOfBirth.trim()) {
    //   newErrors.placeOfBirth = 'Place of birth is required.';
    // }

    // if (!nationality || !nationality.trim()) {
    //   newErrors.nationality = 'Nationality is required.';
    // }

    // if (!placeOfResidence || !placeOfResidence.trim()) {
    //   newErrors.placeOfResidence = 'Place of residence is required.';
    // }

    // if (!gender) {
    //   newErrors.gender = 'Gender is required.';
    // }

    // if (!maritalStatus) {
    //   newErrors.maritalStatus = 'Marital status is required.';
    // }

    // if (!drivingLicense) {
    //   newErrors.drivingLicense = 'Driving license selection is required.';
    // }

    // if (!socialLinks) {
    //   newErrors.socialLinks = 'Social network selection is required.';
    // }

    // Log validation errors
    console.log('Validation errors:', newErrors)

    setErrors(newErrors)

    if (Object.keys(newErrors).length > 0) {
      window.scrollTo(0, 0) // Scroll to top to show errors
      return
    }

    // Log the API request
    console.log('Making API call', userData)

    try {
      const response = await axios.post(
        BaseApi + 'users/contactinfo',
        userData,
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey
          }
        }
      )

      console.log('API Response:', response)

      setLoading(false)

      if (response.data.status === 200) {
        Swal.fire({
          title: t('jobseekerEditProfile.editSuccessTitle'),
          icon: 'success',
          confirmButtonText: t('jobseekerEditProfile.close')
        })
        navigate('/candidates/professions/experience')
      } else if (response.data.status === 400) {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/candidates/professions/experience')
        Swal.fire({
          title: response.data.message,
          icon: 'warning',
          confirmButtonText: t('searchJobPage.close')
        })
      } else {
        Swal.fire({
          title: response.data.message,
          icon: 'error',
          confirmButtonText: t('jobseekerEditProfile.close')
        })
      }
    } catch (error) {
      setLoading(false)
      console.error('Error during API request:', error)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('//candidates/professions/experience')
        Swal.fire({
          title: t('tokenExpired.tokenExpired'),
          icon: 'warning',
          confirmButtonText: t('jobDescription.close')
        })
        setTimeout(function () {
          window.location.reload()
        }, 3000)
      }
      Swal.fire({
        title: t('jobseekerEditProfile.editFailedTitle'),
        icon: 'error',
        confirmButtonText: t('jobseekerEditProfile.close')
      })
    }
  }

  useEffect(() => {
    // Load Google Maps AutocompleteService after component mounts
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places`
    // script.onload = () => {
    //   setAutocompleteService(
    //     new window.google.maps.places.AutocompleteService()
    //   )
    //   // console.log(autocompleteService);
    // }
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  // for preferred location box
  const [suggestionTakenPreferred, setSuggestionTakenPreferred] =
    useState(false)

  const handlePreferredLocationChange = e => {
    const { value } = e.target
    setSuggestionTakenPreferred(false)
    if (value == '') {
      setSuggestionTakenPreferred(true)
    }
    if (value != '') {
      setErrors({
        ...errors,
        pre_location: ''
      })
    }

    setEditProfile(prevFilter => ({
      ...prevFilter,
      pre_location: value
    }))

    // if (autocompleteService) {
    //   // Call Google Maps Autocomplete API
    //   autocompleteService.getPlacePredictions(
    //     {
    //       input: value,
    //       types: ['(cities)'] // Restrict to cities if needed
    //     },
    //     (predictions, status) => {
    //       if (status === 'OK' && predictions) {
    //         setSuggestionsPreferred(
    //           predictions.map(prediction => prediction.description)
    //         )
    //       } else {
    //         setSuggestionsPreferred([])
    //       }
    //     }
    //   )
    // }
    // if (editProfile.pre_location === '') {
    //   setSuggestionsPreferred([])
    // }
  }

  return (
    <>
      <NavBar />
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <>
          <div className='container editProfile'>
            <div className='row'>
              <div className='col-lg-3'>
                <JSSidebar />
              </div>

              <div
                className='col-lg-9 mb-5'
                style={{
                  borderLeft: '2px solid #e6e8e7',
                  borderRight: '2px solid #e6e8e7'
                }}
              >
                <div className='mx-3 d-flex PageHeader'>
                  {/* <img src="/Images/employerSide/icon8color.png" alt="" /> */}
                  <h3 className=''>Contact Information</h3>
                </div>
                <p className='mx-3'>
                  Enter your personal information in the corresponding fields.{' '}
                  <br />
                  You can change or update your information at any time.
                </p>

                <form>
                  <div className='mb-5 mt-4 mx-4'>
                    <div className='form-outline mb-5 DashBoardInputBx'>
                      <label className='form-label' htmlFor='form3Example1'>
                        {t('jobseekerEditProfile.firstName')}
                        <span className='RedStar'>*</span>
                      </label>

                      <input
                        type='text'
                        id='form3Example1'
                        className={`form-control ${
                          errors.first_name ? 'input-error' : ''
                        }`}
                        placeholder={t('jobseekerEditProfile.firstName')}
                        name='first_name'
                        value={firstName} // Bind state to the input value
                        // onChange={e => setFirstName(e.target.value)} // Update state on input change
                        onChange={handleChange}
                      />

                      {errors.first_name && (
                        <div className='text-danger'>{errors.first_name}</div>
                      )}
                    </div>
                    <div className='form-outline mb-5 DashBoardInputBx'>
                      <label className='form-label' htmlFor='form3Example3'>
                        {t('jobseekerEditProfile.lastName')}
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        id='form3Example3'
                        className={`form-control ${
                          errors.last_name && 'input-error'
                        }`}
                        placeholder={t('jobseekerEditProfile.lastName')}
                        name='last_name'
                        value={lastName}
                        onChange={handleChange}
                      />
                      {errors.last_name && (
                        <div className='text-danger'>{errors.last_name}</div>
                      )}
                    </div>

                    <div className='form-outline mb-5 DashBoardInputBx'>
                      <label className='form-label' htmlFor='form3Example3'>
                        {/* {t("jobseekerEditProfile.lastName")} */}
                        Email Address
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        id='form3Example3'
                        className={`form-control ${
                          errors.email_address && 'input-error'
                        }`}
                        placeholder='Email Address'
                        name='email_address'
                        value={email_address}
                        onChange={handleChange}
                      />
                      {errors.email_address && (
                        <div className='text-danger'>
                          {errors.email_address}
                        </div>
                      )}
                    </div>

                    <div className='form-outline mb-5 DashBoardInputBx'>
                      <label className='form-label' htmlFor='form3Example3'>
                        Address
                        {/* <span className='RedStar'>*</span> */}
                      </label>
                      <input
                        type='text'
                        id='form3Example3'
                        className={`form-control ${
                          errors.address && 'input-error'
                        }`}
                        placeholder='Address'
                        name='address'
                        value={address}
                        onChange={handleChange}
                      />

                      {errors.address && (
                        <div className='text-danger'>{errors.address}</div>
                      )}
                    </div>
                    <div className='form-outline mb-5 DashBoardInputBx '>
                      <label className='form-label' htmlFor='form3Example3'>
                        Postcode / Zip
                      </label>
                      <input
                        type='text'
                        // className='form-control'
                        className={`form-control ${
                          errors.postcode && 'input-error'
                        }`}
                        // id='postcode'
                        name='postcode'
                        value={postcode}
                        placeholder='Postcode / Zip'
                      />
                      {errors.postcode && (
                        <div className='text-danger'>{errors.postcode}</div>
                      )}
                    </div>
                    <div className='mx-3 d-flex PageHeader'>
                      <h3 className=''>Additional Points</h3>
                    </div>
                    <p className='mx-3'>
                      Add here any points you would like to include in your
                      contact information
                    </p>
                    <div className='mb-5 mt-4 mx-4'>
                      <div className='form-outline mb-5 DashBoardInputBx d-flex gap-3'>
                        <label className='form-label' htmlFor='form3Example3'>
                          Phone
                        </label>
                        <input
                          type='text'
                          // className='form-control'
                          className={`form-control ${
                            errors.phone && 'input-error'
                          }`}
                          id='residence'
                          name='phone'
                          placeholder='Contact Number'
                        />

                        {errors.phone && (
                          <div className='text-danger'>{errors.phone}</div>
                        )}

                        <label
                          className='form-cellphone'
                          htmlFor='form3Example3'
                        >
                          Mobile / Cell phone
                        </label>
                        <input
                          type='text'
                          className={`form-control ${
                            errors.mobile && 'input-error'
                          }`}
                          id='postcode'
                          name='mobile'
                          value={mobile}
                          placeholder='Contact Number'
                        />
                        {errors.mobile && (
                          <div className='text-danger'>{errors.mobile}</div>
                        )}
                      </div>
                    </div>
                    <div className='mb-5 mt-4 mx-4'>
                      <div className='form-outline mb-5 DashBoardInputBx d-flex gap-3'>
                        <label className='form-label' htmlFor='form3Example3'>
                          Date of birth
                        </label>
                        <input
                          type='date'
                          className={`form-control ${
                            errors.dateOfBirth && 'input-error'
                          }`}
                          // id='dateOfBirth'
                          // name='dateOfBirth'
                          // value={dateOfBirth}
                          // placeholder='Contact Number'
                          id='dateOfBirth'
                          name='dateOfBirth'
                          value={dateOfBirth}
                          onChange={handleDateChange}
                        />
                        <div>
                          {errors.dateOfBirth && (
                            <div className='text-danger'>
                              {errors.dateOfBirth}
                            </div>
                          )}
                        </div>

                        <label className='form-html' htmlFor='form3Example3'>
                          Place of birth
                        </label>
                        {/* <input
                          type='text'
                          className='form-control'
                          id='postcode'
                          placeholder='Contact Number'
                        /> */}
                        <select
                          name='dob'
                          id='placeOfBirth'
                          value={placeOfBirth}
                          // className='form-control form-select'
                          className={`form-control form-select ${
                            errors.placeOfBirth && 'input-error'
                          }`}
                        >
                          <option value=''>Select</option>
                          {/* <option value='1'>Option 1</option>
                          <option value='2'>Option 2</option>
                          <option value='3'>Option 3</option> */}
                          {countries.map(item => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.placeOfBirth && (
                          <div className='text-danger'>
                            {errors.placeOfBirth}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='mb-5 mt-4 mx-4'>
                      <div className='form-outline mb-5 DashBoardInputBx d-flex gap-3'>
                        <label className='form-label' htmlFor='nationality'>
                          Nationality
                        </label>
                        <select
                          name='nationality'
                          id='nationality'
                          // className='form-control form-select'
                          className={`form-control form-select ${
                            errors.nationality && 'input-error'
                          }`}
                        >
                          <option value=''>Select Nationality</option>

                          {countries.map(item => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.nationality && (
                          <div className='text-danger'>
                            {errors.nationality}
                          </div>
                        )}

                        <label className='form-cellphone' htmlFor='residence'>
                          Place of Residence
                        </label>
                        <select
                          name='placeOfResidence'
                          id='placeOfResidence'
                          // className='form-control form-select'
                          className={`form-control form-select ${
                            errors.placeOfResidence && 'input-error'
                          }`}
                        >
                          <option value=''>Select Place of Residence</option>
                          {/* <option value='1'>Place 1</option>
                          <option value='2'>Place 2</option>
                          <option value='3'>Place 3</option> */}
                          {countries.map(item => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.placeOfResidence && (
                          <div className='text-danger'>
                            {errors.placeOfResidence}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='mb-5 mt-4 mx-4'>
                      <div className='form-outline mb-5 DashBoardInputBx d-flex gap-3'>
                        <label className='form-label' htmlFor='gender'>
                          Gender
                        </label>
                        <select
                          name='gender'
                          id='gender'
                          value={gender}
                          className={`form-control form-select ${
                            errors.gender && 'input-error'
                          }`}
                          onChange={handleGenderChange}
                        >
                          <option value=''>Select Gender</option>

                          {genders.map(item => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.gender && (
                          <div className='text-danger'>{errors.gender}</div>
                        )}

                        <label className='form-html' htmlFor='maritalStatus'>
                          Marital Status
                        </label>
                        <select
                          name='maritalStatus'
                          id='maritalStatus'
                          // className='form-control form-select'
                          className={`form-control form-select ${
                            errors.maritalStatus && 'input-error'
                          }`}
                        >
                          <option value=''>Select Marital Status</option>

                          {maritalStatuses.map(item => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.maritalStatus && (
                          <div className='text-danger'>
                            {errors.maritalStatus}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='mb-5 mt-4 mx-4'>
                      <div className='form-outline mb-5 DashBoardInputBx d-flex gap-3'>
                        <label className='form-label' htmlFor='drivingLicense'>
                          Driving License
                        </label>
                        <select
                          name='drivingLicense'
                          id='drivingLicense'
                          className={`form-control form-select ${
                            errors.drivingLicense && 'input-error'
                          }`}
                        >
                          <option value=''>Select Driving License</option>

                          {drivingLicenses.map(item => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.drivingLicense && (
                          <div className='text-danger'>
                            {errors.drivingLicense}
                          </div>
                        )}

                        <label className='form-html' htmlFor='socialLinks'>
                          Social Network
                        </label>
                        <select
                          name='socialLinks'
                          id='socialLinks'
                          className={`form-control form-select ${
                            errors.socialLinks && 'input-error'
                          }`}
                        >
                          <option value=''>Select Social Network</option>

                          {socialNetworks.map(item => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.socialLinks && (
                          <div className='text-danger'>
                            {errors.socialLinks}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='JSEPFinalButton d-flex justify-content-end'>
                      {/* <button
                        type='button'
                        className='btn btn-primary button1'
                        onClick={handleClick}
                        style={{
                          backgroundColor: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                          border: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor
                        }}
                        onMouseEnter={handleFirstButtonMouseEnter}
                        onMouseLeave={handleFirstButtonMouseLeave}
                      >
                        {t("jobseekerEditProfile.updateButton")}
                        Next
                      </button> */}
                      <button
                        // to={`/candidates/professions/experience`}
                        type='button'
                        onClick={handleClick}
                        className='btn btn-primary button1'
                        style={{
                          backgroundColor: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                          border: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor
                        }}
                        onMouseEnter={handleFirstButtonMouseEnter}
                        onMouseLeave={handleFirstButtonMouseLeave}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  )
}

export default ContactInformation
