import React, { useEffect, useState } from 'react'
import JSSidebar from './JSSidebar'
import NavBar from '../element/NavBar'
import Footer from '../element/Footer'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import ApiKey from '../api/ApiKey'
import BaseApi from '../api/BaseApi'
// import Multiselect from "multiselect-react-dropdown";
import JoditEditor from 'jodit-react'
import Select from 'react-select'
import { useRef } from 'react'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // import styles

const EducationDetails = () => {
  const [loading, setLoading] = useState(false)
  let primaryColor = Cookies.get('primaryColor')
  let secondaryColor = Cookies.get('secondaryColor')
  const [errors, setErrors] = useState({})

  const [validationErrors, setValidationErrors] = useState([])
  const [suggestionTakenNative, setSuggestionTakenNative] = useState(false)
  const [suggestionsNative, setSuggestionsNative] = useState([])
  const [yearsList, setYearsList] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])

  const mapKey = Cookies.get('mapKey')
  const [t, i18n] = useTranslation('global')
  const [educations, setEducationDetails] = useState({
    qualifications: '',

    institutions: '',
    location: '',
    start_month: '',
    start_year: '',
    end_month: '',
    end_year: '',
    ongoing: false,
    subject_area: '',
    education_certificate: ''
  })



  

  const getFileIcon = file => {
    // Check if the input is a File object and extract the name
    const fileName = typeof file === 'object' && file.name ? file.name : file;
  
    // Ensure fileName is a valid string
    if (typeof fileName !== 'string') {
      console.error('Invalid file input:', file);
      return 'fa-file'; // Default icon for invalid input
    }
  
    const ext = fileName.split('.').pop().toLowerCase();
    const icons = {
      pdf: 'fa-file-pdf',
      doc: 'fa-file-word',
      docx: 'fa-file-word',
      txt: 'fa-file-alt',
      xls: 'fa-file-excel',
      xlsx: 'fa-file-excel',
      csv: 'fa-file-csv',
      jpg: 'fa-file-image',
      jpeg: 'fa-file-image',
    };
  
    return icons[ext] || 'fa-file'; // Default to a generic file icon
  };
  
  
  // const [educations, setEducations] = useState([]);

  // const [educations, setEducationDetails] = useState([]);

  const [autocompleteService, setAutocompleteService] = useState(null)

  const navigate = useNavigate()
  const tokenKey = Cookies.get('tokenClient') // Assuming you're using cookies for the token

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false)

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true)
  }

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false)
  }





  // const handleFileChange = (e, index) => {
  //   const files = Array.from(e.target.files) // Convert FileList to an array

  //   setSelectedFiles(prevFiles => {
  //     const currentFiles = Array.isArray(prevFiles[index])
  //       ? prevFiles[index]
  //       : []

  //     // Filter out duplicate files based on file name
  //     const newFiles = files.filter(
  //       file =>
  //         !currentFiles.some(existingFile => existingFile.name === file.name)
  //     )

  //     // Check for file limit
  //     if (currentFiles.length + newFiles.length > 5) {
  //       Swal.fire({
  //         title: 'File Limit Exceeded',
  //         text: 'You can upload a maximum of 5 files.',
  //         icon: 'error'
  //       })
  //       return prevFiles // Return without updating
  //     }

  //     // Show warning if there are duplicate files
  //     if (newFiles.length < files.length) {
  //       Swal.fire({
  //         title: 'Duplicate Files',
  //         text: 'Some files are duplicates and were not added.',
  //         icon: 'error'
  //       })
  //     }

     

  //     // Update the selected files state
  //     const updatedFiles = {
  //       ...prevFiles,
  //       [index]: [...currentFiles, ...newFiles]
  //     }

  //     // Update the education details state with the new files
  //     setEducationDetails(prevInternships => {
  //       const updatedInternships = [...prevInternships]
  //       updatedInternships[index] = {
  //         ...updatedInternships[index],
  //         education_certificate: updatedFiles[index]
  //       }
  //       return updatedInternships
  //     })

  //     return updatedFiles
  //   })
  // }

  // const handleFileChange = (e, index) => {
  //   const files = Array.from(e.target.files); // Convert FileList to an array
  
  //   setSelectedFiles(prevFiles => {
  //     // Ensure the current files for this index exist and are an array
  //     const currentFiles = Array.isArray(prevFiles[index]) ? prevFiles[index] : [];
  
  //     // Filter out duplicate files based on file name
  //     const newFiles = files.filter(file => {
  //       // Log the file object and its name for debugging
  //       console.log('File:', file);
  //       console.log('File name:', file.name);
  
  //       // Check if file.name is a string before using .split()
  //       if (typeof file.name !== 'string') {
  //         console.error('file.name is not a string:', file.name);
  //         return false; // Skip this file if name is not a string
  //       }
  
  //       // Now we can safely use .split() on file.name
  //       const fileName = file.name; // Access the file name properly
  //       return !currentFiles.some(existingFile => existingFile.name === fileName);
  //     });
  
  //     // Check for file limit
  //     if (currentFiles.length + newFiles.length > 5) {
  //       Swal.fire({
  //         title: 'File Limit Exceeded',
  //         text: 'You can upload a maximum of 5 files.',
  //         icon: 'error'
  //       });
  //       return prevFiles; // Return without updating
  //     }
  
  //     // Show warning if there are duplicate files
  //     if (newFiles.length < files.length) {
  //       Swal.fire({
  //         title: 'Duplicate Files',
  //         text: 'Some files are duplicates and were not added.',
  //         icon: 'error'
  //       });
  //     }
  
  //     // Update the selected files state
  //     const updatedFiles = [...currentFiles, ...newFiles];
  
  //     // Update the selected files for the specific index
  //     const newFilesState = [...prevFiles];
  //     newFilesState[index] = updatedFiles;
  
  //     // Update the education details state with the new files
  //     setEducationDetails(prevInternships => {
  //       const updatedInternships = [...prevInternships];
  //       updatedInternships[index] = {
  //         ...updatedInternships[index],
  //         education_certificate: updatedFiles
  //       };
  //       return updatedInternships;
  //     });
  
  //     return newFilesState; // Return the updated state
  //   });
  // };
  
  
  // const handleFileChange = (e, index) => {
  //   const files = Array.from(e.target.files); // Convert FileList to an array
  
  //   setSelectedFiles(prevFiles => {
  //     // Ensure the current files for this index exist and are an array
  //     const currentFiles = Array.isArray(prevFiles[index]) ? prevFiles[index] : [];

  //     // Filter out duplicate files based on file name
  //     const newFiles = files.filter(file => {
  //       // Log the file object and its name for debugging
  //       console.log('File:', file);
  //       console.log('File name:', file.name);
  
  //       // Check if file.name is a string before using .split()
  //       if (typeof file.name !== 'string') {
  //         console.error('file.name is not a string:', file.name);
  //         return false; // Skip this file if name is not a string
  //       }
  
  //       // Now we can safely use .split() on file.name
  //       const fileName = file.name; // Access the file name properly
  //       return !currentFiles.some(existingFile => existingFile.name === fileName);
  //     });
      
  //     // Check for file limit
  //     if (currentFiles.length + newFiles.length > 5) {
  //       Swal.fire({
  //         title: 'File Limit Exceeded',
  //         text: 'You can upload a maximum of 5 files.',
  //         icon: 'error'
  //       });
  //       return prevFiles; // Return without updating
  //     }
      
  //     // Show warning if there are duplicate files
  //     if (newFiles.length < files.length) {
  //       Swal.fire({
  //         title: 'Duplicate Files',
  //         text: 'Some files are duplicates and were not added.',
  //         icon: 'error'
  //       });
  //     }
  
  //     // Update the selected files state
  //     const updatedFiles = [...currentFiles, ...newFiles];
      
  //     // Update the selected files for the specific index
  //     const newFilesState = [...prevFiles];
  //     newFilesState[index] = updatedFiles;
  
  //     // Update the education details state with the new files
  //     setEducationDetails(prevInternships => {
  //       const updatedInternships = [...prevInternships];
  //       updatedInternships[index] = {
  //         ...updatedInternships[index],
  //         education_certificate: updatedFiles
  //       };
  //       return updatedInternships;
  //     });
  //     return newFilesState; // Return the updated state
  //   });
  // };
  
  // const handleFileChange = (event, index) => {
  //   const files = Array.from(event.target.files); // Convert FileList to an array
  //   const fileNames = files.map(file => file.name); // Get the names of the selected files

  //   console.log(selectedFiles,"yaha hgu")

  //   // Check for file limit
  //   // Check if the total number of files exceeds the limit
  //   if (selectedFiles.length + fileNames.length > 5) {
  //     Swal.fire({
  //       title: 'File Limit Exceeded',
  //       text: 'You can upload a maximum of 5 files.',
  //       icon: 'error'
  //     });
  //     return;
  //   }

  //   // Check for duplicates now
  //   Object.entries(selectedFiles).map(([key,value]) => {
  //     if(value === fileNames){
  //       Swal.fire({
  //         title: 'Duplicate Files',
  //         text: 'Some files are duplicates and were not added.',
  //         icon: 'error'
  //       });
  //       return
  //     }
  //   })
  //   // Update the state by spreading the existing file names and adding the new ones
  //   setSelectedFiles(prevFiles => [
  //     ...prevFiles,
  //     ...fileNames
  //   ]);
  // };


  // const handleFileChange = (event, index) => {
  //   const files = Array.from(event.target.files); // Convert FileList to an array
  //   const fileNames = files.map(file => file.name); // Get the names of the selected files
  
  //   console.log(selectedFiles, "yaha hgu");
  
  //   // Check for file limit
  //   if (selectedFiles.length + fileNames.length > 5) {
  //     Swal.fire({
  //       title: 'File Limit Exceeded',
  //       text: 'You can upload a maximum of 5 files.',
  //       icon: 'error'
  //     });
  //     return;
  //   }
  
  //   // Check for duplicates
  //   const isDuplicate = files.some(fileNames => 
  //     selectedFiles.some(existingFile => existingFile.name === fileNames)
  //   );
  //   console.log(isDuplicate, "isDuplicate")
  
  //   if (isDuplicate) {
  //     Swal.fire({
  //       title: 'Duplicate Files',
  //       text: 'Some files are duplicates and were not added.',
  //       icon: 'error'
  //     });
  //     return;
  //   }
  
  //   // Update the state by spreading the existing files and adding the new ones
  //   setSelectedFiles(prevFiles => [
  //     ...prevFiles,
  //     ...fileNames // Add new files to the existing array
  //   ]);
  // };
  

  // const handleFileChange = (event, index) => {
  //   const files = Array.from(event.target.files); // Convert FileList to an array
  //   const fileNames = files.map(file => file.name); // Get the names of the selected files
  
  //   console.log(selectedFiles, "Current selected files");
  
  //   // Check for file limit
  //   if (selectedFiles.length + fileNames.length > 5) {
  //     Swal.fire({
  //       title: 'File Limit Exceeded',
  //       text: 'You can upload a maximum of 5 files.',
  //       icon: 'error'
  //     });
  //     return;
  //   }
  
  //   // Check for duplicates
  //   const isDuplicate = fileNames.some(newFileName => 
  //     selectedFiles.includes(newFileName) // Check if the new file name already exists in selectedFiles
  //   );
  
  //   console.log(isDuplicate, "isDuplicate");
  
  //   if (isDuplicate) {
  //     Swal.fire({
  //       title: 'Duplicate Files',
  //       text: 'Some files are duplicates and were not added.',
  //       icon: 'error'
  //     });
  //     return;
  //   }
  
  //   // Update the state by spreading the existing files and adding the new ones
  //   setSelectedFiles(prevFiles => [
  //     ...prevFiles,
  //     ...fileNames // Add new files to the existing array
  //   ]);

  //   setEducationDetails(prevInternships => {
  //           const updatedInternships = [...prevInternships]
  //           updatedInternships[index] = {
  //             ...updatedInternships[index],
  //             education_certificate: updatedFiles[index]
  //           }
  //           return updatedInternships
  //         })
  // };


  // const handleFileChange = (event, index) => {
  //   const files = Array.from(event.target.files); // Convert FileList to an array
  //   const fileNames = files.map(file => file.name); // Get the names of the selected files
  
  //   console.log(selectedFiles, "Current selected files");
  
  //   // Check for file limit
  //   if (selectedFiles.length + fileNames.length > 5) {
  //     Swal.fire({
  //       title: 'File Limit Exceeded',
  //       text: 'You can upload a maximum of 5 files.',
  //       icon: 'error'
  //     });
  //     return;
  //   }
  
  //   // Check for duplicates
  //   const isDuplicate = fileNames.some(newFileName => 
  //     selectedFiles.includes(newFileName) // Check if the new file name already exists in selectedFiles
  //   );
  
  //   console.log(isDuplicate, "isDuplicate");
  
  //   if (isDuplicate) {
  //     Swal.fire({
  //       title: 'Duplicate Files',
  //       text: 'Some files are duplicates and were not added.',
  //       icon: 'error'
  //     });
  //     return;
  //   }
  
  //   // Update the state by spreading the existing files and adding the new ones
  //   setSelectedFiles(prevFiles => [
  //     ...prevFiles,
  //     ...fileNames // Add new files to the existing array
  //   ]);
  
  //   // Update the education details with the new files
  //   setEducationDetails(prevInternships => {
  //     const updatedInternships = [...prevInternships];
  //     updatedInternships[index] = {
  //       ...updatedInternships[index],
  //       education_certificate: [
  //         ...(updatedInternships[index].education_certificate || []), // Ensure we start with existing certificates
  //         ...fileNames // Add the new file names
  //       ]
  //     };
  //     return updatedInternships;
  //   });
  // };


  // const handleFileChange = (event, index) => {
  //   const files = Array.from(event.target.files); // Convert FileList to an array
  //   const fileNames = files.map(file => file.name); // Get the names of the selected files
  
  //   console.log(selectedFiles, "Current selected files");
  
  //   // Check for file limit
  //   if (selectedFiles.length + files.length > 5) {
  //     Swal.fire({
  //       title: 'File Limit Exceeded',
  //       text: 'You can upload a maximum of 5 files.',
  //       icon: 'error'
  //     });
  //     return;
  //   }
  
  //   // Check for duplicates
  //   const isDuplicate = fileNames.some(newFileName => 
  //     selectedFiles.some(existingFile => existingFile.name === newFileName) // Check if the new file name already exists in selectedFiles
  //   );
  
  //   console.log(isDuplicate, "isDuplicate");
  
  //   if (isDuplicate) {
  //     Swal.fire({
  //       title: 'Duplicate Files',
  //       text: 'Some files are duplicates and were not added.',
  //       icon: 'error'
  //     });
  //     return;
  //   }
  
  //   // Update the selectedFiles state with the new files
  //   setSelectedFiles(prevFiles => [
  //     ...prevFiles,
  //     ...fileNames // Add the new files (file objects) to the selectedFiles array
  //   ]);
  
  //   // Update the education details with the new files (without calling API)
  //   setEducationDetails(prevInternships => {
  //     const updatedInternships = [...prevInternships];
  //     updatedInternships[index] = {
  //       ...updatedInternships[index],
  //       education_certificate: [
  //         ...(updatedInternships[index].education_certificate || []), // Ensure we start with existing certificates
  //         ...fileNames // Add the new files (file objects)
  //       ]
  //     };
  //     return updatedInternships;
  //   });
  // };


  const handleFileChange = (event, index) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
  
    console.log(selectedFiles, "Current selected files");
  
    // Check for file limit
    if (selectedFiles.length + files.length > 5) {
      Swal.fire({
        title: 'File Limit Exceeded',
        text: 'You can upload a maximum of 5 files.',
        icon: 'error'
      });
      return;
    }
  
    // Check for duplicates
    const isDuplicate = files.some(newFile => 
      selectedFiles.some(existingFile => existingFile.name === newFile.name) // Check if the new file name already exists in selectedFiles
    );
  
    console.log(isDuplicate, "isDuplicate");
  
    if (isDuplicate) {
      Swal.fire({
        title: 'Duplicate Files',
        text: 'Some files are duplicates and were not added.',
        icon: 'error'
      });
      return;
    }
  
    // Update the selectedFiles state with the new files
    setSelectedFiles(prevFiles => [
      ...prevFiles,
      ...files // Add the new file objects to the selectedFiles array
    ]);
  
    // Update the education details with the new files (without calling API)
    setEducationDetails(prevInternships => {
      const updatedInternships = [...prevInternships];
      updatedInternships[index] = {
        ...updatedInternships[index],
        education_certificate: [
          ...(updatedInternships[index].education_certificate || []), // Ensure we start with existing certificates
          ...files // Add the new file objects
        ]
      };
      return updatedInternships;
    });
  };
  
  

  const handleRemoveFileWithName = async file => {
    try {
      console.log(file, 'File')
    } catch (error) {
      console.log(error.message)
    }
  }
  


  const handleRemoveFile = (index, fileIndex) => {
    // Update the selected files state
    setSelectedFiles(prevFiles => {
      // Create a new array by filtering out the file at fileIndex
      const updatedFiles = prevFiles.filter((_, idx) => idx !== fileIndex);
      return updatedFiles; // Return the updated files array
    });
  
    // Update the education details state
    setEducationDetails(prevEducation => {
      // Create a shallow copy of the education details array
      const updatedEducation = [...prevEducation];
  
      // Check if the index and property exist before updating
      if (
        updatedEducation[index] &&
        Array.isArray(updatedEducation[index].education_certificate)
      ) {
        // Create a new array by filtering out the file at fileIndex
        updatedEducation[index] = {
          ...updatedEducation[index], // Shallow copy of the specific object
          education_certificate: updatedEducation[index].education_certificate.filter(
            (_, idx) => idx !== fileIndex // Remove the file at the given index
          )
        };
      } else {
        console.warn(
          `No education certificate found at index ${index} or invalid structure`
        );
      }
  
      return updatedEducation; // Return the updated education array
    });
  };

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false)

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true)
  }

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false)
  }

  const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false)

  const handleThirdButtonMouseEnter = () => {
    setHoverThirdButtonColor(true)
  }

  const handleThirdButtonMouseLeave = () => {
    setHoverThirdButtonColor(false)
  }

  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true)
  }
  const handleNativeSuggestionClick = suggestion => {
    // Update the input value with the clicked suggestion
    handleNativeLocationChange({
      target: { name: 'location', value: suggestion }
    })

    setSuggestionTakenNative(true)
    // Clear the suggestions
    setSuggestionsNative([])
    // console.log(filterItem);
  }

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/user/jobseekerlogin')
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData()
      window.scrollTo(0, 0)
      // if(educationDetails.basic_course_id) {
      // getSpecializationList(educationDetails.basic_course_id);
      // }
    }
  }, [tokenKey, navigate])
  const getData = async () => {
    try {
      setLoading(true)
      const response = await axios.post(
        BaseApi + '/candidates/editEducation',
        null, // Pass null as the request body if not required
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey
          }
        }
      )
      if (response.data.status === 200) {
        

        if (response.data.response.eduDetails.length === 0) {
          setEducationDetails([
            {
              qualifications: '',

              institutions: '',
              location: '',
              start_month: '',
              start_year: '',
              end_month: '',
              end_year: '',
              ongoing: false,
              subject_area: '',
              education_certificate: ''
            }
          ]) // Set default empty fields
        } else {
          setEducationDetails(response.data.response.eduDetails)
        }
        setYearsList(response.data.response.yearList)
        // console.log(response.data.response)
        const certificates = response.data.response.eduDetails
          .map(eduDetails => eduDetails.education_certificate ? eduDetails.education_certificate : [])
          .flat()
        setSelectedFiles(certificates)
        console.log('Selected Files:', certificates)
        setLoading(false)
      } else if (response.data.status === 400) {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: response.data.message,
          icon: 'warning',
          confirmButtonText: t('searchJobPage.close')
        })
      } else {
        Swal.fire({
          title: response.data.message,
          icon: 'error',
          confirmButtonText: t('searchJobPage.close')
        })
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: t('tokenExpired.tokenExpired'),
          icon: 'warning',
          confirmButtonText: t('jobDescription.close')
        })
        setTimeout(function () {
          window.location.reload()
        }, 3000)
      }
      console.log('Cannot get data of edit profile page')
    }
  }

  const handleFourthButtonMouseLeave = () => {
    setHoverFourthButtonColor(false)
  }

  const handleChange = (e, index) => {
    const { name, value, type, checked } = e.target

    // Update the educations array
    const updatedEducations = [...educations]
    updatedEducations[index][name] =
      type === 'checkbox' ? (checked ? '1' : '0') : value

    // Clear validation error for the specific field
    const updatedValidationErrors = [...validationErrors]
    if (updatedValidationErrors[index]) {
      delete updatedValidationErrors[index][name] // Remove the error for this field
    }

    setEducationDetails(updatedEducations)
    setValidationErrors(updatedValidationErrors) // Update validation errors
  }

  const handleRemove = async id => {
    try {
      const confirmationResult = await Swal.fire({
        title: t('jobseekerEducation.removeConfirmTitle'),
        text: t('jobseekerEducation.removeConfirmTxt'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('jobseekerEducation.yes'),
        cancelButtonText: t('jobseekerEducation.no')
      })
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteeducation/${id}`,
          null,
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey
            }
          }
        )
        if (response.data.status === 200) {
          getData()
          Swal.fire({
            title: t('jobseekerEducation.removeSuccessTitle'),
            icon: 'success',
            confirmButtonText: t('jobseekerEducation.close')
          })
        } else if (response.data.status === 400) {
          Cookies.remove('tokenClient')
          Cookies.remove('user_type')
          Cookies.remove('fname')
          navigate('/')
          Swal.fire({
            title: response.data.message,
            icon: 'warning',
            confirmButtonText: t('jobseekerEducation.close')
          })
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: t('jobseekerEducation.close')
          })
        }
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: t('tokenExpired.tokenExpired'),
          icon: 'warning',
          confirmButtonText: t('jobDescription.close')
        })
        setTimeout(function () {
          window.location.reload()
        }, 3000)
      }
      Swal.fire({
        title: t('jobseekerEducation.removeFailedTitle'),
        text: t('jobseekerEducation.removeFailedTxt'),
        icon: 'error',
        confirmButtonText: t('jobseekerEducation.close')
      })
    }
  }


  const handleRemoveWithoutId = indexToRemove => {
    // Remove internship entry without ID (local state update)
    // setInternships(prevInternshipDetails =>
    //   prevInternshipDetails.filter((_, index) => index !== indexToRemove)
    // )
  }

  const handleNativeLocationChange = e => {
    const { value } = e.target
    setSuggestionTakenNative(false)
    if (value == '') {
      setSuggestionTakenNative(true)
    }
    if (value != '') {
      setErrors({
        ...errors,
        location: ''
      })
    }



    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ['(cities)'] // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === 'OK' && predictions) {
            setSuggestionsNative(
              predictions.map(prediction => prediction.description)
            )
          } else {
            setSuggestionsNative([])
          }
        }
      )
    }
    // if (editProfile.location === '') {
    //   setSuggestionsNative([])
    // }
  }

  const handleAdd = () => {
    const newQualification = {
      // basic_course_id: '', // Set default values for the new block
      // basic_university: '',
      // basic_year: '',
      // // graduation_title: "",
      // specialization_id: '' // Include specialization_id in the new education detail
      qualifications: '',

      institutions: '',
      location: '',
      start_month: '',
      start_year: '',
      end_month: '',
      end_year: '',
      ongoing: false,
      subject_area: ''
    }

    setEducationDetails(prevEducationDetails => [
      ...prevEducationDetails,
      newQualification
    ])
    // setInternships(prevInternships => [...prevInternships, newQualification])
  }

  const validateFields = () => {
    const errors = educations.map((education, index) => ({
      qualifications:
        education.qualifications?.trim() === ''
          ? 'Qualifications is required'
          : '',
      institutions:
        education.institutions?.trim() === '' ? 'Institution is required' : '',
      start_year:
        education.start_year?.trim() === '' ? 'Start year is required' : '',
      location: education.location?.trim() === '' ? 'Location is required' : '',
      subject_area:
        education.subject_area?.trim() === '' ? 'Subject is required' : '',
      start_month:
        education.start_month?.trim() === '' ? 'Start month is required' : '',
      end_month:
        education.end_month?.trim() === '' ? 'End month is required' : '',
      end_year: education.end_year?.trim() === '' ? 'End year is required' : '',
      ongoing: education.ongoing === false ? false : true // Check if the job is ongoing
    }))

    setValidationErrors(errors) // Set the validation errors in the state
    console.log(errors)

    // Check if all fields are valid (no error messages)
    return errors.every(
      error =>
        !error.qualifications &&
        !error.institutions &&
        !error.start_year &&
        !error.location &&
        !error.subject_area &&
        !error.start_month &&
        !error.end_month &&
        !error.end_year
    )
  }

  // Function to handle form submission
  const handleClick = async () => {
  
    if (!validateFields()) {
      window.scrollTo(0, 0) // Scroll to the top to see validation errors
      return
    }

    try {
      const confirmationResult = await Swal.fire({
        title: 'Confirm Education Details',
        text: 'Are you sure you want to save these details?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (confirmationResult.isConfirmed) {
        setLoading(true)
        console.log()

        const response = await axios.post(
          BaseApi + '/candidates/editEducation',
          // null,
          { Education: educations }, // Send education details as payload
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              key: ApiKey, // Replace with your actual API key
              token: tokenKey
            }
          }
        )

        setLoading(false)

        if (response.data.status === 200) {
          Swal.fire({
            title: 'Success!',
            text: 'Educations details updated successfully.',
            icon: 'success',
            confirmButtonText: 'Close'
          })
          setEducationDetails([]) // Reset education details
          // Optional: Reload the page or call a function to update UI
          navigate('/candidates/skills')
        } else if (response.data.status === 400) {
          Cookies.remove('tokenClient')
          Cookies.remove('user_type')
          Cookies.remove('fname')
          navigate('/candidates/parttime') // Redirect if token expired or other issue
          Swal.fire({
            title: response.data.message,
            icon: 'warning',
            confirmButtonText: 'Close'
          })
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: 'Close'
          })
        }
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/candidates/parttime')
        Swal.fire({
          title: 'Token Expired',
          icon: 'warning',
          confirmButtonText: 'Close'
        })
        setTimeout(function () {
          window.location.reload() // Reload page if the token is expired
        }, 3000)
      }

      Swal.fire({
        title: 'Failed to update Educations details',
        text: 'There was an error updating your Educations details details. Please try again.',
        icon: 'error',
        confirmButtonText: 'Close'
      })
    }
  }



  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <>
          <div className='container editProfile'>
            <div className='row'>
              <div className='col-lg-3'>
                <JSSidebar />
              </div>

              <div
                className='col-lg-9 mb-5'
                style={{
                  borderLeft: '2px solid #e6e8e7',
                  borderRight: '2px solid #e6e8e7'
                }}
              >
                <div className='mx-3 d-flex PageHeader'>
                  <h3 className=''>Education</h3>
                </div>
                <p className='mx-3'>
                  If your desired details are not available for selection here,
                  then select the qualification that comes closest to yours.
                </p>

                <form>
                  <div className='mb-5 mt-4 mx-4'>
                    {Array.isArray(educations) &&
                      educations.map((j, index) => {
                        return (
                          <>
                            <h4 className='mt-5 mb-5'>
                              Education Details {index + 1}:
                            </h4>

                            <div className='form-outline mb-5 DashBoardInputBx'>
                              <label
                                className='form-label'
                                htmlFor='form3Example1'
                              >
                                Qualification
                                <span className='RedStar'>*</span>
                              </label>

                              <select
                                name='qualifications'
                                id='qualifications'
                                className={`form-control form-select ${
                                  validationErrors[index]?.qualifications &&
                                  'input-error'
                                }`}
                                value={j.qualifications || ''}
                                onChange={e => handleChange(e, index, j.id)}
                              >
                                <option value=''>Select Qualification</option>
                                <option value='High School'>High School</option>
                                <option value='Diploma'>Diploma</option>
                                <option value="Bachelor's Degree">
                                  Bachelor's Degree
                                </option>
                                <option value="Master's Degree">
                                  Master's Degree
                                </option>
                                <option value='Doctorate'>Doctorate</option>
                                <option value='Other'>Other</option>
                              </select>

                              {validationErrors[index]?.qualifications && (
                                <div className='text-danger'>
                                  {validationErrors[index].qualifications}
                                </div>
                              )}
                            </div>

                            <div className='form-outline mb-5 DashBoardInputBx'>
                              <label
                                className='form-label'
                                htmlFor='form3Example3'
                              >
                                Institute / School
                                <span className='RedStar'>*</span>
                              </label>
                              <input
                                type='text'
                                id='form3Example3'
                                // className={`form-control ${
                                //   errors.company_name && 'input-error'
                                // }`}
                                // className="form-control"
                                className={`form-control  ${
                                  validationErrors[index]?.institutions &&
                                  'input-error'
                                }`}
                                placeholder=' Institute / School'
                                name='institutions'
                                value={j.institutions || ''}
                                // onChange={handleChange}
                                onChange={e => handleChange(e, index, j.id)}
                              />

                              {validationErrors[index]?.institutions && (
                                <div className='text-danger'>
                                  {validationErrors[index].institutions}
                                </div>
                              )}
                            </div>

                            <div className='form-outline mb-5 DashBoardInputBx'>
                              <label
                                className='form-label'
                                htmlFor='form3Example3'
                              >
                                Location
                                <span className='RedStar'>*</span>
                              </label>
                              <input
                                type='text'
                                id='form3Example3'
                                // className={`form-control ${
                                //   errors.location && "input-error"
                                // }`}
                                className={`form-control  ${
                                  validationErrors[index]?.location &&
                                  'input-error'
                                }`}
                                placeholder='Location'
                                name='location'
                                value={j.location || ''}
                                // onChange={handleNativeLocationChange}
                                onChange={e => handleChange(e, index, j.id)}
                              />
                              {suggestionsNative.length > 0 && (
                                <div
                                  className='suggestions'
                                  style={{
                                    display: suggestionTakenNative ? 'none' : ''
                                  }}
                                >
                                  <ul className='locationDropdown'>
                                    {suggestionsNative.map(
                                      (suggestion, index) => (
                                        <div
                                          key={index}
                                          className='suggestion-item'
                                        >
                                          <li
                                            onClick={() =>
                                              handleNativeSuggestionClick(
                                                suggestion
                                              )
                                            }
                                          >
                                            <div className='eachLocation'>
                                              <div className='locationIcon'>
                                                <LocationOnIcon fontSize='small' />
                                              </div>{' '}
                                              <div className='locationSuggestion'>
                                                {suggestion}
                                              </div>
                                            </div>{' '}
                                          </li>
                                        </div>
                                      )
                                    )}
                                  </ul>
                                </div>
                              )}

                              {validationErrors[index]?.location && (
                                <div className='text-danger'>
                                  {validationErrors[index].location}
                                </div>
                              )}
                            </div>
                            <div className='form-outline mb-5 DashBoardInputBx'>
                              <h5>Duration</h5>
                            </div>

                            <div className='form-outline mb-5 DashBoardInputBx d-flex gap-4'>
                              <label
                                className='form-label'
                                htmlFor='startEmployment'
                              >
                                School Entry
                                <span className='RedStar'>*</span>
                              </label>

                              <div className='d-flex gap-3 form-control'>
                                <div className='w-50'>
                                  <select
                                    name='start_month'
                                    id='startMonth'
                                    className={`form-control form-select ${
                                      validationErrors[index]?.start_month &&
                                      'input-error'
                                    }`}
                                    value={j.start_month || ''}
                                    onChange={e => handleChange(e, index, j.id)}
                                  >
                                    <option value=''>Select Month</option>
                                    <option value='01'>January</option>
                                    <option value='02'>February</option>
                                    <option value='03'>March</option>
                                    <option value='04'>April</option>
                                    <option value='05'>May</option>
                                    <option value='06'>June</option>
                                    <option value='07'>July</option>
                                    <option value='08'>August</option>
                                    <option value='09'>September</option>
                                    <option value='10'>October</option>
                                    <option value='11'>November</option>
                                    <option value='12'>December</option>
                                  </select>
                                  {validationErrors[index]?.start_month && (
                                    <div className='text-danger small'>
                                      {validationErrors[index].start_month}
                                    </div>
                                  )}
                                </div>

                                <div className='w-50'>
                                  <select
                                    name='start_year'
                                    id='startYear'
                                    className={`form-control form-select ${
                                      validationErrors[index]?.start_year &&
                                      'input-error'
                                    }`}
                                    value={j.start_year || ''}
                                    onChange={e => handleChange(e, index, j.id)}
                                  >
                                    {/* <option value=''>Select Year</option>
                                    <option value='2020'>2020</option>
                                    <option value='2021'>2021</option>
                                    <option value='2022'>2022</option>
                                    <option value='2023'>2023</option>
                                    <option value='2024'>2024</option> */}
                                    {Object.entries(yearsList).map(
                                      ([index, value]) => {
                                        return (
                                          <option value={value}>{value}</option>
                                        )
                                      }
                                    )}
                                  </select>
                                  {validationErrors[index]?.start_year && (
                                    <div className='text-danger small'>
                                      {validationErrors[index].start_year}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className='d-flex gap-3  form-control'>
                                {j.ongoing ? (
                                  <div className='w-100 d-flex align-items-center'>
                                    <span className='fw-bold'>Present</span>
                                  </div>
                                ) : (
                                  <>
                                    <div className='w-50'>
                                      <label className='form-cellphone w-25'>
                                        Year of Graduation
                                        <span className='RedStar'>*</span>
                                      </label>
                                      <select
                                        name='end_month'
                                        className={`form-control form-select ${
                                          validationErrors[index]?.end_month &&
                                          'input-error'
                                        }`}
                                        value={j.end_month || ''}
                                        onChange={e =>
                                          handleChange(e, index, j.id)
                                        }
                                      >
                                        <option value=''>Select Month</option>
                                        <option value='01'>January</option>
                                        <option value='02'>February</option>
                                        <option value='03'>March</option>
                                        <option value='04'>April</option>
                                        <option value='05'>May</option>
                                        <option value='06'>June</option>
                                        <option value='07'>July</option>
                                        <option value='08'>August</option>
                                        <option value='09'>September</option>
                                        <option value='10'>October</option>
                                        <option value='11'>November</option>
                                        <option value='12'>December</option>
                                      </select>
                                      {validationErrors[index]?.end_month && (
                                        <div className='text-danger small'>
                                          {validationErrors[index].end_month}
                                        </div>
                                      )}
                                    </div>

                                    <div className='w-50'>
                                      <select
                                        name='end_year'
                                        className={`form-control form-select ${
                                          validationErrors[index]?.end_year &&
                                          'input-error'
                                        }`}
                                        value={j.end_year || ''}
                                        onChange={e =>
                                          handleChange(e, index, j.id)
                                        }
                                      >
                                        {/* <option value=''>Select Year</option>
                                        <option value='2020'>2020</option>
                                        <option value='2021'>2021</option>
                                        <option value='2022'>2022</option>
                                        <option value='2023'>2023</option>
                                        <option value='2024'>2024</option> */}
                                        {Object.entries(yearsList).map(
                                          ([index, value]) => {
                                            return (
                                              <option value={value}>
                                                {value}
                                              </option>
                                            )
                                          }
                                        )}
                                      </select>
                                      {validationErrors[index]?.end_year && (
                                        <div className='text-danger small'>
                                          {validationErrors[index].end_year}
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>

                            <div className='form-outline mb-3 d-flex gap-3 ongoing-container mb-5'>
                              <input
                                type='checkbox'
                                id={`ongoing-${j.id}`}
                                name='ongoing'
                                checked={j.ongoing}
                                onChange={e => handleChange(e, index, j.id)}
                                className='form-check-input'
                              />
                              <label
                                htmlFor={`ongoing-${j.id}`}
                                className='form-check-label'
                              >
                                Ongoing
                              </label>
                            </div>

                            <div className='form-outline mb-5 DashBoardInputBx'>
                              <label
                                className='form-label'
                                htmlFor='form3Example3'
                              >
                                Subject area
                                <span className='RedStar'>*</span>
                              </label>
                              <input
                                type='text'
                                id='form3Example3'
                                // className={`form-control ${
                                //   errors.job_profile && "input-error"
                                // }`}
                                className={`form-control  ${
                                  validationErrors[index]?.subject_area &&
                                  'input-error'
                                }`}
                                placeholder='What was the focus of your training? What specialist knowledge and qualifications are important for the new position? Please summarize these briefly and concisely in keywords.'
                                name='subject_area'
                                value={j.subject_area || ''}
                                // onChange={handleChange}
                                // onChange={e => handleChange(e, index)}
                                onChange={e => handleChange(e, index, j.id)}
                              />

                              {validationErrors[index]?.subject_area && (
                                <div className='text-danger'>
                                  {validationErrors[index].subject_area}
                                </div>
                              )}
                              {/* <div className='removeButtonJobseeker mt-4'>
                                {j.id ? (
                                  <>
                                    <button
                                      type='button'
                                      className='btn btn-primary button2'
                                      onClick={() => handleRemove(j.id)}
                                      style={{
                                        color: hoverFourthButtonColor
                                          ? primaryColor
                                          : secondaryColor,
                                        backgroundColor: 'white',
                                        border: hoverFourthButtonColor
                                          ? `2px solid ${primaryColor}`
                                          : `2px solid ${secondaryColor}`
                                      }}
                                      // onMouseEnter={handleFourthButtonMouseEnter}
                                      // onMouseLeave={handleFourthButtonMouseLeave}
                                    >
                                      {t('jobseekerEducation.removeButton')}
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      type='button'
                                      className='btn btn-primary button2'
                                      onClick={() =>
                                        handleRemoveWithoutId(index)
                                      }
                                      style={{
                                        color: hoverFourthButtonColor
                                          ? primaryColor
                                          : secondaryColor,
                                        backgroundColor: 'white',
                                        border: hoverFourthButtonColor
                                          ? `2px solid ${primaryColor}`
                                          : `2px solid ${secondaryColor}`
                                      }}
                                      // onMouseEnter={handleFourthButtonMouseEnter}
                                      // onMouseLeave={handleFourthButtonMouseLeave}
                                    >
                                      {t('jobseekerEducation.removeButton')}
                                    </button>
                                  </>
                                )}
                              </div> */}
                            </div>

                            <div className='file-upload-container'>
                              <div className='upload-box'>
                                <input
                                  type='file'
                                  name='education_certificate'
                                  id={`file-input-${index}`}
                                  accept='.pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg'
                                  multiple
                                  onChange={e => handleFileChange(e, index)} // Handle file change
                                  style={{ display: 'none' }}
                                />
                                <div
                                  className='upload-method'
                                  onClick={() =>
                                    document
                                      .getElementById(`file-input-${index}`)
                                      .click()
                                  }
                                >
                                  <p>
                                    <span role='img' aria-label='attachment'>
                                      📎
                                    </span>{' '}
                                    <span className='upload-text'>
                                      Add files
                                    </span>{' '}
                                    or drag and drop files here
                                  </p>
                                  <p className='upload-info'>
                                    Supported formats: .pdf, .doc, .docx, .txt,
                                    .xls, .csv, .jpg, .jpeg
                                  </p>
                                </div>

                               
                                {selectedFiles && selectedFiles.length > 0 ? (
                                  <div className='file-preview'>
                                    <strong>Selected Files:</strong>
                                    <ul>
                                      {selectedFiles.map((file, fileIndex) => (
                                        <li
                                          key={fileIndex}
                                          className='file-item'
                                        >
                                          <i
                                            className={`fas ${getFileIcon(
                                              file
                                            )} file-icon`}
                                          ></i>
                                          <span className='file-name'>
                                            {/* {file.length > 30
                                              ? `${file.slice(0, 30)}...`
                                              : file} */}
                                               {typeof file === 'object' && file.name
              ? file.name.length > 30
                ? `${file.name.slice(0, 30)}...`
                : file.name
              : file}
                                          </span>
                                          {file ? (
                                            <button
                                              type='button'
                                              onClick={() =>
                                                handleRemoveFileWithName(file)
                                              } // Remove file by index
                                              className='remove-btn'
                                            >
                                              &times;
                                            </button>
                                          ) : (
                                            <button
                                              type='button'
                                              onClick={() =>
                                                handleRemoveFile(
                                                  index,
                                                  fileIndex
                                                )
                                              } // Remove file by index
                                              className='remove-btn'
                                            >
                                              &times;
                                            </button>
                                          )}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ):(
                                  <>
                                  <p>No files selected</p>
                                  </>
                                )}

                                {/* {selectedFiles[index] &&
                                  selectedFiles[index].length > 5 && (
                                    <div className='text-danger small'>
                                      You can upload a maximum of 5 files.
                                    </div>
                                  )}

                                {selectedFiles[index] &&
                                  selectedFiles[index].some(
                                    (file, idx, arr) =>
                                      arr.findIndex(
                                        f => f.name === file.name
                                      ) !== idx
                                  ) && (
                                    <div className='text-danger small'>
                                      Duplicate files are not allowed.
                                    </div>
                                  )} */}
                              </div>
                            </div>

                            <div className='removeButtonJobseeker mt-4'>
                              {j.id ? (
                                <>
                                  <button
                                    type='button'
                                    className='btn btn-primary button2'
                                    onClick={() => handleRemove(j.id)}
                                    style={{
                                      color: hoverFourthButtonColor
                                        ? primaryColor
                                        : secondaryColor,
                                      backgroundColor: 'white',
                                      border: hoverFourthButtonColor
                                        ? `2px solid ${primaryColor}`
                                        : `2px solid ${secondaryColor}`
                                    }}
                                    // onMouseEnter={handleFourthButtonMouseEnter}
                                    // onMouseLeave={handleFourthButtonMouseLeave}
                                  >
                                    {t('jobseekerEducation.removeButton')}
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    type='button'
                                    className='btn btn-primary button2'
                                    onClick={() => handleRemoveWithoutId(index)}
                                    style={{
                                      color: hoverFourthButtonColor
                                        ? primaryColor
                                        : secondaryColor,
                                      backgroundColor: 'white',
                                      border: hoverFourthButtonColor
                                        ? `2px solid ${primaryColor}`
                                        : `2px solid ${secondaryColor}`
                                    }}
                                    // onMouseEnter={handleFourthButtonMouseEnter}
                                    // onMouseLeave={handleFourthButtonMouseLeave}
                                  >
                                    {t('jobseekerEducation.removeButton')}
                                  </button>
                                </>
                              )}
                            </div>
                          </>
                        )
                      })}
                    {/* 
                    {educations.length > 0 && (
                      <>
                        <div className="EduAddMore mb-4">
                          <button
                            type="button"
                            className="btn btn-primary button1"
                            onClick={handleAdd}
                            style={{
                              backgroundColor: primaryColor,
                              color: "white",
                              border: primaryColor,
                            }}
                          >
                            {t("jobseekerEducation.addMoreButton")}
                          </button>
                        </div>
                      </>
                    )} */}

                    {/* {educations.length <= 0 && (
                      <>
                        <div className='EduAddMore mb-4'>
                          <button
                            type='button'
                            className='btn btn-primary button1'
                            onClick={handleAdd}
                            style={{
                              backgroundColor: primaryColor,
                              color: 'white',
                              border: primaryColor
                            }}
                          >
                            {t('jobseekerEducation.addDetails')}
                          </button>
                        </div>
                      </>
                    )} */}
                    {/* {educations.length > 0 && (
                      <>
                        <div className="bottomButtonsEducation">
                          <button
                            type="button"
                            className="btn btn-primary button1"
                            onClick={handleClick}
                            style={{
                              backgroundColor: hoverFirstButtonColor
                                ? secondaryColor
                                : primaryColor,
                              border: hoverFirstButtonColor
                                ? secondaryColor
                                : primaryColor,
                            }}
                            onMouseEnter={handleFirstButtonMouseEnter}
                            onMouseLeave={handleFirstButtonMouseLeave}
                          >
                            {t("jobseekerEducation.updateButton")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary button2"
                            style={{
                              color: hoverThirdButtonColor
                                ? primaryColor
                                : secondaryColor,
                              backgroundColor: "white",
                              border: hoverThirdButtonColor
                                ? `2px solid ${primaryColor}`
                                : `2px solid ${secondaryColor}`,
                            }}
                            onMouseEnter={handleThirdButtonMouseEnter}
                            onMouseLeave={handleThirdButtonMouseLeave}
                            onClick={() => navigate("/candidates/myaccount")}
                          >
                            {t("jobseekerEducation.cancelButton")}
                          </button>
                        </div>
                      </>
                    )} */}
                  </div>
                </form>
                {educations.length > 0 && (
                  <>
                    <div className='EduAddMore mb-4'>
                      <button
                        type='button'
                        className='btn btn-primary button1'
                        onClick={handleAdd}
                        style={{
                          backgroundColor: primaryColor,
                          color: 'white',
                          border: primaryColor
                        }}
                      >
                        {t('jobseekerEducation.addMoreButton')}
                      </button>
                    </div>
                  </>
                )}
                {/* {educations.length > 0 && (
                  <>
                    <div className='bottomButtonsEducation'>
                      <button
                        type='button'
                        className='btn btn-primary button1'
                        onClick={handleClick}
                        style={{
                          backgroundColor: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                          border: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor
                        }}
                        onMouseEnter={handleFirstButtonMouseEnter}
                        onMouseLeave={handleFirstButtonMouseLeave}
                      >
                        {t('jobseekerEducation.updateButton')}
                      </button>
                      <button
                        type='button'
                        className='btn btn-primary button2'
                        style={{
                          color: hoverThirdButtonColor
                            ? primaryColor
                            : secondaryColor,
                          backgroundColor: 'white',
                          border: hoverThirdButtonColor
                            ? `2px solid ${primaryColor}`
                            : `2px solid ${secondaryColor}`
                        }}
                        onMouseEnter={handleThirdButtonMouseEnter}
                        onMouseLeave={handleThirdButtonMouseLeave}
                        onClick={() => navigate('/candidates/myaccount')}
                      >
                        {t('jobseekerEducation.cancelButton')}
                      </button>
                    </div>
                  </>
                )} */}

                <div className='JSEPFinalButton d-flex justify-content-between'>
                  <Link
                    to={`/candidates/parttime`}
                    type='button'
                    // onClick={handleClick}
                    className='btn btn-primary button1'
                    style={{
                      backgroundColor: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor
                    }}
                    onMouseEnter={handleFirstButtonMouseEnter}
                    onMouseLeave={handleFirstButtonMouseLeave}
                  >
                    Back
                  </Link>
                  <button
                    // to={`/candidates/professions/experience`}
                    type='button'
                    onClick={handleClick}
                    className='btn btn-primary button1'
                    style={{
                      backgroundColor: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor
                    }}
                    onMouseEnter={handleFirstButtonMouseEnter}
                    onMouseLeave={handleFirstButtonMouseLeave}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  )
}

export default EducationDetails