// import React, { useEffect, useState } from 'react'
// import JSSidebar from './JSSidebar'
// import NavBar from '../element/NavBar'
// import Footer from '../element/Footer'
// import { Link, useNavigate } from 'react-router-dom'
// import axios from 'axios'
// import ApiKey from '../api/ApiKey'
// import BaseApi from '../api/BaseApi'
// // import Multiselect from "multiselect-react-dropdown";
// import JoditEditor from 'jodit-react'
// import Select from 'react-select'
// import { useRef } from 'react'
// import Swal from 'sweetalert2'
// import Cookies from 'js-cookie'
// import LocationOnIcon from '@mui/icons-material/LocationOn'
// import { useTranslation } from 'react-i18next'
// import ReactQuill from 'react-quill'
// import 'react-quill/dist/quill.snow.css' // import styles

// const ExperienceCertificate = () => {
//   let primaryColor = Cookies.get('primaryColor')
//   let secondaryColor = Cookies.get('secondaryColor')
//   const mapKey = Cookies.get('mapKey')
//   const [t, i18n] = useTranslation('global')
//   const [editProfile, setEditProfile] = useState({
//     job_position: '',
//     company_name: '',
//     location: '',
//     job_profile: '',
//     startMonth: '',
//     startYear: '',
//     endMonth: '',
//     endYear: '',
//     ongoing: false
//   })
//   // const [internships, setInternships] = useState([])
//   const [experience, setExperience] = useState([])

//   const [validationErrors, setValidationErrors] = useState([])
//   const [yearsList, setYearsList] = useState([])
//   const [errors, setErrors] = useState({})
//   const [educationDetails, setEducationDetails] = useState([])
//   const [loading, setLoading] = useState(false)
//   const [autocompleteService, setAutocompleteService] = useState(null)
//   const [suggestionsPreferred, setSuggestionsPreferred] = useState([])
//   const [suggestionsNative, setSuggestionsNative] = useState([])
//   const [suggestionTakenNative, setSuggestionTakenNative] = useState(false)

//   const navigate = useNavigate()
//   const tokenKey = Cookies.get('tokenClient') // Assuming you're using cookies for the token

//   const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false)

//   const handleFirstButtonMouseEnter = () => {
//     setHoverFirstButtonColor(true)
//   }

//   const handleFirstButtonMouseLeave = () => {
//     setHoverFirstButtonColor(false)
//   }

//   const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false)

//   const handleSecondButtonMouseEnter = () => {
//     setHoverSecondButtonColor(true)
//   }

//   const handleSecondButtonMouseLeave = () => {
//     setHoverSecondButtonColor(false)
//   }

//   const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false)

//   const handleThirdButtonMouseEnter = () => {
//     setHoverThirdButtonColor(true)
//   }

//   const handleThirdButtonMouseLeave = () => {
//     setHoverThirdButtonColor(false)
//   }

//   const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

//   const handleFourthButtonMouseEnter = () => {
//     setHoverFourthButtonColor(true)
//   }

//   const handleFourthButtonMouseLeave = () => {
//     setHoverFourthButtonColor(false)
//   }

//   const handleClick = async () => {
//     try {
//       const confirmationResult = await Swal.fire({
//         title: 'Confirm Experience Details',
//         text: 'Are you sure you want to save these details?',
//         icon: 'question',
//         showCancelButton: true,
//         confirmButtonText: 'Yes',
//         cancelButtonText: 'No'
//       })

//       if (confirmationResult.isConfirmed) {
//         setLoading(true)
//         console.log()

//         const response = await axios.post(
//           BaseApi + '/candidates/editExperience',
//           { Experience: experience }, // Send education details as payload
//           {
//             headers: {
//               'Content-Type': 'application/json',
//               key: ApiKey,
//               token: tokenKey
//             }
//           }
//         )

//         setLoading(false)

//         if (response.data.status === 200) {
//           Swal.fire({
//             title: 'Success!',
//             text: 'Experience details updated successfully.',
//             icon: 'success',
//             confirmButtonText: 'Close'
//           })
//           setEducationDetails([]) // Reset education details
//           // Optional: Reload the page or call a function to update UI
//           navigate('/candidates/internships')
//         } else if (response.data.status === 400) {
//           Cookies.remove('tokenClient')
//           Cookies.remove('user_type')
//           Cookies.remove('fname')
//           navigate('/candidates/parttime') // Redirect if token expired or other issue
//           Swal.fire({
//             title: response.data.message,
//             icon: 'warning',
//             confirmButtonText: 'Close'
//           })
//         } else {
//           Swal.fire({
//             title: response.data.message,
//             icon: 'error',
//             confirmButtonText: 'Close'
//           })
//         }
//       }
//     } catch (error) {
//       setLoading(false)
//       // if (error.message === "Network Error") {
//       //   Cookies.remove("tokenClient");
//       //   Cookies.remove("user_type");
//       //   Cookies.remove("fname");
//       //   navigate("/candidates/internships");
//       //   Swal.fire({
//       //     title: "Token Expired",
//       //     icon: "warning",
//       //     confirmButtonText: "Close",
//       //   });
//       //   setTimeout(function () {
//       //     window.location.reload(); // Reload page if the token is expired
//       //   }, 3000);
//       // }

//       Swal.fire({
//         title: 'Failed to update Experience Details',
//         text: 'There was an error updating your experience details. Please try again.',
//         icon: 'error',
//         confirmButtonText: 'Close'
//       })
//     }
//   }

//   return (
//     <>
//       <NavBar />
//       {loading ? (
//         <div className='loader-container'></div>
//       ) : (
//         <>
//           <div className='container editProfile'>
//             <div className='row'>
//               <div className='col-lg-3'>
//                 <JSSidebar />
//               </div>

//               <div
//                 className='col-lg-9 mb-5'
//                 style={{
//                   borderLeft: '2px solid #e6e8e7',
//                   borderRight: '2px solid #e6e8e7'
//                 }}
//               >
//                 <div className='mx-3 d-flex PageHeader'>
//                   <h3 className=''>Download Working certificate</h3>
//                 </div>
//                 <p className='mx-3'>
//                   Would you like to add your work-related documents / employment
//                   references right away, so you always have them at hand and
//                   don’t risk losing them? Here, you have the option to upload or
//                   scan your documents
//                 </p>
//                 <div className='mb-5 mt-4 mx-4'>
//                   <div className='row'>
//                     <div className='col-md-6'>
//                       <div class='upload-box'>
//                         {/* <div className='upload-method'>
//                           <input type='file' id='file-input' />
//                           <p>
//                             <a href='#'>📎 add files</a> or move files here
//                           </p>
//                           <p>
//                             .pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg /
//                             maximal 5 MB
//                           </p>
//                         </div> */}
//                         <div
//                           className='upload-method'
//                           style={{
//                             border: '1px dashed #ccc',
//                             padding: '20px',
//                             textAlign: 'center',
//                             borderRadius: '8px',
//                             cursor: 'pointer'
//                           }}
//                           onClick={() =>
//                             document.getElementById('file-input').click()
//                           }
//                         >
//                           <input
//                             type='file'
//                             id='file-input'
//                             style={{ display: 'none' }}
//                             accept='.pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg'
//                           />
//                           <p>
//                             📎{' '}
//                             <span
//                               style={{
//                                 color: '#007BFF',
//                                 textDecoration: 'underline'
//                               }}
//                             >
//                               Add files
//                             </span>{' '}
//                             or drag and drop files here
//                           </p>
//                           <p style={{ fontSize: '12px', color: '#666' }}>
//                             Supported formats: .pdf, .doc, .docx, .txt, .xls,
//                             .csv, .jpg, .jpeg (max size: 5 MB)
//                           </p>
//                         </div>

//                         <div class='upload-options'>
//                           <Link to='' className='google-drive'>
//                             <i class='fa-brands fa-google-drive'></i>
//                             Google Drive
//                           </Link>
//                           <Link to='' class='dropbox'>
//                             <i class='fa-brands fa-dropbox'></i>
//                             Dropbox
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                     <div className='col-md-6'>
//                       <div class='scan-box'>
//                         <img src='qr-scan-icon.png' alt='Scan Icon' />
//                         <p className='scan-text'>Scan with your mobile phone</p>
//                         <p>(Only possible when using the Job to Date app)</p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className='JSEPFinalButton d-flex justify-content-between'>
//                   <Link
//                     to={`/candidates/professions/experience`}
//                     type='button'
//                     // onClick={handleClick}
//                     className='btn btn-primary button1'
//                     style={{
//                       backgroundColor: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor,
//                       border: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor
//                     }}
//                     onMouseEnter={handleFirstButtonMouseEnter}
//                     onMouseLeave={handleFirstButtonMouseLeave}
//                   >
//                     Back
//                   </Link>
//                   <button
//                     // to={`/candidates/professions/experience`}
//                     type='button'
//                     onClick={handleClick}
//                     className='btn btn-primary button1'
//                     style={{
//                       backgroundColor: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor,
//                       border: hoverFirstButtonColor
//                         ? secondaryColor
//                         : primaryColor
//                     }}
//                     onMouseEnter={handleFirstButtonMouseEnter}
//                     onMouseLeave={handleFirstButtonMouseLeave}
//                   >
//                     Next
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <Footer />
//         </>
//       )}
//     </>
//   )
// }

// export default ExperienceCertificate

// import React, { useEffect, useState } from 'react'
// import JSSidebar from './JSSidebar'
// import NavBar from '../element/NavBar'
// import Footer from '../element/Footer'
// import { Link, useNavigate } from 'react-router-dom'
// import axios from 'axios'
// import ApiKey from '../api/ApiKey'
// import BaseApi from '../api/BaseApi'
// import Swal from 'sweetalert2'
// import Cookies from 'js-cookie'
// import { useTranslation } from "react-i18next";

// const ExperienceCertificate = () => {
//   let primaryColor = Cookies.get('primaryColor')
//   let secondaryColor = Cookies.get('secondaryColor')
//   const mapKey = Cookies.get('mapKey')
//   const [editProfile, setEditProfile] = useState({
//     // job_position: '',
//     // company_name: '',
//     // location: '',
//     // job_profile: '',
//     // startMonth: '',
//     // startYear: '',
//     // endMonth: '',
//     // endYear: '',
//     // ongoing: false,
//     experience_certificate: ''
//   })
//   const [t, i18n] = useTranslation("global");
//   const [experience, setExperience] = useState([])
//   const [loading, setLoading] = useState(false)
//   const [selectedFile, setSelectedFile] = useState(null) // State to hold the selected file
//   const [fileName, setFileName] = useState('') // State to hold the file name
//   const navigate = useNavigate()
//   const tokenKey = Cookies.get('tokenClient') // Assuming you're using cookies for the token

//   // Handle file selection
//   const handleFileChange = e => {
//     const file = e.target.files[0]
//     if (file) {
//       setSelectedFile(file)
//       setFileName(file.name) // Store the file name
//     }
//   }

//   // Handle file upload and submission
//   // const handleClick = async () => {
//   //   try {
//   //     const confirmationResult = await Swal.fire({
//   //       title: 'Confirm Experience Details',
//   //       text: 'Are you sure you want to save these details?',
//   //       icon: 'question',
//   //       showCancelButton: true,
//   //       confirmButtonText: 'Yes',
//   //       cancelButtonText: 'No'
//   //     })

//   //     if (confirmationResult.isConfirmed) {
//   //       setLoading(true)

//   //       const formData = new FormData()
//   //       formData.append('Experience', JSON.stringify(experience))
//   //       if (selectedFile) {
//   //         formData.append('file', selectedFile) // Append the file to the form data
//   //       }

//   //       const response = await axios.post(
//   //         BaseApi + '/candidates/editExperience',
//   //         { Experience: experience },
//   //         {
//   //           headers: {
//   //             'Content-Type': 'multipart/form-data',
//   //             key: ApiKey,
//   //             token: tokenKey
//   //           }
//   //         }
//   //       )

//   //       setLoading(false)

//   //       if (response.data.status === 200) {
//   //         Swal.fire({
//   //           title: 'Success!',
//   //           text: 'Experience details updated successfully.',
//   //           icon: 'success',
//   //           confirmButtonText: 'Close'
//   //         })
//   //         setExperience([]) // Reset experience details
//   //         navigate('/candidates/internships')
//   //       } else {
//   //         Swal.fire({
//   //           title: response.data.message,
//   //           icon: 'error',
//   //           confirmButtonText: 'Close'
//   //         })
//   //       }
//   //     }
//   //   } catch (error) {
//   //     setLoading(false)
//   //     Swal.fire({
//   //       title: 'Failed to update Experience Details',
//   //       text: 'There was an error updating your experience details. Please try again.',
//   //       icon: 'error',
//   //       confirmButtonText: 'Close'
//   //     })
//   //   }
//   // }

//   const handleClick = async () => {
//     try {
//       const confirmationResult = await Swal.fire({
//         title: 'Confirm Experience Details',
//         text: 'Are you sure you want to save these details?',
//         icon: 'question',
//         showCancelButton: true,
//         confirmButtonText: 'Yes',
//         cancelButtonText: 'No'
//       });
  
//       if (confirmationResult.isConfirmed) {
//         setLoading(true);
  
//         // Create a FormData object
//         const formData = new FormData();
//         formData.append('Experience', JSON.stringify(experience)); // Append experience details as JSON string
//         if (selectedFile) {
//           formData.append('experience_certificate', selectedFile); // Append the file
//         }
  
//         // Send the form data to the API
//         const response = await axios.post(
//           BaseApi + '/candidates/editExperience',
//           { Experience: experience, experience_certificate: selectedFile }, // Send the formData directly
//           {
//             headers: {
//               'key': ApiKey,
//               'token': tokenKey
//             }
//           }
//         );
  
//         setLoading(false);
  
//         if (response.data.status === 200) {
//           Swal.fire({
//             title: 'Success!',
//             text: 'Experience details updated successfully.',
//             icon: 'success',
//             confirmButtonText: 'Close'
//           });
//           setExperience([]); // Reset experience details
//           navigate('/candidates/internships');
//         } else {
//           Swal.fire({
//             title: response.data.message,
//             icon: 'error',
//             confirmButtonText: 'Close'
//           });
//         }
//       }
//     } catch (error) {
//       setLoading(false);
//       Swal.fire({
//         title: 'Failed to update Experience Details',
//         text: 'There was an error updating your experience details. Please try again.',
//         icon: 'error',
//         confirmButtonText: 'Close'
//       });
//     }
//   };

//   const getData = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.post(
//         BaseApi + "/candidates/editExperience",
//         null, // Pass null as the request body if not required
//         {
//           headers: {
//             "Content-Type": "application/json",
//             key: ApiKey,
//             token: tokenKey,
//           },
//         }
//       );
//       if (response.data.status === 200) {
//         // if (Array.isArray(response.data)) {
//         //   setInternships(response.data);
//         // } else {
//         //   setInternships([]); // Fallback if the data is not an array
//         // }

//         setExperience(response.data.response.expDetails);
//         // setYearsList(response.data.response.yearList);
//         console.log(response.data.response.expDetails);

//         // console.log("object");

//         // code to handel pre selected interest category

//         var categoryList = response.data.response.categoryList;
//         var interestCategory = response.data.response.interest_categories;
//         var selectedCat = [];

//         categoryList.forEach((element) => {
//           for (let i = 0; i < interestCategory.length; i++) {
//             if (parseInt(interestCategory[i]) === element.id) {
//               let obj = {
//                 value: element.id,
//                 label: element.name,
//               };
//               selectedCat.push(obj);
//             }
//           }
//         });

//         setLoading(false);
//         setEditProfile(response.data.response);

//         console.log("check");
//         // setDesignationList(response.data.response.designationlList);
//         // console.log(skillList);
//       } else if (response.data.status === 400) {
//         Cookies.remove("tokenClient");
//         Cookies.remove("user_type");
//         Cookies.remove("fname");
//         navigate("/");
//         Swal.fire({
//           title: response.data.message,
//           icon: "warning",
//           confirmButtonText: t("searchJobPage.close"),
//         });
//       } else {
//         Swal.fire({
//           title: response.data.message,
//           icon: "error",
//           confirmButtonText: t("searchJobPage.close"),
//         });
//       }
//     } catch (error) {
//       setLoading(false);
//       if (error.message === "Network Error") {
//         Cookies.remove("tokenClient");
//         Cookies.remove("user_type");
//         Cookies.remove("fname");
//         navigate("/");
//         Swal.fire({
//           title: t("tokenExpired.tokenExpired"),
//           icon: "warning",
//           confirmButtonText: t("jobDescription.close"),
//         });
//         setTimeout(function () {
//           window.location.reload();
//         }, 3000);
//       }
//       console.log("Cannot get data of edit profile page");
//     }
//   };


//     useEffect(() => {
//       // Check if tokenKey is not present
//       if (!tokenKey) {
//         // Redirect to the home page
//         navigate("/user/jobseekerlogin");
//       } else {
//         // TokenKey is present, fetch data or perform other actions
//         getData();
  
//         window.scrollTo(0, 0);
//         // if(educationDetails.basic_course_id) {
//         // getSpecializationList(educationDetails.basic_course_id);
//         // }
//       }
//     }, [tokenKey, navigate]);
  

//   return (
//     <>
//       <NavBar />
//       {loading ? (
//         <div className='loader-container'></div>
//       ) : (
//         <>
//           <div className='container editProfile'>
//             <div className='row'>
//               <div className='col-lg-3'>
//                 <JSSidebar />
//               </div>

//               <div
//                 className='col-lg-9 mb-5'
//                 style={{
//                   borderLeft: '2px solid #e6e8e7',
//                   borderRight: '2px solid #e6e8e7'
//                 }}
//               >
//                 <div className='mx-3 d-flex PageHeader'>
//                   <h3 className=''>Download Working Certificate</h3>
//                 </div>
//                 <p className='mx-3'>
//                   Would you like to add your work-related documents / employment
//                   references right away, so you always have them at hand and
//                   don’t risk losing them? Here, you have the option to upload or
//                   scan your documents.
//                 </p>
//                 <div className='mb-5 mt-4 mx-4'>
//                   <div className='row'>
//                     <div className='col-md-6'>
//                       <div className='upload-box'>
//                         {/* <div
//                           className="upload-method"
//                           style={{
//                             border: '1px dashed #ccc',
//                             padding: '20px',
//                             textAlign: 'center',
//                             borderRadius: '8px',
//                             cursor: 'pointer'
//                           }}
//                           onClick={() => document.getElementById('file-input').click()}
//                         >
//                           <input
//                             type="file"
//                             id="file-input"
//                             style={{ display: 'none' }}
//                             accept=".pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg"
//                             onChange={handleFileChange} // Handle file change
//                           />
//                           <p>
//                             📎{' '}
//                             <span style={{ color: '#007BFF', textDecoration: 'underline' }}>
//                               Add files
//                             </span>{' '}
//                             or drag and drop files here
//                           </p>
//                           <p style={{ fontSize: '12px', color: '#666' }}>
//                             Supported formats: .pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg (max size: 5 MB)
//                           </p>
//                         </div>

//                         {fileName && (
//                           <div style={{ marginTop: '10px', textAlign: 'center' }}>
//                             <strong>Selected File:</strong>
//                             <p>{fileName}</p>
//                           </div>
//                         )} */}
//                         <div
//                           className='upload-method'
//                           style={{
//                             border: '1px dashed #ccc',
//                             padding: '20px',
//                             textAlign: 'center',
//                             borderRadius: '8px',
//                             cursor: 'pointer'
//                           }}
//                           onClick={() =>
//                             document.getElementById('file-input').click()
//                           }
//                         >
//                           <input
//                             type='file'
//                             id='file-input'
//                             name='experience_certificate'
//                             style={{ display: 'none' }}
//                             accept='.pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg'
//                             onChange={handleFileChange} // Handle file change
//                           />
//                           {!fileName ? (
//                             <>
//                               <p>
//                                 📎{' '}
//                                 <span
//                                   style={{
//                                     color: '#007BFF',
//                                     textDecoration: 'underline'
//                                   }}
//                                 >
//                                   Add files
//                                 </span>{' '}
//                                 or drag and drop files here
//                               </p>
//                               <p style={{ fontSize: '12px', color: '#666' }}>
//                                 Supported formats: .pdf, .doc, .docx, .txt,
//                                 .xls, .csv, .jpg, .jpeg (max size: 5 MB)
//                               </p>
//                             </>
//                           ) : (
//                             <div
//                               style={{ marginTop: '10px', textAlign: 'center' }}
//                             >
//                               <strong>Selected File:</strong>
//                               <p>{fileName}</p>
//                             </div>
//                           )}
//                         </div>

//                         <div class='upload-options'>
//                           <Link to='' className='google-drive'>
//                             <i class='fa-brands fa-google-drive'></i>
//                             Google Drive
//                           </Link>
//                           <Link to='' class='dropbox'>
//                             <i class='fa-brands fa-dropbox'></i>
//                             Dropbox
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                     <div className='col-md-6'>
//                       <div className='scan-box'>
//                         <img src='qr-scan-icon.png' alt='Scan Icon' />
//                         <p className='scan-text'>Scan with your mobile phone</p>
//                         <p>(Only possible when using the Job to Date app)</p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className='JSEPFinalButton d-flex justify-content-between'>
//                   <Link
//                     to={`/candidates/professions/experience`}
//                     type='button'
//                     className='btn btn-primary button1'
//                     style={{
//                       backgroundColor: primaryColor,
//                       border: `1px solid ${primaryColor}`
//                     }}
//                   >
//                     Back
//                   </Link>
//                   <button
//                     type='button'
//                     onClick={handleClick}
//                     className='btn btn-primary button1'
//                     style={{
//                       backgroundColor: primaryColor,
//                       border: `1px solid ${primaryColor}`
//                     }}
//                   >
//                     Next
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <Footer />
//         </>
//       )}
//     </>
//   )
// }

// export default ExperienceCertificate











import React, { useEffect, useState } from 'react'
import JSSidebar from './JSSidebar'
import NavBar from '../element/NavBar'
import Footer from '../element/Footer'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import ApiKey from '../api/ApiKey'
import BaseApi from '../api/BaseApi'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import { useTranslation } from "react-i18next";

const ExperienceCertificate = () => {
  let primaryColor = Cookies.get('primaryColor')
  let secondaryColor = Cookies.get('secondaryColor')
  const mapKey = Cookies.get('mapKey')
  const [editProfile, setEditProfile] = useState({
    experience_certificate: ''
  })
  const [t, i18n] = useTranslation("global");
  const [experience, setExperience] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState([]) // State to hold the selected file
  const [fileName, setFileName] = useState('') 
  const navigate = useNavigate()
  const tokenKey = Cookies.get('tokenClient') // Assuming you're using cookies for the token





  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Log the file if needed
      console.log(file);
  
      // Create a new FormData to store the file if necessary
      const formData = new FormData();
      formData.append('experience_certificate', file);
  
      // Assuming you want to update the experience_certificate field in the first object of the experience array
      const updatedExperience = [...experience]; // Create a shallow copy of the experience array
      updatedExperience[0].experience_certificate = file; // Update the experience_certificate field with the selected file
  
      // Update the experience state with the modified array
      setExperience(updatedExperience);
  
      // If you need to handle binary data for the certificate, you can store the file as binary here.
      // For example, you might want to convert the file to a base64 string or handle it accordingly.
    }
  };
  
  
  
  // Handle file upload and submission
  const handleClick = async () => {
    try {
      const confirmationResult = await Swal.fire({
        title: 'Confirm Experience Details',
        text: 'Are you sure you want to save these details?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      });

      if (confirmationResult.isConfirmed) {
        setLoading(true);

 


      

         


         

        // Send the form data to the API
        const response = await axios.post(
          BaseApi + '/candidates/editExperience',
          { Experience: experience },// Send FormData
          {
            headers: {
              'Content-Type': 'multipart/form-data', // Make sure to set this for file uploads
              'key': ApiKey,
              'token': tokenKey
            }
          }
        );

        setLoading(false);

        if (response.data.status === 200) {
          Swal.fire({
            title: 'Success!',
            text: 'Experience details updated successfully.',
            icon: 'success',
            confirmButtonText: 'Close'
          });
          setExperience([]); // Reset experience details
          navigate('/candidates/internships');
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: 'Close'
          });
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: 'Failed to update Experience Details',
        text: 'There was an error updating your experience details. Please try again.',
        icon: 'error',
        confirmButtonText: 'Close'
      });
    }
  };


  

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + "/candidates/editExperience",
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      if (response.data.status === 200) {
        setExperience(response.data.response.expDetails);
        setLoading(false);
        setEditProfile(response.data.response);
      } else if (response.data.status === 400) {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: response.data.message,
          icon: "warning",
          confirmButtonText: t("searchJobPage.close"),
        });
      } else {
        Swal.fire({
          title: response.data.message,
          icon: "error",
          confirmButtonText: t("searchJobPage.close"),
        });
      }
    } catch (error) {
      setLoading(false);
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: t("tokenExpired.tokenExpired"),
          icon: "warning",
          confirmButtonText: t("jobDescription.close"),
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
      console.log("Cannot get data of edit profile page");
    }
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/jobseekerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  return (
    <>
      <NavBar />
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <>
          <div className='container editProfile'>
            <div className='row'>
              <div className='col-lg-3'>
                <JSSidebar />
              </div>

              <div
                className='col-lg-9 mb-5'
                style={{
                  borderLeft: '2px solid #e6e8e7',
                  borderRight: '2px solid #e6e8e7'
                }}
              >
                <div className='mx-3 d-flex PageHeader'>
                  <h3 className=''>Download Working Certificate</h3>
                </div>
                <p className='mx-3'>
                  Would you like to add your work-related documents / employment
                  references right away, so you always have them at hand and
                  don’t risk losing them? Here, you have the option to upload or
                  scan your documents.
                </p>
                <div className='mb-5 mt-4 mx-4'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='upload-box'>
                        <div
                          className='upload-method'
                          style={{
                            border: '1px dashed #ccc',
                            padding: '20px',
                            textAlign: 'center',
                            borderRadius: '8px',
                            cursor: 'pointer'
                          }}
                          onClick={() =>
                            document.getElementById('file-input').click()
                          }
                        >
                          <input
                            type='file'
                            id='file-input'
                            name='experience_certificate'
                            style={{ display: 'none' }}
                            accept='.pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg'
                            onChange={handleFileChange} // Handle file change
                          />
                          {!fileName ? (
                            <>
                              <p>
                                📎{' '}
                                <span
                                  style={{
                                    color: '#007BFF',
                                    textDecoration: 'underline'
                                  }}
                                >
                                  Add files
                                </span>{' '}
                                or drag and drop files here
                              </p>
                              <p style={{ fontSize: '12px', color: '#666' }}>
                                Supported formats: .pdf, .doc, .docx, .txt,
                                .xls, .csv, .jpg, .jpeg (max size: 5 MB)
                              </p>
                            </>
                          ) : (
                            <div
                              style={{ marginTop: '10px', textAlign: 'center' }}
                            >
                              <strong>Selected File:</strong>
                              <p>{fileName}</p>
                            </div>
                          )}
                        </div>

                        <div className='upload-options'>
                          <Link to='' className='google-drive'>
                            <i className='fa-brands fa-google-drive'></i>
                            Google Drive
                          </Link>
                          <Link to='' className='dropbox'>
                            <i className='fa-brands fa-dropbox'></i>
                            Dropbox
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='scan-box'>
                        <img src='qr-scan-icon.png' alt='Scan Icon' />
                        <p className='scan-text'>Scan with your mobile phone</p>
                        <p>(Only possible when using the Job to Date app)</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='JSEPFinalButton d-flex justify-content-between'>
                  <Link
                    to={`/candidates/professions/experience`}
                    type='button'
                    className='btn btn-primary button1'
                    style={{
                      backgroundColor: primaryColor,
                      border: `1px solid ${primaryColor}`
                    }}
                  >
                    Back
                  </Link>
                  <button
                    type='button'
                    onClick={handleClick}
                    className='btn btn-primary button1'
                    style={{
                      backgroundColor: primaryColor,
                      border: `1px solid ${primaryColor}`
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  )
}

export default ExperienceCertificate

